import { getUserPreference } from "helpers/utils.helper"
import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { getParentBlogListSuccess, setBlogLoader } from "./action.blog"
import {
  ADD_PARENT_BLOG,
  DELETE_BLOG,
  GET_BLOG_DATA,
  GET_PARENT_BLOG_LIST,
  IS_UNIQUE_BLOG_SLUG,
  LOADER_STATE,
  UPDATE_CHILD_BLOG,
} from "./actionTypes.blog"
import {
  addParentBlog,
  deleteBlog,
  getBlogData,
  getParentBlogList,
  isUniqueBlogSlug,
  updateChildBlog,
} from "./http.blog"

function* GetParentBlogList(action) {
  yield put(setBlogLoader(LOADER_STATE.PARENT_LOADER, true))
  try {
    const response = yield call(getParentBlogList, action.payload)
    if (response?.success) {
      yield put(getParentBlogListSuccess(response.data, response.metadata))
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setBlogLoader(LOADER_STATE.PARENT_LOADER, false))
}

function* GetBlogData(action) {
  const { blogId, cb } = action.payload
  try {
    const response = yield call(getBlogData, blogId)
    cb(response)
  } catch (error) {
    console.log(error, "error")
  }
}

function* AddParentBlog(action) {
  const { data, cb } = action.payload
  yield put(setBlogLoader(LOADER_STATE.BLOG_FORM_SUBMIT_LOADER, true))
  try {
    const response = yield call(addParentBlog, data)
    if (response?.success) {
      toast.success(response.message)
      cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setBlogLoader(LOADER_STATE.BLOG_FORM_SUBMIT_LOADER, false))
}

function* IsUniqueBlogSlug(action) {
  const { blogSlug, cb } = action.payload
  try {
    const response = yield call(isUniqueBlogSlug, blogSlug)
    cb(response)
  } catch (error) {
    console.log(error, "error")
  }
}

function* UpdateChildBlog(action) {
  const { data, cb, params } = action.payload
  yield put(setBlogLoader(LOADER_STATE.BLOG_UPDATE_LOADER, true))
  try {
    const response = yield call(updateChildBlog, data)
    if (response?.success) {
      cb()
      toast.success(response.message)
      if (params) {
        const listRes = yield call(getParentBlogList, params)
        if (listRes?.success) {
          yield put(getParentBlogListSuccess(listRes.data, listRes.metadata))
        }
      }
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setBlogLoader(LOADER_STATE.BLOG_UPDATE_LOADER, false))
}

function* DeleteBlog(action) {
  const { blogId, currentPage, arrayLength, cb } = action.payload
  yield put(setBlogLoader(LOADER_STATE.BLOG_DELETE_LOADER, true))
  try {
    const response = yield call(deleteBlog, blogId)
    if (response?.success) {
      toast.success(response.message)
      const responseList = yield call(getParentBlogList, {
        pageNum:
          arrayLength > 1 ? currentPage : currentPage > 0 ? currentPage - 1 : 0,
        recordLimit: getUserPreference().recordLimit,
      })
      if (responseList?.success) {
        yield put(
          getParentBlogListSuccess(responseList.data, responseList.metadata)
        )
        cb()
        // if get api fails for some reason refreshing page
      } else window.location.reload()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setBlogLoader(LOADER_STATE.BLOG_DELETE_LOADER, false))
}

function* blogSaga() {
  yield takeEvery(GET_PARENT_BLOG_LIST, GetParentBlogList)
  yield takeEvery(GET_BLOG_DATA, GetBlogData)
  yield takeEvery(IS_UNIQUE_BLOG_SLUG, IsUniqueBlogSlug)
  yield takeEvery(ADD_PARENT_BLOG, AddParentBlog)
  yield takeEvery(UPDATE_CHILD_BLOG, UpdateChildBlog)
  yield takeEvery(DELETE_BLOG, DeleteBlog)
}

export default blogSaga
