import {
  GET_ALL_LEADS,
  GET_ALL_LEADS_SUCCESS,
  GET_ALL_LEADS_ERROR,
} from "./actionTypes"

const initialState = {
  leads: [],
  metadata: {},
  loading: false,
}

const leads = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEADS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALL_LEADS_SUCCESS:
      state = {
        ...state,
        leads: action.payload?.data,
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case GET_ALL_LEADS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default leads
