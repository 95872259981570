import FormButton from "components/form-components/FormButton"
import { Field, Formik } from "formik"
import { INSURER } from "helpers/constants/labels.constants"
import { Card, Col, Form, Label, Row } from "reactstrap"

const InsurerForm = ({
  formValues,
  onSubmit,
  insurerLoading,
  insurerId,
  InsurerValidationSchema,
}) => {
  const { NAME, PHONE_NUMBER, HEALTH_PHONE_NUMBER, WEBSITE, EMAIL } =
    INSURER.INSURER_FORM
  const btnText = insurerId ? "Update Insurer" : "Add Insurer"
  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={InsurerValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, errors, values, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{NAME}</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <div className="position-absolute text-danger">
                          {errors.name}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label>{EMAIL}</Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="email"
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <div className="position-absolute text-danger">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label>{WEBSITE}</Label>
                        <Field
                          type="url"
                          className="form-control"
                          name="url"
                          value={values.url}
                        />
                        {errors.url && touched.url ? (
                          <div className="position-absolute text-danger">
                            {errors.url}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label>{PHONE_NUMBER}</Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="phone"
                          value={values.phone}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="position-absolute text-danger">
                            {errors.phone}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label>{HEALTH_PHONE_NUMBER}</Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="health_phone"
                          value={values.health_phone}
                        />
                        {errors.health_phone && touched.health_phone ? (
                          <div className="position-absolute text-danger">
                            {errors.health_phone}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col>
                <FormButton
                  customClass="btn-dark"
                  type="submit"
                  btnText={btnText}
                  disabled={insurerLoading}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default InsurerForm
