import {
  GET_ALL_FILTERED_TESTIMONIAL_SUCCESS,
  GET_ALL_TESTIMONIAL_SUCCESS,
  SET_DELETE_LOADING_TESTIMONIAL,
  SET_LOADING_TESTIMONIAL,
} from "./actionTypes"

const initialState = {
  testimonials: [],
  filteredTestimonialList: [],
  metadata: {},
  loading: false,
  deleteLoader: false,
}

const testimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_TESTIMONIAL:
      state = {
        ...state,
        [action.payload.key]: action.payload.value,
      }
      break
    case GET_ALL_TESTIMONIAL_SUCCESS:
      state = {
        ...state,
        testimonials: action.payload?.data || [],
        metadata: action.payload?.metadata || {},
      }
      break
    case GET_ALL_FILTERED_TESTIMONIAL_SUCCESS:
      state = {
        ...state,
        filteredTestimonialList: action.payload?.data || [],
        metadata: action.payload?.metadata || {},
      }
      break
    case SET_DELETE_LOADING_TESTIMONIAL:
      state = {
        ...state,
        deleteLoader: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default testimonialReducer
