import {
  ADD_TEXT_SECTION,
  ADD_TEXT_SECTION_ERROR,
  ADD_TEXT_SECTION_SUCCESS,
  ADD_VIDEO_SECTION,
  ADD_VIDEO_SECTION_ERROR,
  ADD_VIDEO_SECTION_SUCCESS,
  DELETE_LEAD_SECTION,
  DELETE_LEAD_SECTION_ERROR,
  DELETE_LEAD_SECTION_SUCCESS,
  GET_ALL_LEAD_SECTION,
  GET_ALL_LEAD_SECTION_ERROR,
  GET_ALL_LEAD_SECTION_SUCCESS,
  GET_SINGLE_LEAD_SECTION,
  GET_SINGLE_LEAD_SECTION_ERROR,
  GET_SINGLE_LEAD_SECTION_SUCCESS,
  SINGLE_SECTION,
  SINGLE_SECTION_ERROR,
  SINGLE_SECTION_SUCCESS,
  UPDATE_TEXT_SECTION,
  UPDATE_TEXT_SECTION_ERROR,
  UPDATE_TEXT_SECTION_SUCCESS,
  UPDATE_VIDEO_SECTION,
  UPDATE_VIDEO_SECTION_ERROR,
  UPDATE_VIDEO_SECTION_SUCCESS,
} from "./actionTypes"

export const addTextSection = (data, cb) => {
  return {
    type: ADD_TEXT_SECTION,
    payload: { data, cb },
  }
}

export const addTextSectionSuccess = () => {
  return {
    type: ADD_TEXT_SECTION_SUCCESS,
  }
}

export const addTextSectionError = () => {
  return {
    type: ADD_TEXT_SECTION_ERROR,
  }
}

export const addVideoSection = (data, cb) => {
  return {
    type: ADD_VIDEO_SECTION,
    payload: { data, cb },
  }
}

export const addVideoSectionSuccess = () => {
  return {
    type: ADD_VIDEO_SECTION_SUCCESS,
  }
}

export const addVideoSectionError = () => {
  return {
    type: ADD_VIDEO_SECTION_ERROR,
  }
}

export const getAllLeadPageSection = params => {
  return {
    type: GET_ALL_LEAD_SECTION,
    payload: params,
  }
}

export const getAllLeadPageSuccess = response => {
  return {
    type: GET_ALL_LEAD_SECTION_SUCCESS,
    payload: response,
  }
}

export const getAllLeadPageError = () => {
  return {
    type: GET_ALL_LEAD_SECTION_ERROR,
  }
}

export const getSingleLeadPageSection = sectionId => {
  return {
    type: GET_SINGLE_LEAD_SECTION,
    payload: sectionId,
  }
}

export const getSingleLeadPageSectionSuccess = response => {
  return {
    type: GET_SINGLE_LEAD_SECTION_SUCCESS,
    payload: response,
  }
}

export const getSingleLeadPageSectionError = () => {
  return {
    type: GET_SINGLE_LEAD_SECTION_ERROR,
  }
}

export const deleteLeadPageSection = (sectionId, cb) => {
  return {
    type: DELETE_LEAD_SECTION,
    payload: { sectionId, cb },
  }
}

export const deleteLeadPageSectionSuccess = () => {
  return {
    type: DELETE_LEAD_SECTION_SUCCESS,
  }
}

export const deleteLeadPageSectionError = () => {
  return {
    type: DELETE_LEAD_SECTION_ERROR,
  }
}

export const getSingleSection = (sectionId, cb) => {
  return {
    type: SINGLE_SECTION,
    payload: { sectionId, cb },
  }
}

export const getSingleSectionSuccess = () => {
  return {
    type: SINGLE_SECTION_SUCCESS,
  }
}

export const getSingleSectionError = () => {
  return {
    type: SINGLE_SECTION_ERROR,
  }
}

export const updateTextSection = (data, cb) => {
  return {
    type: UPDATE_TEXT_SECTION,
    payload: { data, cb },
  }
}

export const updateTextSectionSuccess = () => {
  return {
    type: UPDATE_TEXT_SECTION_SUCCESS,
  }
}

export const updateTextSectionError = () => {
  return {
    type: UPDATE_TEXT_SECTION_ERROR,
  }
}

export const updateVideoSection = (data, cb) => {
  return {
    type: UPDATE_VIDEO_SECTION,
    payload: { data, cb },
  }
}

export const updateVideoSectionSuccess = () => {
  return {
    type: UPDATE_VIDEO_SECTION_SUCCESS,
  }
}

export const updateVideoSectionError = () => {
  return {
    type: UPDATE_VIDEO_SECTION_ERROR,
  }
}
