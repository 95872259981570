import TextEditor from "components/common/TextEditor"
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import Select from "react-select"
import { MdClose } from "react-icons/md"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import SelectMediaModal from "components/common/SelectMediaModal"
import FormButton from "components/form-components/FormButton"
import { FAQS } from "helpers/constants/labels.constants"
import { MdAddCircleOutline } from "react-icons/md"

const FaqForm = ({
  formData,
  selectedVideos,
  setSelectedVideos,
  FaqValidationSchema,
  onSubmit,
  editorText,
  setEditorText,
  requiredEditor,
  submitbtn,
  faqCategoryList,
  submitLoader,
  childFaqId,
  currentLanguageCode,
  parentFaqId,
}) => {
  const [faqCateories, setFaqCateories] = useState([])
  const [showMeidaModal, setShowMediaModal] = useState(false)

  const removeVideoCard = () => {
    setSelectedVideos([])
  }

  useEffect(() => {
    if (formData) {
      if (faqCategoryList && faqCategoryList.length) {
        let filteredCateroies = faqCategoryList.map(val => ({
          value: val.id,
          label: val.name,
        }))
        setFaqCateories(filteredCateroies)
      }
    }
  }, [formData])

  const {
    QUESTION,
    ADD_VIDEO,
    ANSWER,
    ANSWER_REQUIRED,
    FAQS_CATEGORY,
    FAQS_VIDEO,
    LANGUAGE_CODE,
  } = FAQS.FAQS_FORM
  // TODO: If you want to clear video when language change uncommment below code
  // useEffect(() => {
  //   setSelectedVideos([])
  // }, [currentLanguageCode])

  return (
    <div>
      <Formik
        initialValues={formData}
        validationSchema={FaqValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          errors,
          values,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{QUESTION}</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="question"
                        value={values.question}
                      />
                      {errors.question && touched.question ? (
                        <div className="position-absolute text-danger">
                          {errors.question}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{ANSWER}</Label>
                      <TextEditor
                        content={editorText}
                        setContent={setEditorText}
                      />
                      {requiredEditor ? (
                        <div className="position-absolute text-danger">
                          {ANSWER_REQUIRED}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{FAQS_CATEGORY}</Label>
                        <Select
                          name="faqCategoryName"
                          value={values.faqCategoryName}
                          options={faqCateories}
                          onChange={option => {
                            setFieldTouched("faqCategoryName", true)
                            setFieldValue("faqCategoryName", option)
                          }}
                          isDisabled={childFaqId || parentFaqId ? true : false}
                        />
                        {errors.faqCategoryName && touched.faqCategoryName ? (
                          <div className="position-absolute text-danger">
                            {errors.faqCategoryName}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{LANGUAGE_CODE}</Label>
                        <LanguageDropDown
                          disabled={childFaqId || parentFaqId ? true : false}
                        />
                        {errors.languageCode && touched.languageCode ? (
                          <div className="position-absolute text-danger">
                            {errors.languageCode}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{FAQS_VIDEO} (optional)</Label>
                        <div className="w-100 d-flex flex-wrap gap-4">
                          {selectedVideos[0]?.bhemaBhaiVideoId ? (
                            <div className="video_card_container">
                              <div
                                className="video-close"
                                onClick={() =>
                                  removeVideoCard(
                                    selectedVideos[0].bhemaBhaiVideoId
                                  )
                                }
                              >
                                <MdClose style={{ fontSize: "initial" }} />
                              </div>
                              <div>
                                <img
                                  src={selectedVideos[0].thumbnail}
                                  alt="imagedd"
                                  width="100%"
                                  height="90px"
                                />
                              </div>
                              <div
                                className="text-center video-title text-truncate"
                                title={selectedVideos[0].video_title}
                              >
                                {selectedVideos[0].video_title}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="video_card_container"
                              onClick={() => {
                                setShowMediaModal(true)
                              }}
                            >
                              <div className="attachment-icon">
                                <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                                  <MdAddCircleOutline className="attachment-icon-mdicon" />
                                  <span>{ADD_VIDEO}</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>

                <FormButton
                  customClass="btn-dark"
                  type="submit"
                  btnText={submitbtn}
                  disabled={submitLoader}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {showMeidaModal && (
        <SelectMediaModal
          multiple={false}
          modalShow={showMeidaModal}
          setModalShow={setShowMediaModal}
          setVideos={setSelectedVideos}
          currentLanguageCode={currentLanguageCode}
          modalHeaderText="Select Media"
        />
      )}
    </div>
  )
}

export default FaqForm
