import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import {
  loginError,
  loginSuccess,
  logoutUserError,
  logoutUserSuccess,
} from "./actions"

import { login, logout } from "./httpCalls.login"
import {
  setAuthToken,
  setRefreshToken,
  setUserInfo,
  userLogoutActions,
} from "helpers/utils.helper"
import {
  // ERROR_TOAST,
  ROUTES_PATH,
  SUCCESS_TOAST,
} from "helpers/constants/constants.helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password,
    })
    if (response?.success) {
      toast.success(response?.message, { toastId: SUCCESS_TOAST.LOGIN })
      setAuthToken(response?.data?.access_token)
      setRefreshToken(response?.data?.refresh_token)
      setUserInfo(response?.data)
      yield put(
        loginSuccess(response)
        // loginSuccess({
        //   ...response,
        //   data: { ...response.data, role: "admin", allowedPages: [] },
        // })
      )
      history.push(ROUTES_PATH.BASE)
    } else {
      // if (response?.response?.status === 404)
      //   toast.error(response?.response?.data?.message || "Try again later!!!", {
      //     toastId: ERROR_TOAST.LOGIN,
      //   })
      yield put(loginError(response))
    }
  } catch (error) {
    yield put(loginError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logout)
    if (response.success) {
      toast.success(response?.message, { toastId: SUCCESS_TOAST.LOGOUT })
      yield put(logoutUserSuccess(response))
    } else {
      yield put(logoutUserError(response))
    }
  } catch (error) {
    yield put(logoutUserError(error))
  }
  userLogoutActions()
  history.push("/" + ROUTES_PATH.LOGIN)
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
