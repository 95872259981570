import {
  GET_ALL_ABOUTUS,
  GET_ALL_ABOUTUS_ERROR,
  GET_ALL_ABOUTUS_SUCCESS,
  GET_ABOUTUS,
  GET_ABOUTUS_ERROR,
  GET_ABOUTUS_SUCCESS,
  ADD_ABOUTUS,
  ADD_ABOUTUS_SUCCESS,
  ADD_ABOUTUS_ERROR,
  EDIT_ABOUTUS,
  EDIT_ABOUTUS_SUCCESS,
  EDIT_ABOUTUS_ERROR,
  DELETE_ABOUTUS,
  DELETE_ABOUTUS_SUCCESS,
  DELETE_ABOUTUS_ERROR,
} from "./actionTypes"

export const getAllAboutUs = (params, cb) => {
  return {
    type: GET_ALL_ABOUTUS,
    payload: { params, cb },
  }
}

export const getAllAboutUsSuccess = data => {
  return {
    type: GET_ALL_ABOUTUS_SUCCESS,
    payload: data,
  }
}

export const getAllAboutUsError = error => {
  return {
    type: GET_ALL_ABOUTUS_ERROR,
    payload: error,
  }
}

export const getAboutUs = params => {
  return {
    type: GET_ABOUTUS,
    payload: params,
  }
}

export const getAboutUsSuccess = data => {
  return {
    type: GET_ABOUTUS_SUCCESS,
    payload: data,
  }
}

export const getAboutUsError = error => {
  return {
    type: GET_ABOUTUS_ERROR,
    payload: error,
  }
}

export const addAboutUs = (data, cb) => {
  return {
    type: ADD_ABOUTUS,
    payload: data,
    cb,
  }
}

export const addAboutUsSuccess = params => {
  return {
    type: ADD_ABOUTUS_SUCCESS,
    payload: params,
  }
}

export const addAboutUsError = error => {
  return {
    type: ADD_ABOUTUS_ERROR,
    payload: error,
  }
}

export const editAboutUs = params => {
  return {
    type: EDIT_ABOUTUS,
    payload: params,
  }
}

export const editAboutUsSuccess = params => {
  return {
    type: EDIT_ABOUTUS_SUCCESS,
    payload: params,
  }
}

export const editAboutUsError = error => {
  return {
    type: EDIT_ABOUTUS_ERROR,
    payload: error,
  }
}

export const deleteAboutUs = ({ data, cb }) => {
  return {
    type: DELETE_ABOUTUS,
    payload: data,
    cb,
  }
}

export const deleteAboutUsSuccess = params => {
  return {
    type: DELETE_ABOUTUS_SUCCESS,
    payload: params,
  }
}

export const deleteAboutUsError = error => {
  return {
    type: DELETE_ABOUTUS_ERROR,
    payload: error,
  }
}
