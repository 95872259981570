import { DELETE_PARTNER, GET_ALL_PARTNERS } from "./actionTypes"
import { GET_ALL_PARTNERS_SUCCESS } from "./actionTypes"
import { SET_LOADING_PARTNERS } from "./actionTypes"
import { ADD_PARTNER } from "./actionTypes"

const initialState = {
  partners: [],
  metadata: {},
  loading: false,
}

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARTNERS:
      state = {
        ...state,
        loading: true,
        partners: [],
        metadata: {},
      }
      break
    case GET_ALL_PARTNERS_SUCCESS:
      state = {
        ...state,
        partners: action.payload?.data,
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case ADD_PARTNER:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_PARTNER:
      state = {
        ...state,
        loading: true,
      }
      break
    case SET_LOADING_PARTNERS:
      state = {
        ...state,
        loading: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default partnerReducer
