import { del, get, patch, post } from "helpers/api.helper"

export async function getPrivacy(params) {
  try {
    const res = await get(`/privacy-policy`, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function singlePrivacy(params) {
  try {
    const res = await get(`/privacy-policy/${params}`)
    return res
  } catch (err) {
    return err
  }
}

export async function addPrivacy(params) {
  try {
    const res = await post(`/privacy-policy`, params)
    return res
  } catch (err) {
    return err
  }
}

export async function deletePrivacy(params) {
  try {
    const res = await del(`/privacy-policy`, { data: { ids: params } })
    return res
  } catch (err) {
    return err
  }
}

export async function editPrivacy(params) {
  try {
    const { privacyId, title, title_body, content, language_code } = params
    const res = await patch(`/privacy-policy`, {
      privacyId,
      changes: {
        title,
        title_body,
        content,
        language_code,
      },
    })
    return res
  } catch (err) {
    return err
  }
}
