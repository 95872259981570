import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"
import "assets/scss/custom/components/pagination.scss"

const Pagination = ({ metadata, currentPage = 0, setCurrentPage }) => {
  return (
    <>
      <ReactPaginate
        previousLabel="Previous"
        breakLabel="..."
        nextLabel="Next"
        onPageChange={e => {
          setCurrentPage(e.selected)
        }}
        pageRangeDisplayed={3}
        pageCount={metadata?.totalPages > 0 ? metadata?.totalPages : 1}
        forcePage={currentPage}
        renderOnZeroPageCount={null}
        className="pagination justify-content-end mb-0"
        activeClassName="active"
        previousClassName="page-item"
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        // disabledClassName="disable-pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
      />
    </>
  )
}

Pagination.propTypes = {
  metadata: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
}

export default Pagination
