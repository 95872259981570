import ConfirmationModal from "components/modal/ConfirmationModal"
import { BREADCRUM } from "helpers/constants/constants.helper"
import { getUserPreference } from "helpers/utils.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  getParentBlogList,
  deleteBlog,
  updateChildBlog,
} from "store/actions"
import BlogParentList from "./BlogParentList"
import BlogSettingModal from "./BlogSettingModal"

const Blog = props => {
  const {
    deleteBlog,
    parentBlogList,
    parentBlogLoader,
    parentBlogMetadata,
    blogDeleteLoader,
    languages,
    getParentBlogList,
    setBreadcrumbItems,
    updateChildBlog,
  } = props
  const [currentPage, setCurrentPage] = useState(0)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [selectedBlog, setSelectedBlog] = useState(null)
  const [settingModal, setSettingModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchText, setSearchText] = useState("")

  const getParentBlogListParams = searchText => ({
    pageNum: currentPage,
    recordLimit: recordLimit,
    searchText,
  })

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.BLOG)
  }, [])

  useEffect(() => {
    let timeout = null
    if (searchText) {
      timeout = setTimeout(
        () => getParentBlogList(getParentBlogListParams(searchText)),
        500
      )
    } else getParentBlogList(getParentBlogListParams())
    return () => timeout && clearTimeout(timeout)
  }, [currentPage, recordLimit, searchText])

  const handleDelete = (blogId, isChild = false) => {
    const callBack = isChild
      ? () => {
          if (selectedBlog.BlogLanguage.length > 1)
            setSelectedBlog({
              ...selectedBlog,
              BlogLanguage: selectedBlog.BlogLanguage.filter(
                item => item.id !== blogId
              ),
            })
          else {
            setSettingModal(false)
          }
          if (parentBlogList.length === 1 && currentPage > 0)
            setCurrentPage(currentPage - 1)
        }
      : () => {
          setDeleteModal(false)
          if (parentBlogList.length === 1 && currentPage > 0)
            setCurrentPage(currentPage - 1)
        }
    deleteBlog(blogId, currentPage, parentBlogList.length, callBack)
  }

  const handleStatusChange = (childBlogId, status, cb) => {
    const callback = () => {
      setSelectedBlog({
        ...selectedBlog,
        BlogLanguage: selectedBlog.BlogLanguage.map(blog => {
          return blog.id === childBlogId ? { ...blog, status } : blog
        }),
      })
      cb()
    }

    const statusUpdateData = {
      blogLangId: childBlogId,
      changes: { status },
    }
    updateChildBlog(
      statusUpdateData,
      callback,
      getParentBlogListParams(searchText)
    )
  }

  return (
    <>
      <BlogParentList
        parentBlogLoader={parentBlogLoader}
        parentBlogList={parentBlogList}
        parentBlogMetadata={parentBlogMetadata}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordLimit={recordLimit}
        setRecordLimit={setRecordLimit}
        selectedBlog={selectedBlog}
        setSelectedBlog={setSelectedBlog}
        settingModal={settingModal}
        setSettingModal={setSettingModal}
        setDeleteModal={setDeleteModal}
        languages={languages}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <ConfirmationModal
        confirmBtnText="Delete"
        modalHeaderText="You are about to delete Blog. It will be removed from website, Are you sure?"
        confirmAction={() => {
          handleDelete(selectedBlog?.id, false)
        }}
        modalShow={deleteModal}
        setModalShow={setDeleteModal}
        loading={blogDeleteLoader}
      />
      <BlogSettingModal
        modalHeaderText="Blog Setting"
        modalShow={settingModal}
        setModalShow={setSettingModal}
        currentSelectedBlog={selectedBlog}
        handleDelete={handleDelete}
        handleStatusChange={handleStatusChange}
      />
    </>
  )
}

const mapStatetoProps = state => {
  const { Blog, Language } = state
  const { currentLanguageCode, languages } = Language
  const {
    parentBlogList,
    parentBlogLoader,
    parentBlogMetadata,
    blogDeleteLoader,
  } = Blog
  return {
    parentBlogList,
    parentBlogLoader,
    parentBlogMetadata,
    blogDeleteLoader,
    languages,
    currentLanguageCode,
  }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getParentBlogList,
  deleteBlog,
  updateChildBlog,
})(Blog)
