import { INSURER_URL } from "helpers/api-end-points.constant.helper"
import { del, get, patch, post } from "helpers/api.helper"

export async function getInsurerList(params) {
  try {
    const res = await get(INSURER_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function addInsurer(data) {
  try {
    const res = await post(INSURER_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function getInsurer(id) {
  try {
    const res = await get(`${INSURER_URL}/${id}`)
    return res
  } catch (err) {
    return err
  }
}

export async function updateInsurer(data) {
  try {
    const res = await patch(INSURER_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function deleteInsurer(id) {
  try {
    const res = await del(`${INSURER_URL}/${id}`)
    return res
  } catch (err) {
    return err
  }
}
