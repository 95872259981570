import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_ABOUTUS,
  DELETE_ABOUTUS,
  EDIT_ABOUTUS,
  GET_ALL_ABOUTUS,
  GET_ABOUTUS,
} from "./actionTypes"
import {
  addAboutUsError,
  addAboutUsSuccess,
  deleteAboutUsError,
  deleteAboutUsSuccess,
  editAboutUsError,
  editAboutUsSuccess,
  getAboutUsError,
  getAboutUsSuccess,
  getAllAboutUsError,
  getAllAboutUsSuccess,
} from "./actions"
import {
  addAboutUs,
  deleteAboutUs,
  editAboutUs,
  getAboutUs,
  singleAboutUs,
} from "./httpCalls.aboutus"
import { SUCCESS_TOAST } from "helpers/constants/constants.helper"

function* getAllAboutUs(action) {
  const { params } = action.payload
  const response = yield call(getAboutUs, params)
  if (response?.success) {
    if (response?.data?.length) {
      action.payload.cb(response?.data)
    }
    yield put(getAllAboutUsSuccess(response))
  } else {
    yield put(getAllAboutUsError())
  }
}

function* getSingleAboutUs(action) {
  const { payload } = action
  const response = yield call(singleAboutUs, payload)
  if (response?.success) {
    yield put(getAboutUsSuccess(response))
  } else {
    yield put(getAboutUsError())
  }
}

function* AddAboutUs(action) {
  const { payload, cb } = action
  const response = yield call(addAboutUs, payload)
  if (response?.success) {
    toast.success(response?.message, { toastId: SUCCESS_TOAST.ADD_ABOUTUS })
    yield put(addAboutUsSuccess())
    cb()
  } else {
    yield put(addAboutUsError())
  }
}

function* EditAboutUs(action) {
  const { payload } = action
  const response = yield call(editAboutUs, payload)
  if (response?.success) {
    toast.success(response?.message, { toastId: SUCCESS_TOAST.EDIT_ABOUTUS })
    yield put(editAboutUsSuccess())
  } else {
    yield put(editAboutUsError())
  }
}

function* DeleteAboutUs(action) {
  const { payload, cb } = action
  const response = yield call(deleteAboutUs, payload)
  if (response?.success) {
    toast.success(response?.message, {
      toastId: SUCCESS_TOAST.DELETE_ABOUTUS,
    })
    cb()
    yield put(deleteAboutUsSuccess())
  } else {
    yield put(deleteAboutUsError())
  }
}

function* AboutUsSaga() {
  yield takeEvery(GET_ALL_ABOUTUS, getAllAboutUs)
  yield takeEvery(GET_ABOUTUS, getSingleAboutUs)
  yield takeEvery(ADD_ABOUTUS, AddAboutUs)
  yield takeEvery(EDIT_ABOUTUS, EditAboutUs)
  yield takeEvery(DELETE_ABOUTUS, DeleteAboutUs)
}

export default AboutUsSaga
