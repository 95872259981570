import TableFooter from "components/common/TableFooter"
import {
  BREADCRUM,
  FOUR_WHEELER_HEADER,
  HEALTH_INSURANCE_HEADER,
  PRODUCT_TYPE,
  TWO_WHEELER_HEADER,
  OTHER_INSURANCE_HEADER,
  COMMERCIAL_INSURANCE_HEADER,
  TRAVEL_INSURANCE_HEADER,
} from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row } from "reactstrap"
import LeadTabs from "./leadTabs"
import { setBreadcrumbItems } from "store/actions"
import { getAllProducts } from "store/product/actions"
import { getAllLeads, downloadLeads } from "store/leads/actions"
import { getUserPreference } from "helpers/utils.helper"
import { toast } from "react-toastify"
import FormButton from "components/form-components/FormButton"
import { MdOutlineSwapHoriz } from "react-icons/md"

// const breadcrumbItems = [
//   { title: BREADCRUM.POLICY_LEADER, link: "/" },
//   { title: BREADCRUM.LEADS, link: "/" },
// ]

const Leads = ({
  setBreadcrumbItems,
  downloadLeads,
  getAllProducts,
  products,
  loading,
  getAllLeads,
  metadata,
  leads,
  leadsLoading,
}) => {
  let today = new Date().toISOString().slice(0, 10)
  let previousDate = new Date()
  previousDate.setDate(previousDate.getDate() - 1)
  previousDate = previousDate.toISOString().slice(0, 10)
  const [activeTab, setActiveTab] = useState(false)
  const [startDate, setStartDate] = useState(previousDate)
  const [endDate, setEndDate] = useState(today)
  const [recordLimit, setrecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [currentPage, setCurrentPage] = useState(0)
  const [leadData, setLeadData] = useState(leads)
  const [thead, setThead] = useState([])
  const [isdownload, setIsDownload] = useState(false)
  function setTabs(activeTab) {
    if (activeTab === PRODUCT_TYPE.BIKE) {
      setThead(TWO_WHEELER_HEADER)
    }
    if (activeTab === PRODUCT_TYPE.CAR) {
      setThead(FOUR_WHEELER_HEADER)
    }
    if (activeTab === PRODUCT_TYPE.HEALTH) {
      setThead(HEALTH_INSURANCE_HEADER)
    }
    if (activeTab === PRODUCT_TYPE.COMMERCIAL) {
      setThead(COMMERCIAL_INSURANCE_HEADER)
    }
    if (activeTab === PRODUCT_TYPE.TRAVEL) {
      setThead(TRAVEL_INSURANCE_HEADER)
    }
    if (activeTab === PRODUCT_TYPE.OTHERS) {
      setThead(OTHER_INSURANCE_HEADER)
    }
  }

  function checkEndDate(e, select) {
    const nowDate = new Date()
    if (select === "start") {
      const checkInStartDate = new Date(e.target.value)
      const checkInEndDate = new Date(endDate)
      if (checkInStartDate > nowDate) {
        toast.error("Date must be minimun today's date!!!", {
          toastId: "errorStart",
        })
      } else if (checkInStartDate > checkInEndDate) {
        toast.error("Date must be greater than end date!!!", {
          toastId: "errorStart",
        })
      } else {
        setStartDate(e.target.value)
      }
    }
    if (select === "end") {
      const checkInEndDate = new Date(e.target.value)
      const checkInStart = new Date(startDate)
      if (checkInEndDate > nowDate) {
        toast.error("Date must be minimun today's date!!!", {
          toastId: "errorEndDate",
        })
      } else if (checkInEndDate < checkInStart) {
        toast.error("Date must be greater than start date!!!", {
          toastId: "errorEndDate",
        })
      } else {
        setEndDate(e.target.value)
      }
    }
  }

  function fileDownload(activeTab, start, end) {
    setIsDownload(true)
    downloadLeads({
      activeTab,
      start,
      end,
      cb: () => {
        setIsDownload(false)
      },
    })
  }

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.LEADS)
    getAllProducts()
  }, [])

  useEffect(() => {
    if (products && products.length) {
      setActiveTab(products[0]?.name)
    }
  }, [products])

  useEffect(() => {
    let x = setTimeout(() => {
      setIsDownload(false)
    }, 4000)

    return () => {
      clearTimeout(x)
    }
  }, [isdownload])

  useEffect(() => {
    setLeadData(leads)
  }, [leads])

  useEffect(() => {
    setCurrentPage(0)
    setTabs(activeTab)
  }, [activeTab])

  useEffect(() => {
    if (activeTab) {
      getAllLeads({
        activeTab,
        start: startDate,
        end: endDate,
        recordLimit,
        currentPage,
      })
    }
  }, [startDate, endDate, recordLimit, currentPage, thead])

  if (loading) {
    return <>Loading.....</>
  }
  return (
    <>
      <div className="position-relative">
        <Row className="align-items-start position-absolute d-flex justify-content-center right-5">
          <div className="d-flex gap-3">
            <div className="d-flex gap-3 align-items-center ">
              <div>
                <input
                  placeholder="Start date"
                  value={startDate}
                  onChange={e => checkEndDate(e, "start")}
                  type="date"
                  className="form-control lead-date"
                />
              </div>
              <div>
                <MdOutlineSwapHoriz style={{ fontSize: "1.2rem" }} />
              </div>
              <div>
                <input
                  placeholder="EndDate"
                  value={endDate}
                  onChange={e => checkEndDate(e, "end")}
                  type="date"
                  className="form-control lead-date"
                />
              </div>
            </div>
            <div className="col-sm-6 w-8rem">
              <FormButton
                type="button"
                disabled={isdownload || leadData.length ? false : true}
                customClass="btn-dark"
                clickEvent={() => fileDownload(activeTab, startDate, endDate)}
                btnText="Export"
                loading={isdownload}
              ></FormButton>
            </div>
          </div>
        </Row>
      </div>
      <LeadTabs
        products={products}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        thead={thead}
        leadsLoading={leadsLoading}
        leads={leadData}
      />
      {thead?.length && leadData && leadData.length ? (
        <TableFooter
          metadata={metadata}
          setrecordLimit={setrecordLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordLimit={recordLimit}
        />
      ) : null}
    </>
  )
}

const mapStatetoProps = state => {
  const { products, loading } = state.Product
  const { leads, loading: leadsLoading, metadata } = state.Lead
  return { products, loading, leads, leadsLoading, metadata }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getAllProducts,
  getAllLeads,
  downloadLeads,
})(Leads)
