import FormButton from "components/form-components/FormButton"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React from "react"
import { Link } from "react-router-dom"
import { TabContent, TabPane } from "reactstrap"
import InsurerTable from "./InsurerTable"
import TableFooter from "components/common/TableFooter"

const InsurerList = ({
  searchText,
  setSearchText,
  setCurrentPage,
  insurerList,
  insurerLoading,
  metadata,
  currentPage,
  recordLimit,
  setRecordLimit,
  setCurrentSelectedInsurer,
  setShowDeleteModal,
}) => {
  return (
    <>
      <TabContent activeTab={1} className="p-3 text-muted bg-white">
        <TabPane tabId={1}>
          <div className="row mb-2">
            <div className="col-3">
              <input
                className="form-control"
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={e => {
                  setCurrentPage(0)
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <div className="col-6"></div>
            <div className="col-3">
              <Link
                to={`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}/${ROUTES_PATH.ADD_INSURER}`}
              >
                <FormButton
                  customClass="btn-dark"
                  btnText="Add Insurer"
                  type="button"
                />
              </Link>
            </div>
          </div>
          <InsurerTable
            insuereList={insurerList}
            insurerLoading={insurerLoading}
            setCurrentSelectedInsurer={setCurrentSelectedInsurer}
            setShowDeleteModal={setShowDeleteModal}
          />
          {insurerList.length > 0 && (
            <TableFooter
              metadata={metadata}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordLimit={recordLimit}
              setrecordLimit={setRecordLimit}
            />
          )}
        </TabPane>
      </TabContent>
    </>
  )
}

export default InsurerList
