import React from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import { ROUTES_PATH } from "helpers/constants/constants.helper"

const LANGUAGE_DROPDOWN_PATH = [
  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}`,
  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}/${ROUTES_PATH.ADD_CONTACT_US}`,
  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.CONTACT_US}/${ROUTES_PATH.UPDATE_CONTACT_US}`,
  `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.ABOUT_US}`,
]

const Breadcrumb = props => {
  const itemLength = (props.breadcrumbItems || []).length || 1
  const location = useLocation()
  const customStyle = {
    color: "inherit",
  }

  return (
    <Row style={{ paddingBottom: "12px" }}>
      <Col sm={9} className="d-flex align-items-center">
        <div className="page-title-box pb-0 d-flex align-items-baseline">
          <h4 className="m-0">
            {props.title?.pageTitleLink ? (
              <Link to={props.title?.pageTitleLink} style={customStyle}>
                {props.title?.title}
              </Link>
            ) : (
              props.title
            )}
            {props.breadcrumbItems ? <> &gt;</> : null}
          </h4>
          <ol className="breadcrumb ms-2 m-0">
            {(props.breadcrumbItems || []).map((item, key) =>
              key + 1 === itemLength ? (
                <li key={key} className="breadcrumb-item active">
                  {item.title}
                </li>
              ) : (
                <li key={key} className="breadcrumb-item">
                  <Link to={item.link}>{item.title}</Link>
                </li>
              )
            )}
          </ol>
        </div>
      </Col>
      <Col sm={3}>
        {LANGUAGE_DROPDOWN_PATH.includes(location.pathname) && (
          <LanguageDropDown />
        )}
      </Col>
    </Row>
  )
}

const mapStatetoProps = state => {
  const BreadcrumbData = state.Breadcrumb
  return {
    title: BreadcrumbData.title,
    breadcrumbItems: BreadcrumbData.breadcrumbItems,
  }
}

export default connect(mapStatetoProps, {})(Breadcrumb)
