import {
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_ERROR,
} from "./actionTypes"

const initialState = {
  products: [],
  loading: false,
}

const product = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALL_PRODUCT_SUCCESS:
      state = {
        ...state,
        products: action.payload?.data,
        loading: false,
      }
      break
    case GET_ALL_PRODUCT_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default product
