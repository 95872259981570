import {
  ADD_BANNER,
  DELETE_BANNER,
  GET_BANNER,
  GET_BANNER_LIST,
  GET_BANNER_LIST_SUCCESS,
  GET_PARENT_BANNER,
  SET_LOADING,
  UPDATE_BANNER,
} from "./actionTypes"

export const getBannerList = params => ({
  type: GET_BANNER_LIST,
  payload: params,
})

export const getBannerListSuccess = (data, metadata) => ({
  type: GET_BANNER_LIST_SUCCESS,
  payload: { data, metadata },
})

export const getParentBanner = (id, cb) => ({
  type: GET_PARENT_BANNER,
  payload: { id, cb },
})

export const setLoading = payload => ({
  type: SET_LOADING,
  payload,
})

export const addBanner = (data, cb) => ({
  type: ADD_BANNER,
  payload: { data, cb },
})

export const getBanner = (id, cb) => ({
  type: GET_BANNER,
  payload: { id, cb },
})

export const updateBanner = (data, cb) => ({
  type: UPDATE_BANNER,
  payload: { data, cb },
})

export const deleteBanner = (id, cb, apiEndpoint) => ({
  type: DELETE_BANNER,
  payload: { id, apiEndpoint, cb },
})
