import { BLOG_URL, SLUG_URL } from "helpers/api-end-points.constant.helper"
import { del, get, patch, post } from "helpers/api.helper"

export async function getParentBlogList(params) {
  try {
    const res = await get(BLOG_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function getBlogData(blogId) {
  const url = BLOG_URL + "/" + blogId
  try {
    const res = await get(url)
    return res
  } catch (err) {
    return err
  }
}

export async function isUniqueBlogSlug(blogSlug) {
  try {
    const res = await post(BLOG_URL + SLUG_URL, { slug: blogSlug })
    return res
  } catch (err) {
    return err
  }
}

export async function addParentBlog(data) {
  try {
    const res = await post(BLOG_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function updateChildBlog(data) {
  try {
    const res = await patch(BLOG_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function deleteBlog(blogId) {
  try {
    const res = await del(BLOG_URL, {
      data: { id: blogId },
    })
    return res
  } catch (err) {
    return err
  }
}
