import { BREADCRUM } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  getAllPrivacy,
  editPrivacy,
  addPrivacy,
} from "store/actions"
import { Card, Col, Label, Row } from "reactstrap"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import TextEditor from "components/common/TextEditor"
import { toast } from "react-toastify"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import FormButton from "components/form-components/FormButton"
import { PRIVACY } from "helpers/constants/labels.constants"

// const breadcrumbItems = [
//   { title: BREADCRUM.POLICY_LEADER, link: "/" },
//   { title: BREADCRUM.PRIVACY, link: "/" },
// ]

const initalPrivacyForm = {
  title: "",
  title_body: "",
}

const PrivacyValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required !!!"),
  title_body: Yup.string().required("Required !!!"),
})

const Privacy = ({
  setBreadcrumbItems,
  getAllPrivacy,
  addPrivacy,
  editPrivacy,
  privacy,
  currentLanguageCode,
}) => {
  const [fromData, setFormData] = useState(initalPrivacyForm)
  const [editorText, setEditorText] = useState("")
  const [requiredEditor, setRequiredEditor] = useState(false)
  const [editorError, setEditorError] = useState("Required!!!")
  const [privacyId, setPrivacyId] = useState("")
  const { TITLE, DESCRIPTION, CONTENT } = PRIVACY.PRIVACY_FORM

  const resetForm = () => {
    setFormData(initalPrivacyForm)
    setEditorText("")
    setPrivacyId("")
  }

  const onSubmit = value => {
    if (editorText.length) {
      if (editorText.length > 10) {
        if (privacyId.length) {
          editPrivacy({ ...value, privacyId, content: editorText })
        } else {
          addPrivacy({
            ...value,
            content: editorText,
            language_code: currentLanguageCode,
          })
        }
      } else {
        setRequiredEditor(true)
        setEditorError("Minimum 10 character required!!!")
      }
    } else {
      setEditorError("Required !!!")
      setRequiredEditor(true)
    }
  }

  useEffect(() => {
    resetForm()
    setBreadcrumbItems(BREADCRUM.PRIVACY_POLICY)
    getAllPrivacy({ language: currentLanguageCode })
  }, [currentLanguageCode])

  useEffect(() => {
    setRequiredEditor(false)
  }, [editorText])

  useEffect(() => {
    if (privacy && privacy.length) {
      if (privacy[0]?.id) {
        setPrivacyId(privacy[0]?.id)
        setEditorText(privacy[0]?.content)
        setFormData({
          title: privacy[0]?.title,
          title_body: privacy[0]?.title_body,
        })
      } else {
        toast.error("Problem with data ID not found")
      }
    }
  }, [privacy])

  return (
    <>
      <div>
        <Formik
          initialValues={fromData}
          validationSchema={PrivacyValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xl="8" sm="12">
                  <Card className="p-3">
                    <Row>
                      <div className="mb-4">
                        <Label>{TITLE}</Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="title"
                        />
                        {errors.title && touched.title ? (
                          <div className="position-absolute text-danger">
                            {errors.title}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{DESCRIPTION}</Label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="title_body"
                        />
                        {errors.title_body && touched.title_body ? (
                          <div className="position-absolute text-danger">
                            {errors.title_body}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{CONTENT}</Label>
                        <TextEditor
                          content={editorText}
                          setContent={setEditorText}
                          setFieldValue={setFieldValue}
                        />
                        {requiredEditor ? (
                          <div className="position-absolute text-danger">
                            {editorError}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <Card className="p-3">
                    <Row>
                      <LanguageDropDown />
                    </Row>
                  </Card>
                  <Row>
                    <div>
                      <FormButton
                        customClass="btn-dark"
                        type="submit"
                        btnText={
                          privacyId.length
                            ? "Update Privacy Policy"
                            : "Add Privacy Policy"
                        }
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const mapStatetoProps = state => {
  const { languages, currentLanguageCode } = state.Language
  const { privacy } = state.Privacy
  return { privacy, languages, currentLanguageCode }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getAllPrivacy,
  editPrivacy,
  addPrivacy,
})(Privacy)
