import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import {
  BLOG_CATEGORY_ARRAY,
  BLOG_CATEGORY_OBJ,
} from "helpers/constants/enum.constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  setCurrentLanguageCode,
  addMedia,
  addParentBlog,
  getBlogData,
  updateChildBlog,
  isUniqueBlogSlug,
} from "store/actions"
import BlogForm from "./BlogForm"
import * as Yup from "yup"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { formatBytes } from "helpers/utils.helper"

const parentBlogBreadcrumb = {
  title: BREADCRUM.BLOG,
  pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`,
}

const addBlogBreadcrumbItems = [{ title: BREADCRUM.ADD_BLOG, link: "/" }]
const editBlogBreadcrumbItems = [{ title: BREADCRUM.EDIT_BLOG, link: "/" }]

const initalBlogForm = {
  title: "",
  slug: "",
  authorname: "",
  blogCreatedDate: new Date().toISOString().slice(0, 10),
  blogCategory: {
    value: BLOG_CATEGORY_OBJ.CAR.id,
    label: BLOG_CATEGORY_OBJ.CAR.name,
  },
  posterId: "",
  summary: "",
}

const blogValidationSchema = Yup.object().shape({
  title: Yup.string().required(),
  slug: Yup.string()
    .min(2)
    .max(20)
    .matches(/^[\w-]+$/, "Only alpha-numeric and hyphen allowed")
    .required(),
  authorname: Yup.string(),
  blogCreatedDate: Yup.string().required(),
  blogCategory: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  posterId: Yup.string().required("Image Required"),
  summary: Yup.string().required(),
})

const AddBlog = props => {
  const {
    currentLanguageCode,
    blogFormSubmitLoader,
    languages,
    blogUpdateLoader,
    getBlogData,
    addMedia,
    addParentBlog,
    setCurrentLanguageCode,
    setBreadcrumbItems,
    updateChildBlog,
    isUniqueBlogSlug,
    fileLoader,
  } = props
  const history = useHistory()
  const { parentBlogId, parentBlogLanguage, childBlogId } = useParams()
  const [formData, setFormData] = useState(initalBlogForm)
  const [editorText, setEditorText] = useState("")
  const [selectedVideos, setSelectedVideos] = useState([])
  const [requiredEditor, setRequiredEditor] = useState(false)
  const [isUniqueSlugState, setIsUniqueSlugState] = useState(false)
  const [slugStr, setSlugStr] = useState("")

  useEffect(() => {
    setBreadcrumbItems(
      parentBlogBreadcrumb,
      childBlogId ? editBlogBreadcrumbItems : addBlogBreadcrumbItems
    )
    if (childBlogId && languages.length > 1) {
      getBlogData(parentBlogId, res => {
        if (res?.success) {
          const parentData = res?.data[0]
          const childData = parentData?.BlogLanguage?.find(
            item => item.id === childBlogId
          )
          if (parentData && childData)
            handleEditChildData(parentData, childData)
          else {
            history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
            toast.error("Something went wrong!!")
          }
        } else {
          history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
          toast.error("Something went wrong!!")
        }
      })
    } else if (parentBlogId && languages.length > 1) {
      getBlogData(parentBlogId, res => {
        if (res?.success) {
          handleAddChildParentData(res.data[0])
        } else {
          history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
          toast.error("Something went wrong!!")
        }
      })
    }
  }, [languages])

  useEffect(() => {
    setRequiredEditor(false)
  }, [editorText])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (slugStr?.length > 1) {
        isUniqueBlogSlug(slugStr, res => setIsUniqueSlugState(res?.success))
      } else setIsUniqueSlugState(false)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [slugStr])

  const onSubmit = values => {
    const {
      title,
      summary,
      slug,
      posterId,
      blogCreatedDate,
      blogCategory,
      authorname,
    } = values
    const data = {
      title,
      slug,
      authorname,
      summary,
      content: editorText,
      blogCreatedDate: new Date(blogCreatedDate).toISOString(),
      blogCategoryId: blogCategory.value,
      posterId,
      languageCode: currentLanguageCode,
    }
    if (childBlogId) {
      delete data.slug
      delete data.blogCreatedDate
      delete data.blogCategoryId
      delete data.languageCode
      const requestBody = {
        blogLangId: childBlogId,
        changes: data,
      }
      updateChildBlog(requestBody, () =>
        history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
      )
    } else {
      if (parentBlogId) {
        data.blogId = parentBlogId
        delete data.slug
        delete data.blogCreatedDate
      }
      addParentBlog(data, () => {
        history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
      })
    }
  }

  function uploadFile(file, fieldId, setFieldValue) {
    const formdata = new FormData()
    formdata.append("name", file[0].name)
    formdata.append("file", file[0])
    addMedia({
      formdata,
      cb: res => {
        setFieldValue(fieldId, res[0].id)
        setSelectedVideos(res)
      },
    })
  }

  const handleAddChildParentData = parentData => {
    // validation current url data with the parent data
    // validation for url lang code is valid
    if (
      languages.length &&
      !languages.find(item => item.language_code === parentBlogLanguage)
    )
      history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
    // validate add child lang is already added into the parent data
    else if (
      parentData.BlogLanguage.find(
        item => item.language_code === parentBlogLanguage
      )
    )
      history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}`)
    // set url lang code in the state if not
    else {
      if (currentLanguageCode !== parentBlogLanguage)
        setCurrentLanguageCode(parentBlogLanguage)
      // set form data
      setFormData({
        ...initalBlogForm,
        blogCategory: {
          label: parentData.master_blog_categories.name,
          value: parentData.master_blog_categories.id,
        },
        slug: parentData.slug,
        blogCreatedDate: new Date(parentData.blog_created_date)
          .toISOString()
          .slice(0, 10),
      })
    }
  }

  const handleEditChildData = (parentData, childData) => {
    const {
      id = "",
      location = "",
      originalname = "Unknown",
      size,
    } = childData?.poster_image
    // set url lang code in the state if not
    if (currentLanguageCode !== childData.language_code)
      setCurrentLanguageCode(childData.language_code)
    // set form data
    setFormData({
      ...initalBlogForm,
      title: childData.title,
      slug: parentData.slug,
      authorname: childData.authorname,
      blogCreatedDate: new Date(parentData.blog_created_date)
        .toISOString()
        .slice(0, 10),
      blogCategory: {
        value: parentData.master_blog_categories.id,
        label: parentData.master_blog_categories.name,
      },
      posterId: childData.poster_image.id,
      summary: childData.summary,
    })
    setEditorText(childData?.content)
    setSelectedVideos([
      {
        id,
        preview: location,
        name: originalname,
        formattedSize: size ? formatBytes(size) : "Unknown",
      },
    ])
  }

  return (
    <BlogForm
      formData={formData}
      editorText={editorText}
      selectedVideos={selectedVideos}
      setSelectedVideos={setSelectedVideos}
      blogValidationSchema={blogValidationSchema}
      onSubmit={onSubmit}
      setEditorText={setEditorText}
      submitbtn={childBlogId ? "Edit Blog" : "Add Blog"}
      blogCategoryList={BLOG_CATEGORY_ARRAY.map(item => ({
        value: item.id,
        label: item.name,
      }))}
      currentLanguageCode={currentLanguageCode}
      submitLoader={blogFormSubmitLoader}
      requiredEditor={requiredEditor}
      setRequiredEditor={setRequiredEditor}
      uploadFile={uploadFile}
      parentBlogId={parentBlogId}
      blogUpdateLoader={blogUpdateLoader}
      isUniqueSlugState={isUniqueSlugState}
      setSlugStr={setSlugStr}
      fileLoader={fileLoader}
    />
  )
}

const mapStatetoProps = state => {
  const { Language, Blog, Media } = state
  const { currentLanguageCode, languages } = Language
  const { blogFormSubmitLoader, blogUpdateLoader } = Blog
  const { loading: fileLoader } = Media
  return {
    currentLanguageCode,
    blogFormSubmitLoader,
    languages,
    blogUpdateLoader,
    fileLoader,
  }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  setCurrentLanguageCode,
  addMedia,
  addParentBlog,
  getBlogData,
  updateChildBlog,
  isUniqueBlogSlug,
})(AddBlog)
