export const GET_ALL_PARENT_FAQS = "GET_ALL_PARENT_FAQS"
export const GET_ALL_PARENT_FAQS_SUCCESS = "GET_ALL_PARENT_FAQS_SUCCESS"
export const GET_PARENT_FAQ = "GET_PARENT_FAQ"
export const GET_PARENT_FAQ_SUCCESS = "GET_PARENT_FAQ_SUCCESS"
export const SET_PARENT_FAQ_LOADING = "SET_PARENT_FAQ_LOADING"

export const UPDATE_PARENT_FAQ = "UPDATE_PARENT_FAQ"
export const SET_UPDATE_PARENT_FAQ_LOADER = "SET_UPDATE_PARENT_FAQ_LOADER"

export const ADD_CHILD_FAQ = "ADD_CHILD_FAQ"
export const ADD_CHILD_FAQ_SUCCESS = "ADD_CHILD_FAQ_SUCCESS"
export const SET_CHILD_FAQ_LOADER = "SET_CHILD_FAQ_LOADER"

export const DELETE_FAQS = "DELETE_FAQS"
export const SET_DELETE_FAQS_LOADER = "SET_DELETE_FAQS_LOADER"

export const GET_CHILD_FAQ = "GET_CHILD_FAQ"
export const SET_GET_CHILD_FAQ_LOADER = "SET_GET_CHILD_FAQ_LOADER"

export const UPDATE_CHILD_FAQ = "UPDATE_CHILD_FAQ"
export const UPDATE_CHILD_FAQ_LOADER = "UPDATE_CHILD_FAQ_LOADER"
