import {
  ADD_FEATURED_TESTIMONIAL_URL,
  TESTIMONIAL_CHILD_URL,
  TESTIMONIAL_URL,
} from "helpers/api-end-points.constant.helper"
import { get, post, del, put, patch } from "helpers/api.helper"

export async function callGetAllTestimonials(params) {
  try {
    const res = await get(TESTIMONIAL_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function callGetTestimonial(testimonialId) {
  try {
    const res = await get(TESTIMONIAL_URL + "/" + testimonialId)
    return res
  } catch (err) {
    return err
  }
}

export async function callAddTestimonialApi(data) {
  try {
    const res = await post(TESTIMONIAL_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function callUpdateTestimonial(testimonialData) {
  try {
    const res = await put(TESTIMONIAL_URL, { ...testimonialData })
    return res
  } catch (err) {
    return err
  }
}

export async function callAddFeatureTestimonialAPI(requestBody) {
  try {
    const res = await patch(ADD_FEATURED_TESTIMONIAL_URL, { ...requestBody })
    return res
  } catch (err) {
    return err
  }
}

export async function callDeleteTestimonial(
  paramsTestimonialId,
  isChild = true
) {
  try {
    const url = isChild ? TESTIMONIAL_CHILD_URL : TESTIMONIAL_URL
    const res = await del(url + "/" + paramsTestimonialId)
    return res
  } catch (err) {
    return err
  }
}
