import { GET_BANNER_LIST_SUCCESS, SET_LOADING } from "./actionTypes"

const initialState = {
  bannerList: [],
  bannerLoading: false,
  metadata: {},
}

const Banner = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER_LIST_SUCCESS:
      state = {
        ...state,
        bannerList: action.payload.data,
        metadata: action.payload.metadata,
      }
      break
    case SET_LOADING:
      state = {
        ...state,
        bannerLoading: action.payload,
      }
  }
  return state
}

export default Banner
