import { combineReducers } from "redux"

// Front
// import Layout from "./layout/reducer"

import Breadcrumb from "./breadcrumb/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Product
import Product from "./product/reducer"

// Faqs
import Faq from "./faqs/reducer"

// Leads
import Lead from "./leads/reducer"

// Leads
import Language from "./language/reducer"

// Partner
import Partner from "./partner/reducer"

// Contact us
import ContactUs from "./contact-us/reducer"

//Calendar
import calendar from "./calendar/reducer"
import AboutUs from "./aboutus/reducer"
import Privacy from "./privacy/reducer"
import SocialMedia from "./social-media/reducer"
import BimaBhaiVideos from "./bima-bhai-videos/reducer"
import Media from "./media/reducer"
import Tag from "./tags/reducer"
import Search from "./search/reducer"
import LeadPages from "./lead-pages/reducer"
import Blog from "./blog/reducer.blog"
import Testimonial from "./testimonial/reducer"

// banner
import Banner from "./banner/reducer"
import Insurer from "./insurers/reducer"

const rootReducer = combineReducers({
  Partner,
  ContactUs,
  Breadcrumb,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Product,
  Lead,
  Faq,
  AboutUs,
  Language,
  Privacy,
  calendar,
  SocialMedia,
  BimaBhaiVideos,
  Media,
  Tag,
  Search,
  LeadPages,
  Blog,
  Testimonial,
  Banner,
  Insurer,
})

export default rootReducer
