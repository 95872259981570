import axiosApi from "helpers/api.helper"

export async function callAddMedia(file) {
  try {
    const res = await axiosApi.post("/media", file, {
      headers: {
        "content-Type": "multipart/form-data",
      },
      timeout: null,
    })
    return res
  } catch (err) {
    return err
  }
}
