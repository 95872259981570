import {
  ADD_ABOUTUS,
  ADD_ABOUTUS_ERROR,
  ADD_ABOUTUS_SUCCESS,
  DELETE_ABOUTUS,
  DELETE_ABOUTUS_ERROR,
  DELETE_ABOUTUS_SUCCESS,
  EDIT_ABOUTUS,
  EDIT_ABOUTUS_ERROR,
  EDIT_ABOUTUS_SUCCESS,
  GET_ALL_ABOUTUS,
  GET_ALL_ABOUTUS_ERROR,
  GET_ALL_ABOUTUS_SUCCESS,
  GET_ABOUTUS,
  GET_ABOUTUS_ERROR,
  GET_ABOUTUS_SUCCESS,
} from "./actionTypes"

const initialState = {
  aboutus: {},
  loading: false,
}

const aboutus = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ABOUTUS:
      state = {
        aboutus: {},
        loading: true,
      }
      break
    case GET_ALL_ABOUTUS_SUCCESS:
      state = {
        aboutus: action.payload?.data[0],
        loading: false,
      }
      break
    case GET_ALL_ABOUTUS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_ABOUTUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ABOUTUS_SUCCESS:
      state = {
        ...state,
        aboutus: action.payload?.data[0],
        loading: false,
      }
      break
    case GET_ABOUTUS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_ABOUTUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_ABOUTUS_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_ABOUTUS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case EDIT_ABOUTUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_ABOUTUS_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case EDIT_ABOUTUS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_ABOUTUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_ABOUTUS_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_ABOUTUS_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default aboutus
