import { get, post } from "helpers/api.helper"
import { ADD_LANGUAGE_URL } from "helpers/api-end-points.constant.helper"

export async function getallLanguages({ payload }) {
  try {
    const { pageNum, recordLimit } = payload
    const res = await get("/lang", { params: { pageNum, recordLimit } })
    return res
  } catch (err) {
    return err
  }
}

export async function addLanguage(data) {
  try {
    const res = await post(ADD_LANGUAGE_URL, data)
    return res
  } catch (err) {
    return err
  }
}
