import {
  DOWNLOAD_LEADS,
  DOWNLOAD_LEADS_ERROR,
  DOWNLOAD_LEADS_SUCCESS,
  GET_ALL_LEADS,
  GET_ALL_LEADS_ERROR,
  GET_ALL_LEADS_SUCCESS,
} from "./actionTypes"

export const getAllLeads = params => {
  return {
    type: GET_ALL_LEADS,
    payload: params,
  }
}

export const getAllLeadsSuccess = params => {
  return {
    type: GET_ALL_LEADS_SUCCESS,
    payload: params,
  }
}

export const getAllLeadsError = error => {
  return {
    type: GET_ALL_LEADS_ERROR,
    payload: error,
  }
}

export const downloadLeads = params => {
  return {
    type: DOWNLOAD_LEADS,
    payload: params,
  }
}

export const downloadLeadsSuccess = params => {
  return {
    type: DOWNLOAD_LEADS_SUCCESS,
    payload: params,
  }
}

export const downloadLeadsError = error => {
  return {
    type: DOWNLOAD_LEADS_ERROR,
    payload: error,
  }
}
