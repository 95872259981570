import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import calendarSaga from "./calendar/saga"
import ProductSaga from "./product/saga"
import LeadSaga from "./leads/saga"
import FaqSaga from "./faqs/saga"
import LanguageSaga from "./language/saga"
import AboutUsSaga from "./aboutus/saga"
import PrivacySaga from "./privacy/saga"
import PartnerSaga from "./partner/saga"
import ContactSaga from "./contact-us/saga"
import SocialMediaSaga from "./social-media/saga"
import BimaBhaiSaga from "./bima-bhai-videos/saga"
import MediaSaga from "./media/saga"
import TagSaga from "./tags/saga"
import SearchSaga from "./search/saga"
import LeadPagesSaga from "./lead-pages/saga"
import BlogSaga from "./blog/saga.blog"
import bannerSaga from "./banner/saga"
import TestimonialSaga from "./testimonial/saga"
import insurerSaga from "./insurers/saga"

export default function* rootSaga() {
  yield all([
    //public
    PartnerSaga(),
    ContactSaga(),
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    ProductSaga(),
    LeadSaga(),
    FaqSaga(),
    LanguageSaga(),
    AboutUsSaga(),
    PrivacySaga(),
    BimaBhaiSaga(),
    SocialMediaSaga(),
    MediaSaga(),
    TagSaga(),
    SearchSaga(),
    LeadPagesSaga(),
    BlogSaga(),
    TestimonialSaga(),
    fork(calendarSaga),
    bannerSaga(),
    fork(calendarSaga),
    insurerSaga(),
  ])
}
