import Accordion from "components/common/modal-accordion/Accordion"
import TabPill from "components/common/tab-pill"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Modal,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { deleteFaq } from "store/actions"
import styles from "./faq.module.css"
import { IoChevronDown } from "react-icons/io5"
const FaqSettingModal = props => {
  const {
    modalHeaderText,
    modalShow,
    setModalShow,
    currentSelectedParentFAQ,
    updateChildFaq,
    languages,
    deleteFaq,
    deleteFAQLoader,
    filterFaq,
    isFeatureTab,
  } = props

  const [singlebtn, setSinglebtn] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(0)
  const history = useHistory()
  const remainingLanguages = languages.filter(language => {
    return !currentSelectedParentFAQ?.FaqLanguage.some(
      faq => faq.language_code === language.language_code
    )
  })
  useEffect(() => {
    if (
      !currentSelectedParentFAQ ||
      !currentSelectedParentFAQ.FaqLanguage?.length
    )
      setModalShow(false)
  }, [])
  return (
    <Modal isOpen={modalShow} centered={true} size="xl">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {/* Are you sure you want to Logout? */}
          {modalHeaderText}
        </h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className={`modal-body ${styles.setting_modal_body}`}>
        {currentSelectedParentFAQ ? (
          <>
            <div className="info-container">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-1">
                    <div className="col-3 mb-3">FAQs Category:</div>
                    <div className="col-3">
                      <TabPill
                        classNames="mb-0 w-auto"
                        label={
                          currentSelectedParentFAQ.master_faqs_categories.name
                        }
                        status={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-4 ">
                  {remainingLanguages.length ? (
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                      className=""
                    >
                      <DropdownToggle className="btn btn-dark w-100" caret>
                        Add FAQ <IoChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        {remainingLanguages.map((language, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              history.push(
                                `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.ADD_FAQ}/${currentSelectedParentFAQ?.master_faqs_categories?.id}/${language.language_code}/${currentSelectedParentFAQ?.id}`
                              )
                            }}
                          >
                            {language.name}
                            {` (${language.language_code})`}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="table-container mt-4">
              <div className="table-responsive">
                {currentSelectedParentFAQ?.FaqLanguage?.length ? (
                  currentSelectedParentFAQ.FaqLanguage.map((item, index) => {
                    return (
                      <Accordion
                        key={index}
                        status={item.status}
                        updateChild={updateChildFaq}
                        childId={item.id}
                        active={activeAccordion}
                        setActive={setActiveAccordion}
                        index={index + 1}
                        title={item?.question}
                        description={item?.answer}
                        languageCode={item?.language_code}
                        deleteAction={deleteFaq}
                        deleteLoader={deleteFAQLoader}
                        filterList={filterFaq}
                        showAdd={isFeatureTab ? false : true}
                        showDelete={isFeatureTab ? false : true}
                        showStatus={isFeatureTab ? false : true}
                        deleteConfirmationText="You are About to Delete this FAQ. Are you sure?"
                        editFormRoute={
                          isFeatureTab
                            ? `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.EDIT_FAQ}/${item.id}?callback=${ROUTES_PATH.FEATURED}`
                            : `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.EDIT_FAQ}/${item.id}`
                        }
                      />
                    )
                  })
                ) : (
                  <h2 className="text-center mt-4">
                    No data available into the FAQ list
                  </h2>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}
const mapStatetoProps = state => {
  const { Language } = state
  const { Faq } = state
  const { languages } = Language
  const { deleteFAQLoader } = Faq

  return { languages, deleteFAQLoader }
}

export default connect(mapStatetoProps, {
  deleteFaq,
})(FaqSettingModal)
