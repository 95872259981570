import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Table } from "reactstrap"
import SkeletonLoader from "./SkeletonLoader"

// TODO: ADD table class props also later
const CreateTable = ({ thead, leadsLoading, leads }) => {
  if (thead && !thead.length) {
    return <h1>Comming Soon</h1>
  }
  return (
    // TODO: Add custom style for table, check proptypes to check inside header or add js doc later to define what all config needed to create table and add table table type later
    <>
      <div className="table-responsive">
        <Table className="table-striped mb-0">
          <thead className="bg-dark text-white sticky">
            <tr>
              {thead.length
                ? thead.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <th className={item?.customClassNames || ""}>
                          {item.value}
                        </th>
                      </React.Fragment>
                    )
                  })
                : null}
            </tr>
          </thead>
          {leads && leads.length ? (
            <tbody className="table-striped break-word">
              {leads.map((item, index) => {
                return (
                  <tr key={index} className="font-11">
                    {thead.map((hkey, value) => {
                      if (leadsLoading) {
                        return (
                          <React.Fragment key={value}>
                            <td>
                              <SkeletonLoader />
                            </td>
                          </React.Fragment>
                        )
                      }
                      return (
                        <React.Fragment key={value}>
                          <td>{item[hkey.id] || "-"}</td>
                        </React.Fragment>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={200} className="text-center">
                  <h3>No Leads Found</h3>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
    </>
  )
}

CreateTable.propTypes = {
  headers: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  const { successMessage, errorMessage, products, loading, metadata } =
    state.Product
  return { successMessage, errorMessage, products, loading, metadata }
}

export default connect(mapStatetoProps, {})(CreateTable)
