import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_INSURER,
  DELETE_INSURER,
  GET_INSURER,
  GET_INSURER_LIST,
  UPDATE_INSURER,
} from "./actionTypes"
import {
  addInsurer,
  deleteInsurer,
  getInsurer,
  getInsurerList,
  updateInsurer,
} from "./httpCalls.insurers"
import { getInsurerListSuccess, setInsurerLoading } from "./actions"
import { toast } from "react-toastify"

function* GetInsurerList(action) {
  yield put(setInsurerLoading(true))
  try {
    const { payload } = action
    const response = yield call(getInsurerList, payload)
    if (response?.success) {
      yield put(getInsurerListSuccess(response.data, response.metadata))
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setInsurerLoading(false))
}
function* AddInsurer(action) {
  yield put(setInsurerLoading(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(addInsurer, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "insurer-added" })
      cb && cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setInsurerLoading(false))
}

function* GetInsurer(action) {
  yield put(setInsurerLoading(true))
  try {
    const { id, cb } = action.payload
    const response = yield call(getInsurer, id)
    if (response?.success) {
      cb && cb(response.data)
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setInsurerLoading(false))
}

function* UpdateInsurer(action) {
  yield put(setInsurerLoading(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(updateInsurer, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "insurer-update" })
      cb && cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setInsurerLoading(false))
}

function* DeleteInsurer(action) {
  yield put(setInsurerLoading(true))
  try {
    const { id, cb } = action.payload
    const response = yield call(deleteInsurer, id)
    if (response?.success) {
      toast.success(response?.message, { toastId: "banner-delete" })
      cb && cb(id)
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setInsurerLoading(false))
}

function* insurerSaga() {
  yield takeEvery(GET_INSURER_LIST, GetInsurerList)
  yield takeEvery(ADD_INSURER, AddInsurer)
  yield takeEvery(GET_INSURER, GetInsurer)
  yield takeEvery(UPDATE_INSURER, UpdateInsurer)
  yield takeEvery(DELETE_INSURER, DeleteInsurer)
}

export default insurerSaga
