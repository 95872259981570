import { call, put, takeEvery } from "redux-saga/effects"
import { callAddTag } from "./httpCalls.tags"
import { addTagError, addTagSuccess } from "./actions"
import { ADD_TAG } from "./actionTypes"
import { toast } from "react-toastify"

function* addTag(action) {
  try {
    const { tag, cb } = action.payload
    const response = yield call(callAddTag, tag)
    if (response?.success) {
      toast.success(response?.message)
      yield put(addTagSuccess(response?.data))
      cb(response?.data)
    } else {
      yield put(addTagError())
    }
  } catch (error) {
    yield put(addTagError())
  }
}

function* TagSaga() {
  yield takeEvery(ADD_TAG, addTag)
}

export default TagSaga
