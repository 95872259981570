import { SEARCH, SEARCH_ERROR, SEARCH_SUCCESS } from "./actionTypes"

const initialState = {
  search: {},
  loading: false,
}

const Search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      state = {
        ...state,
        loading: true,
      }
      break
    case SEARCH_SUCCESS:
      state = {
        ...state,
        search: action.payload?.data,
        loading: false,
      }
      break
    case SEARCH_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Search
