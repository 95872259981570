import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import * as Yup from "yup"
import {
  setBreadcrumbItems,
  setCurrentLanguageCode,
  addTestimonial,
  getTestimonial,
  updateTestimonial,
} from "store/actions"
import { useHistory, useParams } from "react-router-dom"
import TestimonialForm from "./TestimonialForm"

const AddTestimonialBreadcrumbItems = [
  { title: BREADCRUM.ADD_TESTIMONIAL, link: "/" },
]

const EditTestimonialBreadcrumbItems = [
  { title: BREADCRUM.UPDATE_TESTIMONIAL, link: "/" },
]

const ratingOptions = [
  { label: "1 Star", value: 1 },
  { label: "2 Star", value: 2 },
  { label: "3 Star", value: 3 },
  { label: "4 Star", value: 4 },
  { label: "5 Star", value: 5 },
]

const initalTestimonialForm = {
  name: "",
  feedback: "",
  ratings: ratingOptions[0],
}

const FaqValidationSchema = Yup.object().shape({
  name: Yup.string().required("Username required !"),
  feedback: Yup.string()
    .test("maximumWords", "Maximun 30 words only !", value => {
      if (value) {
        let wordsArray = value.split(" ")
        if (wordsArray.length <= 30) return true
      }
    })
    .required("Feedback required !"),
  ratings: Yup.object().required("Ratings required !"),
})

const TestimonialAdd = props => {
  const {
    setBreadcrumbItems,
    currentLanguageCode,
    addTestimonial,
    testimonialLoader,
    setCurrentLanguageCode,
    getTestimonial,
    updateTestimonial,
  } = props

  const [fromData, setFormData] = useState(initalTestimonialForm)
  const [submitbtn, setSubmitBtn] = useState("Add Testimonial")
  const [isFeatured, setIsFeatured] = useState(false)
  const history = useHistory()

  const { parentId, childLanguage, childId } = useParams()

  const findNewRatings = (ratingsValue = 0) => {
    let newFilteredRating
    if (ratingsValue) {
      newFilteredRating = ratingOptions.filter(
        rating => rating.value === ratingsValue
      )
    }
    return newFilteredRating[0] || ratingOptions[0]
  }

  const updateFormData = (status, res) => {
    if (!status) {
      history.push(`/${ROUTES_PATH.TESTIMONIAL}`)
    } else {
      setIsFeatured(res?.testimonials?.isfeatured || false)
      const { feedback, name, testimonials, ratings } = res
      if (ratings) {
        setFormData({ ...fromData, ratings: findNewRatings(ratings) })
      } else if ((feedback, name, testimonials)) {
        setSubmitBtn("Update Testimonial")
        setFormData({
          name,
          feedback,
          ratings: findNewRatings(testimonials.ratings),
        })
      }
    }
  }

  useEffect(() => {
    setBreadcrumbItems(
      {
        title: BREADCRUM.TESTIMONIAL,
        pageTitleLink: `/${ROUTES_PATH.TESTIMONIAL}`,
      },
      AddTestimonialBreadcrumbItems
    )
    if (childId && parentId) {
      setBreadcrumbItems(
        {
          title: BREADCRUM.TESTIMONIAL,
          pageTitleLink: `/${ROUTES_PATH.TESTIMONIAL}`,
        },
        EditTestimonialBreadcrumbItems
      )
      getTestimonial(childId, updateFormData)
      setCurrentLanguageCode(childLanguage)
    } else if (parentId) {
      getTestimonial(`parent/${parentId}`, updateFormData)
      setCurrentLanguageCode(childLanguage)
    }
  }, [])

  const onSubmit = value => {
    if (parentId && childId) {
      updateTestimonial(
        {
          ...value,
          id: childId,
          ratings: value.ratings.value,
          language_code: currentLanguageCode,
        },
        () => {
          isFeatured
            ? history.push(
                `/${ROUTES_PATH.TESTIMONIAL}?tab=${ROUTES_PATH.FEATURED}`
              )
            : history.push(`/${ROUTES_PATH.TESTIMONIAL}`)
        }
      )
    } else if (parentId) {
      addTestimonial(
        {
          ...value,
          id: parentId,
          ratings: value.ratings.value,
          language_code: currentLanguageCode,
        },
        () => {
          history.push(`/${ROUTES_PATH.TESTIMONIAL}`)
        }
      )
    } else {
      addTestimonial(
        {
          ...value,
          ratings: value.ratings.value,
          language_code: currentLanguageCode,
        },
        () => {
          history.push(`/${ROUTES_PATH.TESTIMONIAL}`)
        }
      )
    }
  }

  return (
    <TestimonialForm
      currentLanguageCode={currentLanguageCode}
      fromData={fromData}
      FaqValidationSchema={FaqValidationSchema}
      submitbtn={submitbtn}
      parentId={parentId}
      childId={childId}
      onSubmit={onSubmit}
      testimonialLoader={testimonialLoader}
      ratingOptions={ratingOptions}
    />
  )
}

const mapStatetoProps = state => {
  const { Testimonial, Language } = state
  const { currentLanguageCode } = Language
  const { loading: testimonialLoader } = Testimonial
  return {
    currentLanguageCode,
    testimonialLoader,
  }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  setCurrentLanguageCode,
  addTestimonial,
  getTestimonial,
  updateTestimonial,
})(TestimonialAdd)
