import {
  GET_PARENT_BLOG_LIST_SUCCESS,
  LOADER_STATE,
  SET_BLOG_LOADER,
} from "./actionTypes.blog"

const initialState = {
  parentBlogList: [],
  parentBlogMetadata: {},
  [LOADER_STATE.PARENT_LOADER]: false,
  [LOADER_STATE.BLOG_FORM_SUBMIT_LOADER]: false,
  [LOADER_STATE.BLOG_DELETE_LOADER]: false,
  [LOADER_STATE.BLOG_UPDATE_LOADER]: false,
}

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARENT_BLOG_LIST_SUCCESS:
      state = {
        ...state,
        parentBlogList: action.payload.data,
        parentBlogMetadata: action.payload.metaData,
      }
      break

    case SET_BLOG_LOADER:
      state = {
        ...state,
        [action.payload.key]: action.payload.value,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default blogReducer
