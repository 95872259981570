import {
  ADD_INSURER,
  DELETE_INSURER,
  GET_INSURER,
  GET_INSURER_LIST,
  GET_INSURER_LIST_SUCCESS,
  SET_INSURER_LOADING,
  UPDATE_INSURER,
} from "./actionTypes"

export const getInsurerList = params => ({
  type: GET_INSURER_LIST,
  payload: params,
})

export const getInsurerListSuccess = (data, metadata) => ({
  type: GET_INSURER_LIST_SUCCESS,
  payload: { data, metadata },
})

export const setInsurerLoading = payload => ({
  type: SET_INSURER_LOADING,
  payload,
})

export const addInsurer = (data, cb) => ({
  type: ADD_INSURER,
  payload: { data, cb },
})

export const getInsurer = (id, cb) => ({
  type: GET_INSURER,
  payload: { id, cb },
})

export const updateInsurer = (data, cb) => ({
  type: UPDATE_INSURER,
  payload: { data, cb },
})

export const deleteInsurer = (id, cb) => ({
  type: DELETE_INSURER,
  payload: { id, cb },
})
