export const GET_ALL_BIMABHAI = "GET_ALL_BIMABHAI"
export const GET_ALL_BIMABHAI_SUCCESS = "GET_ALL_BIMABHAI_SUCCESS"

export const GET_BIMABHAI = "GET_BIMABHAI"
export const GET_BIMABHAI_SUCCESS = "GET_BIMABHAI_SUCCESS"

export const ADD_BIMABHAI_VIDEO = "ADD_BIMABHAI_VIDEO"

export const UPDATE_BIMABHAI_VIDEO = "UPDATE_BIMABHAI_VIDEO"

export const DELETE_BIMABHAI_VIDEO = "DELETE_BIMABHAI_VIDEO"

export const FILTER_BIMABHAI_VIDEO = "FILTER_BIMABHAI_VIDEO"

export const SET_BIMABHAI_LOADING = "SET_BIMABHAI_LOADING"

export const GET_PARENT_BIMABHAI_VIDEO_DATA = "GET_PARENT_BIMABHAI_VIDEO_DATA"

export const CHECK_BIMABHAI_VIDEO_ASSOCIATIONS =
  "CHECK_BIMABHAI_VIDEO_ASSOCIATIONS"

export const loadingStateBimabhai = {
  getLoading: "loading",
  deleteLoading: "deleteLoading",
}
