import { ADD_TAG, ADD_TAG_ERROR, ADD_TAG_SUCCESS } from "./actionTypes"

export const addTag = (tag, cb) => {
  return {
    type: ADD_TAG,
    payload: { tag, cb },
  }
}

export const addTagSuccess = response => {
  return {
    type: ADD_TAG_SUCCESS,
    payload: response,
  }
}

export const addTagError = () => {
  return {
    type: ADD_TAG_ERROR,
  }
}
