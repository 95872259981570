import {
  ADD_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA,
  GET_ALL_SOCIAL_MEDIAS,
  GET_ALL_SOCIAL_MEDIAS_SUCCESS,
  GET_SOCIAL_MEDIA,
  SET_LOADING_SOCIAL_MEDIAS,
  UPDATE_SOCIAL_MEDIA,
} from "./actionTypes"

export const getAllSocialMedia = params => ({
  type: GET_ALL_SOCIAL_MEDIAS,
  payload: params,
})

export const getAllSocialMediaSuccess = data => ({
  type: GET_ALL_SOCIAL_MEDIAS_SUCCESS,
  payload: data,
})

export const getSocialMedia = (socialMediaId, cb) => ({
  type: GET_SOCIAL_MEDIA,
  payload: { socialMediaId, cb },
})

export const addSocialMedia = (data, cb) => ({
  type: ADD_SOCIAL_MEDIA,
  payload: { data, cb },
})

export const updateSocialMedia = (socialMediaId, data, cb) => ({
  type: UPDATE_SOCIAL_MEDIA,
  payload: { socialMediaId, data, cb },
})

export const deleteSocialMedia = (socialMediaIdList, cb) => ({
  type: DELETE_SOCIAL_MEDIA,
  payload: { socialMediaIdList, cb },
})

export const setLoading = bool => ({
  type: SET_LOADING_SOCIAL_MEDIAS,
  payload: bool,
})
