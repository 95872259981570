import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_FEATURED_TESTIMONIAL,
  ADD_TESTIMONIAL,
  DELETE_TESTIMONIAL,
  GET_ALL_TESTIMONIAL,
  GET_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
} from "./actionTypes"
import {
  getAllFilteredTestimonialSuccess,
  getAllTestimonialSuccess,
  setAddFeaturedLoading,
  setdeleteLoading,
  setTestimonialLoading,
} from "./actions"
import {
  callAddTestimonialApi,
  callDeleteTestimonial,
  callGetAllTestimonials,
  callGetTestimonial,
  callUpdateTestimonial,
  callAddFeatureTestimonialAPI,
} from "./httpCalls.testimonial"

function* GetAllTestimonials(action) {
  action.payload?.isFiltered
    ? yield put(setTestimonialLoading("FEATURED_TESTIMONIAL_LIST_LOADER", true))
    : yield put(setTestimonialLoading("TESTIMONIAL_LIST_LOADER", true))
  const response = yield call(callGetAllTestimonials, action.payload?.params)
  if (response?.success) {
    action.payload?.isFiltered
      ? yield put(getAllFilteredTestimonialSuccess(response))
      : yield put(getAllTestimonialSuccess(response))
  }
  action.payload?.isFiltered
    ? yield put(
        setTestimonialLoading("FEATURED_TESTIMONIAL_LIST_LOADER", false)
      )
    : yield put(setTestimonialLoading("TESTIMONIAL_LIST_LOADER", false))
}

function* GetTestimonial(action) {
  yield put(setTestimonialLoading(true))
  const response = yield call(callGetTestimonial, action.payload.testimonialId)
  if (response?.success) {
    action.payload.cb(true, response.data)
  } else {
    action.payload.cb(false, response)
  }
  yield put(setTestimonialLoading(false))
}

function* AddTestimonial(action) {
  yield put(setTestimonialLoading(true))
  const response = yield call(callAddTestimonialApi, action.payload.data)
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setTestimonialLoading(false))
}

function* UpdateTestimonial(action) {
  yield put(setTestimonialLoading(true))
  const response = yield call(
    callUpdateTestimonial,
    action.payload.testimonialData
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setTestimonialLoading(false))
}

function* DeleteTestimonial(action) {
  yield put(setdeleteLoading(true))
  const response = yield call(
    callDeleteTestimonial,
    action.payload.paramsId,
    action.payload.isChild
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setdeleteLoading(false))
}

function* AddFeatureTestimonial(action) {
  yield put(setAddFeaturedLoading(true))
  const response = yield call(
    callAddFeatureTestimonialAPI,
    action.payload.requestBody
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setAddFeaturedLoading(false))
}

function* testimonialSaga() {
  yield takeEvery(ADD_TESTIMONIAL, AddTestimonial)
  yield takeEvery(GET_ALL_TESTIMONIAL, GetAllTestimonials)
  yield takeEvery(GET_TESTIMONIAL, GetTestimonial)
  yield takeEvery(UPDATE_TESTIMONIAL, UpdateTestimonial)
  yield takeEvery(DELETE_TESTIMONIAL, DeleteTestimonial)
  yield takeEvery(ADD_FEATURED_TESTIMONIAL, AddFeatureTestimonial)
}

export default testimonialSaga
