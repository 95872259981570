import React, { useEffect, useState } from "react"
import CustomSelect from "components/common/CustomSelect"
import { connect } from "react-redux"
import { setCurrentLanguageCode } from "store/actions"

const defaultOption = [
  {
    label: "English (en)",
    value: "en",
  },
]

const LanguageDropDown = props => {
  const { languages, currentLanguageCode, setCurrentLanguageCode, disabled } =
    props
  const [languagesOption, setLanguagesOption] = useState(defaultOption)
  const [currentLanguage, setCurrentLanguage] = useState(defaultOption[0])
  useEffect(() => {
    if (languages?.length) {
      setLanguagesOption(
        languages.map(lang => ({
          label: `${lang.name} (${lang.language_code})`,
          value: lang.language_code,
        }))
      )
    }
  }, [languages])

  useEffect(() => {
    if (currentLanguageCode) {
      setCurrentLanguage(
        languagesOption.find(lang => lang.value === currentLanguageCode) ||
          defaultOption[0]
      )
    }
  }, [currentLanguageCode, languagesOption])

  const handleLanguageChange = selected => {
    setCurrentLanguageCode(selected.value)
  }

  return (
    <CustomSelect
      name="language"
      id="language"
      value={currentLanguage}
      options={languagesOption || defaultOption}
      onChange={handleLanguageChange}
      placeholder="Language..."
      disabled={disabled}
      defaultSelectValue={defaultOption[0]}
    />
  )
}

const mapStatetoProps = state => {
  const { Language } = state
  const { languages, currentLanguageCode } = Language
  return { languages, currentLanguageCode }
}

const mapDispatchToProps = { setCurrentLanguageCode }

export default connect(mapStatetoProps, mapDispatchToProps)(LanguageDropDown)
