import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import * as Yup from "yup"
import {
  addFAQ,
  getChildFaq,
  setBreadcrumbItems,
  setCurrentLanguageCode,
  updateChildFaq,
  getParentFaq,
} from "store/actions"
import FaqForm from "./FaqForm"
import { FAQ_CATEGORY } from "helpers/constants/enum.constants.helper"

const AddFaqBreadcrumbItems = [{ title: BREADCRUM.ADD_FAQS, link: "/" }]

const EditFaqBreadcrumbItems = [{ title: BREADCRUM.EDIT_FAQ, link: "/" }]

const initalFaqForm = {
  question: "",
  faqCategoryName: {
    label: FAQ_CATEGORY[0].name,
    value: FAQ_CATEGORY[0].id,
  },
}

const FaqValidationSchema = Yup.object().shape({
  question: Yup.string().required("Question required !"),
  faqCategoryName: Yup.object().required("Category required !!!"),
})

// TODO: Bimabhai video has to be add
const FaqAdd = props => {
  const {
    setBreadcrumbItems,
    currentLanguageCode,
    history,
    addFAQ,
    addChildFAQLoader,
    getChildFaq,
    setCurrentLanguageCode,
    updateChildFaq,
    getParentFaq,
  } = props

  const [formData, setFormData] = useState(initalFaqForm)
  const [editorText, setEditorText] = useState("")
  const [requiredEditor, setRequiredEditor] = useState(false)
  const [submitbtn, setSubmitBtn] = useState("Add FAQ")
  const [selectedVideos, setSelectedVideos] = useState([])

  const { childFaqId, parentFaqId, faqLanguage, faqCategoryId } = useParams()

  useEffect(() => {
    if (childFaqId) {
      setBreadcrumbItems(
        {
          title: BREADCRUM.FAQS,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`,
        },
        EditFaqBreadcrumbItems
      )
      setSubmitBtn("Edit FAQ")
      getChildFaq(childFaqId, res => {
        setSelectedVideos([
          {
            bhemaBhaiVideoId: res?.bima_bhai_videos_language_id || null,
            thumbnail:
              res?.bima_bhai_videos_by_language?.master_media_thumbnail_id
                ?.location || null,
            video_title: res?.bima_bhai_videos_by_language?.title || "Unknown",
          },
        ])
        setFormData({
          ...formData,
          question: res.question,
          faqCategoryName: {
            label: res?.faqs?.master_faqs_categories?.name,
            value: res?.faqs?.master_faqs_categories?.id,
          },
        })
        setCurrentLanguageCode(res.language_code)
        setEditorText(res.answer)
      })
    } else {
      setBreadcrumbItems(
        {
          title: BREADCRUM.FAQS,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`,
        },
        AddFaqBreadcrumbItems
      )
    }
  }, [])

  useEffect(() => {
    setRequiredEditor(false)
  }, [editorText])

  const onSubmit = value => {
    const { question, faqCategoryName } = value
    if (editorText) {
      if (childFaqId) {
        updateChildFaq(
          {
            faqLangId: childFaqId,
            changes: {
              question,
              answer: editorText,
              bimaBhaiVideoByLanguageId:
                selectedVideos[0]?.bhemaBhaiVideoId || null,
              status: true,
            },
          },
          () => {
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}${
                history.location.search.includes(ROUTES_PATH.FEATURED)
                  ? `?tab=${ROUTES_PATH.FEATURED}`
                  : history.location.search
              }`
            )
          }
        )
      } else {
        const data = {
          question,
          answer: editorText,
          faqCategoryName: faqCategoryName.value,
          bimaBhaiVideoByLanguageId:
            selectedVideos[0]?.bhemaBhaiVideoId || null,
          languageCode: currentLanguageCode,
        }
        if (parentFaqId) {
          data.parentFaqId = parentFaqId
          addFAQ(data, () => {
            history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`)
          })
        } else {
          addFAQ(data, () => {
            history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`)
          })
        }
      }
    } else {
      setRequiredEditor(true)
    }
  }

  const checkParentFaqExist = res => {
    if (!res?.code) {
      history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`)
    }
  }
  useEffect(() => {
    if (parentFaqId) {
      setCurrentLanguageCode(faqLanguage)
      const selectedFaqCategory = FAQ_CATEGORY.filter(
        category => category.id === faqCategoryId
      )
      if (selectedFaqCategory.length) {
        setFormData({
          ...initalFaqForm,
          faqCategoryName: {
            label: selectedFaqCategory[0].name,
            value: selectedFaqCategory[0].id,
          },
        })
      } else {
        history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`)
      }
      getParentFaq(parentFaqId, checkParentFaqExist)
    }
  }, [])
  return (
    <FaqForm
      editorText={editorText}
      setSelectedVideos={setSelectedVideos}
      selectedVideos={selectedVideos}
      currentLanguageCode={currentLanguageCode}
      faqCategoryList={FAQ_CATEGORY}
      setEditorText={setEditorText}
      requiredEditor={requiredEditor}
      formData={formData}
      FaqValidationSchema={FaqValidationSchema}
      submitbtn={submitbtn}
      submitLoader={addChildFAQLoader}
      childFaqId={childFaqId}
      onSubmit={onSubmit}
      parentFaqId={parentFaqId}
    />
  )
}

const mapStatetoProps = state => {
  const { Faq, Language } = state
  const { currentLanguageCode } = Language
  const { addChildFAQLoader } = Faq
  return {
    currentLanguageCode,
    addChildFAQLoader,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    setBreadcrumbItems,
    addFAQ,
    getChildFaq,
    setCurrentLanguageCode,
    updateChildFaq,
    getParentFaq,
  })(FaqAdd)
)
