/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_BIMABHAI_VIDEO,
  CHECK_BIMABHAI_VIDEO_ASSOCIATIONS,
  DELETE_BIMABHAI_VIDEO,
  FILTER_BIMABHAI_VIDEO,
  GET_ALL_BIMABHAI,
  GET_BIMABHAI,
  GET_PARENT_BIMABHAI_VIDEO_DATA,
  loadingStateBimabhai,
  UPDATE_BIMABHAI_VIDEO,
} from "./actionTypes"
import {
  getAllBimabhaiSuccess,
  getBimabhaiSuccess,
  setBimabhaiLoading,
} from "./actions"
import {
  callAddBimabhaiVideo,
  callDeleteBimabhaiVideo,
  callFilterBimaBhaiVideoApi,
  callGetAllBimabhaiApi,
  callGetBimabhaiApi,
  callUpdateBimabhaiVideo,
  checkBimabhaiVideoAssociations,
  getParentBimaBhaiVideoDataApi,
} from "./httpCalls.bimabhaivideos"
import { SUCCESS_TOAST } from "helpers/constants/constants.helper"

function* getAllBimabhaiVideo(action) {
  try {
    const response = yield call(callGetAllBimabhaiApi, action.payload)
    if (response?.success) yield put(getAllBimabhaiSuccess(response))
  } catch (error) {
    console.log(error)
  }
  yield put(setBimabhaiLoading(false, loadingStateBimabhai.getLoading))
}

function* getBimabhaiVideo(action) {
  try {
    const response = yield call(callGetBimabhaiApi, action.payload.params)
    if (response?.success) {
      yield put(getBimabhaiSuccess(response))
    }
    action.payload.cb(response?.data)
  } catch (error) {
    console.log(error)
  }
  yield put(setBimabhaiLoading(false, loadingStateBimabhai.getLoading))
}

function* addBimabhaiVideo(action) {
  yield put(setBimabhaiLoading(true, loadingStateBimabhai.getLoading))
  try {
    const { data, cb } = action.payload
    const response = yield call(callAddBimabhaiVideo, data)
    if (response?.success) {
      toast.success(response?.message, {
        toastId: SUCCESS_TOAST.ADD_BIMA_BHAI_VIDEOS,
      })
      cb()
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setBimabhaiLoading(false, loadingStateBimabhai.getLoading))
}

function* updateBimabhaiVideo(action) {
  // yield put(setBimabhaiLoading(true, loadingStateBimabhai.getLoading))
  try {
    const { id, data: changes, cb, params } = action.payload
    const response = yield call(callUpdateBimabhaiVideo, { id, changes })
    if (response?.success) {
      toast.success(response?.message)
      try {
        const response = yield call(callGetAllBimabhaiApi, params)
        if (response?.success) yield put(getAllBimabhaiSuccess(response))
      } catch (error) {
        console.log(error)
      }
      cb()
    }
  } catch (error) {
    console.log(error)
  }
  // yield put(setBimabhaiLoading(false, loadingStateBimabhai.getLoading))
}

function* deleteBimabhaiVideo(action) {
  yield put(setBimabhaiLoading(true, loadingStateBimabhai.deleteLoading))
  try {
    const { id, cb, isChild, params } = action.payload
    const response = yield call(callDeleteBimabhaiVideo, id, isChild)
    if (response?.success) {
      toast.success(response?.message, {
        toastId: SUCCESS_TOAST.DELETE_BIMA_BHAI_VIDEOS,
      })
      if (isChild) {
        try {
          const response = yield call(callGetAllBimabhaiApi, params)
          if (response?.success) yield put(getAllBimabhaiSuccess(response))
        } catch (error) {
          console.log(error)
        }
      }
      cb()
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setBimabhaiLoading(false, loadingStateBimabhai.deleteLoading))
}

function* filterBimabhaiVideo(action) {
  yield put(setBimabhaiLoading(true, loadingStateBimabhai.getLoading))
  try {
    const response = yield call(
      callFilterBimaBhaiVideoApi,
      action.payload.params
    )
    if (response?.success) action.payload.cb(response?.data || [], response?.metadata || {})
  } catch (error) {
    console.log(error)
  }
  yield put(setBimabhaiLoading(false, loadingStateBimabhai.getLoading))
}

function* GetParentBimaBhaiVideoData(action) {
  const { parentBVId, cb } = action.payload
  try {
    const response = yield call(getParentBimaBhaiVideoDataApi, parentBVId)
    cb(response?.data[0])
  } catch (error) {
    console.log(error)
    cb(null)
  }
}

function* CheckBimabhaiVideoAssociations(action) {
  const { childVideoId, cb } = action.payload
  try {
    const response = yield call(checkBimabhaiVideoAssociations, childVideoId)
    cb(response.data)
  } catch (error) {
    console.log(error)
  }
}

function* BimabhaiSaga() {
  yield takeEvery(GET_ALL_BIMABHAI, getAllBimabhaiVideo)
  yield takeEvery(GET_BIMABHAI, getBimabhaiVideo)
  yield takeEvery(ADD_BIMABHAI_VIDEO, addBimabhaiVideo)
  yield takeEvery(UPDATE_BIMABHAI_VIDEO, updateBimabhaiVideo)
  yield takeEvery(DELETE_BIMABHAI_VIDEO, deleteBimabhaiVideo)
  yield takeEvery(FILTER_BIMABHAI_VIDEO, filterBimabhaiVideo)
  yield takeEvery(GET_PARENT_BIMABHAI_VIDEO_DATA, GetParentBimaBhaiVideoData)
  yield takeEvery(
    CHECK_BIMABHAI_VIDEO_ASSOCIATIONS,
    CheckBimabhaiVideoAssociations
  )
}

export default BimabhaiSaga
