import { Field, Form, Formik } from "formik"
import React from "react"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import ReactSelect from "react-select"
import FormButton from "components/form-components/FormButton"
import { TESTIMONIAL } from "helpers/constants/labels.constants"

const TestimonialForm = ({
  fromData,
  FaqValidationSchema,
  onSubmit,
  submitbtn,
  testimonialLoader,
  parentId,
  childId,
  ratingOptions,
}) => {
  const { USER_NAME, FEEDBACK, RATING_STAR, SELECT_LANGUAGE } =
    TESTIMONIAL.TESTIMONIAL_FORM
  return (
    <div>
      <Formik
        initialValues={fromData}
        validationSchema={FaqValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          errors,
          values,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{USER_NAME} </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <div className="position-absolute text-danger">
                          {errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{FEEDBACK}</Label>
                      <Field
                        as="textarea"
                        rows="4"
                        className="form-control"
                        name="feedback"
                      />
                      {errors.feedback && touched.feedback ? (
                        <div className="position-absolute text-danger">
                          {errors.feedback}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{SELECT_LANGUAGE}</Label>
                        <LanguageDropDown disabled={parentId ? true : false} />
                        {errors.languageCode && touched.languageCode ? (
                          <div className="position-absolute text-danger">
                            {errors.languageCode}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{RATING_STAR}</Label>
                        <ReactSelect
                          value={values.ratings}
                          onChange={selected => {
                            setFieldTouched("ratings", true)
                            setFieldValue("ratings", selected)
                          }}
                          name="ratings"
                          placeholder="Select Star"
                          options={ratingOptions}
                          classNamePrefix="select2-selection"
                          isDisabled={parentId || childId}
                        />
                        {errors.ratings && touched.ratings ? (
                          <div className="position-absolute text-danger">
                            {errors.ratings}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={testimonialLoader}
                  type="submit"
                  btnText={submitbtn}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TestimonialForm
