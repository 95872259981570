import TabPill from "components/common/tab-pill"
import CustomTableLoader from "components/loader/CustomTableLoader"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { getRemainingLanguages } from "helpers/utils.helper"
import React from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Table, UncontrolledTooltip } from "reactstrap"
import { MdSettings, MdStarRate, MdStarBorder, MdDelete } from "react-icons/md"
import styles from "./faq.module.css"

const FaqTableHead = [
  { value: "Question", id: "id", classNames: "text-start text-truncate" },
  { value: "Category", id: "Category", style: { width: "218px" } },
  { value: "Languages", id: "Languages", style: { width: "265px" } },
]

const ParentFAQListing = props => {
  const {
    getParentLoader,
    parentFAQList,
    setShowFeatureModal,
    setShowDeleteModal,
    setShowSettingModal,
    setcurrentSelectedParentFAQ,
    isFeatureTab,
    languages,
  } = props
  const { vertical_align_middle } = styles
  const onFeaturedIconClicked = item => {
    setcurrentSelectedParentFAQ(item)
    setShowFeatureModal(true)
  }
  return (
    <Table className="table-striped mb-0">
      <thead className="bg-dark text-white sticky">
        <tr className="text-center">
          {FaqTableHead.map((item, index) => {
            return (
              <th
                key={index}
                className={item.classNames || null}
                style={item.style}
              >
                {item.value}
              </th>
            )
          })}
          <th className="action-tab">Action</th>
        </tr>
      </thead>
      <tbody className="table-striped ">
        {getParentLoader ? (
          <CustomTableLoader col={4} />
        ) : parentFAQList.length ? (
          parentFAQList.map((item, index) => {
            return (
              <tr className={vertical_align_middle} key={item.id || index}>
                <td className="text-start">{item?.question}</td>
                <td>
                  <TabPill
                    classNames="mb-0"
                    label={item?.master_faqs_categories?.name}
                    status={item?.status}
                  />
                </td>
                <td>
                  <LanguageTabs
                    languageItemArray={item?.FaqLanguage || []}
                    languages={languages}
                    parentFaqId={item.id}
                    faqCategoryId={item.master_faqs_categories.id}
                  />
                </td>
                <td className="icon-container">
                  <div className=" justify-content-center">
                    {isFeatureTab ? (
                      <>
                        <MdStarRate
                          className="text-success icons"
                          id={`featureIcon${index}${isFeatureTab}`}
                          onClick={() => onFeaturedIconClicked(item)}
                        />
                        <UncontrolledTooltip
                          target={`featureIcon${index}${isFeatureTab}`}
                        >
                          Remove from Featured
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <MdStarBorder
                          id={`featureIcon${index}`}
                          className="text-success icons"
                          onClick={() => onFeaturedIconClicked(item)}
                        />
                        <UncontrolledTooltip target={`featureIcon${index}`}>
                          Move to Featured
                        </UncontrolledTooltip>
                      </>
                    )}

                    <MdSettings
                      id={`settingIcon${index}`}
                      className="text-success icons"
                      onClick={() => {
                        setcurrentSelectedParentFAQ(item)
                        setShowSettingModal(true)
                      }}
                    />
                    <UncontrolledTooltip target={`settingIcon${index}`}>
                      Settings
                    </UncontrolledTooltip>
                    {isFeatureTab ? null : (
                      <>
                        <MdDelete
                          id={`deleteIcon${index}`}
                          className="icons text-danger"
                          onClick={() => {
                            setcurrentSelectedParentFAQ(item)
                            setShowDeleteModal(true)
                          }}
                        />
                        <UncontrolledTooltip target={`deleteIcon${index}`}>
                          Delete
                        </UncontrolledTooltip>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              <h3>No FAQ Found</h3>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const LanguageTabs = ({
  languageItemArray,
  languages,
  parentFaqId,
  faqCategoryId,
}) => {
  const remainingLanguages = getRemainingLanguages(languages, languageItemArray)
  const history = useHistory()
  return (
    <div className="w-100 d-flex justify-content-center">
      {languageItemArray.map((languageItemObj, i) => (
        <TabPill
          key={i}
          status={languageItemObj.status}
          label={languageItemObj.language_code}
          action={() => {}}
          classNames="mb-0 cursor-default lang-tab"
        />
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code}+`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.ADD_FAQ}/${faqCategoryId}/${language.language_code}/${parentFaqId}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
    </div>
  )
}
const mapStatetoProps = state => {
  const { languages } = state.Language
  return { languages }
}
export default connect(mapStatetoProps, {})(ParentFAQListing)
