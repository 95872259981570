import { ADD_TAG, ADD_TAG_ERROR, ADD_TAG_SUCCESS } from "./actionTypes"

const initialState = {
  tag: {},
  loading: false,
}

const Tag = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAG:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TAG_SUCCESS:
      state = {
        ...state,
        tag: action.payload?.data,
        loading: false,
      }
      break
    case ADD_TAG_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Tag
