import {
  ADD_FEATURED_TESTIMONIAL,
  ADD_FEATURED_TESTIMONIAL_LOADING,
  ADD_TESTIMONIAL,
  DELETE_TESTIMONIAL,
  GET_ALL_FILTERED_TESTIMONIAL_SUCCESS,
  GET_ALL_TESTIMONIAL,
  GET_ALL_TESTIMONIAL_SUCCESS,
  GET_TESTIMONIAL,
  SET_DELETE_LOADING_TESTIMONIAL,
  SET_LOADING_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
} from "./actionTypes"

export const addTestimonial = (data, cb) => ({
  type: ADD_TESTIMONIAL,
  payload: { data, cb },
})

export const getAllTestimonial = (params, isFiltered = false) => ({
  type: GET_ALL_TESTIMONIAL,
  payload: { params, isFiltered },
})

export const getAllTestimonialSuccess = response => ({
  type: GET_ALL_TESTIMONIAL_SUCCESS,
  payload: response,
})

export const getAllFilteredTestimonialSuccess = response => ({
  type: GET_ALL_FILTERED_TESTIMONIAL_SUCCESS,
  payload: response,
})

export const getTestimonial = (testimonialId, cb) => ({
  type: GET_TESTIMONIAL,
  payload: { testimonialId, cb },
})

export const updateTestimonial = (testimonialData, cb) => ({
  type: UPDATE_TESTIMONIAL,
  payload: { testimonialData, cb },
})

export const addFeaturedTestimonial = (requestBody, cb) => ({
  type: ADD_FEATURED_TESTIMONIAL,
  payload: { requestBody, cb },
})

export const deleteTestimonial = (paramsId, cb, isChild) => {
  return {
    type: DELETE_TESTIMONIAL,
    payload: { paramsId: paramsId, cb, isChild },
  }
}

export const setTestimonialLoading = (key, value) => ({
  type: SET_LOADING_TESTIMONIAL,
  payload: { key, value },
})

export const setAddFeaturedLoading = bool => ({
  type: ADD_FEATURED_TESTIMONIAL_LOADING,
  payload: bool,
})

export const setdeleteLoading = bool => ({
  type: SET_DELETE_LOADING_TESTIMONIAL,
  payload: bool,
})
