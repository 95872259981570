import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import {
  setBreadcrumbItems,
  getBannerList,
  updateBanner,
  deleteBanner,
} from "store/actions"
import classNames from "classnames"
import BannerTab from "./BannerTab"
import { getUserPreference } from "helpers/utils.helper"
import BannerSettingModal from "./BannerSettingModal"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { useHistory } from "react-router-dom"
import { BANNERS_MESSAGES } from "helpers/constants/messages.constants"
const BannerTabs = [
  {
    value: "Featured",
    id: 1,
  },
  {
    value: "Drafts",
    id: 2,
  },
]
const Banner = ({
  setBreadcrumbItems,
  getBannerList,
  bannerList,
  bannerLoading,
  metadata,
  updateBanner,
  deleteBanner,
}) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(
    history.location.search.includes(ROUTES_PATH.FEATURED) ? 1 : 2
  )
  const [currentPage, setCurrentPage] = useState(0)
  const [currentPageOnFeaturedTab, setCurrentPageOnFeaturedTab] = useState(0)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [showFeatureModal, setShowFeatureModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getParentBannerListParams = () => ({
    pageNum: activeTab === 1 ? currentPageOnFeaturedTab : currentPage,
    recordLimit: recordLimit,
    isfeatured: activeTab === 1 ? true : false,
  })
  const [searchText, setSearchText] = useState("")
  const [showSettingModal, setShowSettingModal] = useState(false)
  const [currentSelectedParentBanner, setCurrentSelectedParentBanner] =
    useState(null)
  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.BANNER)
  }, [])
  useEffect(() => {
    let timeout
    if (searchText) {
      const params = getParentBannerListParams()
      timeout = setTimeout(() => {
        getBannerList({
          ...params,
          searchText: searchText,
        })
      }, 500)
    } else {
      getBannerList(getParentBannerListParams())
    }
    if (
      activeTab === 2 &&
      history.location.search.includes(ROUTES_PATH.FEATURED)
    ) {
      history.push(`/${ROUTES_PATH.BANNER}`)
    }
    return () => clearTimeout(timeout)
  }, [
    currentPage,
    activeTab,
    currentPageOnFeaturedTab,
    searchText,
    recordLimit,
  ])
  const filterBanner = deletedBannerID => {
    getBannerList(getParentBannerListParams())
    const filteredBanners =
      currentSelectedParentBanner?.BannerByLanguages.filter(
        banner => banner.id !== deletedBannerID
      )
    setCurrentSelectedParentBanner({
      ...currentSelectedParentBanner,
      BannerByLanguages: filteredBanners,
    })
    if (!filteredBanners.length) {
      setShowSettingModal(false)
    }
  }
  const getBanners = currentPageOfSeletedTab => {
    if (currentPageOfSeletedTab >= 1) {
      activeTab === 1
        ? setCurrentPageOnFeaturedTab(currentPageOnFeaturedTab - 1)
        : setCurrentPage(currentPage - 1)
      getBannerList({
        pageNum:
          activeTab === 1 ? currentPageOnFeaturedTab - 1 : currentPage - 1,
        recordLimit: recordLimit,
        isfeatured: activeTab === 1 ? true : false,
      })
    } else {
      getBannerList(getParentBannerListParams())
    }
  }
  useEffect(() => {
    setSearchText("")
  }, [activeTab])
  return (
    <>
      <Nav tabs>
        {BannerTabs.map((item, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeTab === item.id,
                })}
                onClick={() => {
                  setActiveTab(item.id)
                }}
              >
                {item.value}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted bg-white">
        <TabPane tabId={1}>
          <BannerTab
            bannerList={bannerList}
            isFeaturedTab={true}
            currentPage={currentPage}
            currentPageOnFeaturedTab={currentPageOnFeaturedTab}
            setCurrentPage={setCurrentPage}
            setCurrentPageOnFeaturedTab={setCurrentPageOnFeaturedTab}
            recordLimit={recordLimit}
            setRecordLimit={setRecordLimit}
            parentBannerMetadata={metadata}
            setShowSettingModal={setShowSettingModal}
            setCurrentSelectedParentBanner={setCurrentSelectedParentBanner}
            parentLoader={bannerLoading}
            setShowFeatureModal={setShowFeatureModal}
            setShowDeleteModal={setShowDeleteModal}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </TabPane>
        <TabPane tabId={2}>
          <BannerTab
            bannerList={bannerList}
            currentPage={currentPage}
            currentPageOnFeaturedTab={currentPageOnFeaturedTab}
            setCurrentPage={setCurrentPage}
            setCurrentPageOnFeaturedTab={setCurrentPageOnFeaturedTab}
            recordLimit={recordLimit}
            setRecordLimit={setRecordLimit}
            parentBannerMetadata={metadata}
            setShowSettingModal={setShowSettingModal}
            setCurrentSelectedParentBanner={setCurrentSelectedParentBanner}
            parentLoader={bannerLoading}
            setShowFeatureModal={setShowFeatureModal}
            setShowDeleteModal={setShowDeleteModal}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </TabPane>
      </TabContent>
      <ConfirmationModal
        confirmBtnText={activeTab === 1 ? "Remove" : "Add to Featured"}
        modalHeaderText={
          activeTab === 1
            ? BANNERS_MESSAGES.REMOVE_FROM_FEATURED
            : BANNERS_MESSAGES.MOVE_TO_FEATURED
        }
        confirmAction={() => {
          updateBanner(
            {
              parent_id: currentSelectedParentBanner.id,
              is_featured: activeTab === 2 ? true : false,
            },
            () => {
              bannerList.length === 1
                ? getBanners(
                    activeTab === 1 ? currentPageOnFeaturedTab : currentPage
                  )
                : getBannerList(getParentBannerListParams())
              setShowFeatureModal(false)
            }
          )
        }}
        modalShow={showFeatureModal}
        setModalShow={setShowFeatureModal}
        loading={bannerLoading}
      />
      <ConfirmationModal
        confirmBtnText="Detele"
        modalHeaderText={BANNERS_MESSAGES.DELETE_PARENT}
        confirmAction={() => {
          deleteBanner(currentSelectedParentBanner.id, () => {
            setShowDeleteModal(false)
            bannerList.length === 1
              ? getBanners(
                  activeTab === 1 ? currentPageOnFeaturedTab : currentPage
                )
              : getBannerList(getParentBannerListParams())
          })
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={bannerLoading}
      />
      <BannerSettingModal
        setShowSettingModal={setShowSettingModal}
        showSettingModal={showSettingModal}
        currentSelectedParentBanner={currentSelectedParentBanner}
        filterBanner={filterBanner}
        deleteBanner={deleteBanner}
        isFeaturedTab={activeTab === 1 ? true : false}
      />
    </>
  )
}
const mapStatetoProps = state => {
  const { Banner } = state
  const { bannerList, bannerLoading, metadata } = Banner
  return { bannerList, bannerLoading, metadata }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getBannerList,
  updateBanner,
  deleteBanner,
})(Banner)
