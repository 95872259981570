export const BANNER = {
  BANNER_FORM: {
    TITLE: "Title",
    LINK: "Link",
    BANNER_IMAGE: "Banner Image",
    BANNER_IMAGE_SIZE: "Banner Image size should be 390x195(width x height)",
    LANGUAGE_CODE: "Language Code",
  },
}

export const BIMA_BHAI_VIDEOS = {
  BIMA_BHAI_VIDEOS_FORM: {
    TITLE: "Title",
    DESCRIPTION: "Description",
    VIDEO_THUMBNAIL: "Video Thumbnail",
    VIDEO: "Video",
    VIDEO_CATEGORY: "Video Category",
    LANGUAGE_CODE: "Language Code",
    TAGS: "Tags",
    NO_TAGS: "No tages",
  },
}

export const SOCIAL_MEDIA = {
  SOCIAL_MEDIA_FORM: {
    NAME: "Name",
    WEBSITE_LINK: "Website Link",
  },
}

export const PRIVACY = {
  PRIVACY_FORM: {
    TITLE: "Title",
    DESCRIPTION: "Description",
    CONTENT: "Content",
  },
}

export const BLOG = {
  BLOG_FORM: {
    TITLE: "Title",
    AUTHOR_NAME: "Author Name",
    BLOG_SLUG: "Slug for blog",
    SLUG_INFO:
      "Slug is unique and use for URl into the blog, should contain only alphanumeric and hyphen",
    SHORT_SUMMARY: "Short Summary",
    CONTENT: "Content",
    BLOG_CATEGORY: "Blog Category",
    LANGUAGE: "Language",
    CREATED_DATE: "Created Date",
    POSTER: "Poster",
  },
}

export const TESTIMONIAL = {
  TESTIMONIAL_FORM: {
    USER_NAME: "User Name",
    FEEDBACK: "Feedback",
    SELECT_LANGUAGE: "Select Language",
    RATING_STAR: "Rating Star",
  },
}

export const PARTNERS = {
  PARTNERS_FORM: {
    NAME: "Name",
    WEBSITE_LINK: "Website Link",
    LANGUAGE: "Language",
    PARTNER_LOGO: "Partner Logo",
  },
}

export const LEAD_PAGE = {
  VIDEO_SECTION: {
    VIDEO_SECTION_FORM: {
      TITLE: "Title",
      VIDEO_LABEL: "Video Section",
      ADD_MEDIA: "Add Media",
      SELECTED_SECTION: "Selected Section",
      LANGUAGE_CODE: "Language Code",
    },
  },
  TEXT_SECTION: {
    TEXT_SECTION_FORM: {
      TITLE: "Title",
      DESCRIPTION: "Description",
      SELECTED_SECTION: "Selected Section",
      LANGUAGE_CODE: "Language Code",
    },
  },
}

export const FAQS = {
  FAQS_FORM: {
    QUESTION: "Question",
    ANSWER: "Answers",
    ANSWER_REQUIRED: "Answer required!",
    FAQS_CATEGORY: "FAQ Category",
    LANGUAGE_CODE: "Language Code",
    FAQS_VIDEO: "FAQs Video",
    ADD_VIDEO: "Add Raksha video",
  },
}

export const CONTACT_US = {
  CONTACT_US_FORM: {
    EMAIL: "E-Mail",
    PHONE: "Phone",
    LATITUDE: "latitude",
    LONGITUDE: "longitude",
    ADDRESS: "Address",
  },
}

export const INSURER = {
  INSURER_FORM: {
    NAME: " Insurance Company Name",
    EMAIL: "Claim help mail ID",
    PHONE_NUMBER: "Motor toll free number",
    HEALTH_PHONE_NUMBER: "Health toll free number",
    WEBSITE: "Insurance site",
  },
}
