import FileDropBox from "components/form-components/FileDropBox"
import FormButton from "components/form-components/FormButton"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import { Field, Form, Formik } from "formik"
import React from "react"
import { Card, CardBody, Col, Label, Row } from "reactstrap"
import Select from "react-select"
import CategoryDropDown from "components/form-components/CategoryDropDown"
import { FILE_TYPE } from "helpers/constants/constants.helper"
import { BIMA_BHAI_VIDEOS } from "helpers/constants/labels.constants"
import { MdAddCircleOutline } from "react-icons/md"

const FormBimaBhaiVideos = props => {
  const {
    fromData,
    BhimaBhaiValidationSchema,
    onSubmit,
    handleTagSearch,
    optionTags,
    setAddTagModal,
    optionVideoCategory,
    animatedComponents,
    parentId,
    childId,
    uploadFile,
    mediaLoader,
    bimaBhaiLoader,
    setCurrentFormState,
  } = props

  const {
    DESCRIPTION,
    LANGUAGE_CODE,
    NO_TAGS,
    TAGS,
    TITLE,
    VIDEO_THUMBNAIL,
    VIDEO,
    VIDEO_CATEGORY,
  } = BIMA_BHAI_VIDEOS.BIMA_BHAI_VIDEOS_FORM
  return (
    <div>
      <Formik
        initialValues={fromData}
        validationSchema={BhimaBhaiValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldTouched,
          handleBlur,
          setFieldValue,
          errors,
          values,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  {/* <span className="card-label">{currentLanguageCode}</span> */}
                  <Row>
                    <div className="mb-4">
                      <Label>{TITLE}</Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="videoTitle"
                      />
                      {errors.videoTitle && touched.videoTitle ? (
                        <div className="position-absolute text-danger">
                          {errors.videoTitle}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{DESCRIPTION}</Label> (optional)
                      <Field
                        as="textarea"
                        rows="4"
                        className="form-control"
                        name="videoDescription"
                      />
                      {errors.videoDescription && touched.videoDescription ? (
                        <div className="position-absolute text-danger">
                          {errors.videoDescription}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Row>
                        <Col>
                          <Label>{VIDEO_THUMBNAIL}</Label>
                          <FileDropBox
                            value={values.thumbnail}
                            handleChange={file => {
                              uploadFile(file, "thumbnail", setFieldValue)
                            }}
                            fileType={FILE_TYPE.IMAGE}
                          />
                          {errors.thumbnail && touched.thumbnail ? (
                            <div className="position-absolute text-danger">
                              {errors.thumbnail}
                            </div>
                          ) : null}
                        </Col>
                        <Col>
                          <Label>{VIDEO}</Label>
                          <FileDropBox
                            value={values.videoFile}
                            handleChange={file => {
                              uploadFile(file, "videoFile", setFieldValue)
                            }}
                            fileType={FILE_TYPE.VIDEO}
                          />
                          {errors.videoFile && touched.videoFile ? (
                            <div className="position-absolute text-danger">
                              {errors.videoFile}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{VIDEO_CATEGORY}</Label>
                        <CategoryDropDown
                          options={optionVideoCategory}
                          value={values.bimaBhaiVideoCategory}
                          setFieldValue={setFieldValue}
                          blur={handleBlur}
                          name="bimaBhaiVideoCategory"
                          setFieldTouched={setFieldTouched}
                          disabled={parentId ? true : false}
                        />
                        {errors.bimaBhaiVideoCategory &&
                          touched.bimaBhaiVideoCategory ? (
                          <div className="position-absolute text-danger">
                            {errors.bimaBhaiVideoCategory}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4">
                        <Label>{LANGUAGE_CODE}</Label>
                        <LanguageDropDown disabled={parentId ? true : false} />
                      </div>
                      <div className="mb-4">
                        <div className="d-flex gap-2">
                          <Label>{TAGS}</Label>
                          {parentId ? null : (
                            <div
                              className="add-tag"
                              onClick={() => {
                                setCurrentFormState(values)
                                setAddTagModal(true)
                              }}
                            >
                              <MdAddCircleOutline />
                            </div>
                          )}
                        </div>
                        <Select
                          value={values.tags}
                          isMulti={true}
                          onChange={arr => {
                            setFieldValue("tags", arr)
                          }}
                          onInputChange={e => {
                            handleTagSearch(e, setFieldValue, "tags")
                          }}
                          noOptionsMessage={() => <>{NO_TAGS}</>}
                          name="tags"
                          options={optionTags}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isDisabled={parentId ? true : false}
                        />
                        {errors.tags && touched.tags ? (
                          <div className="position-absolute text-danger">
                            {errors.tags}
                          </div>
                        ) : null}
                      </div>

                      {/* <div className="mb-4">
                        <div className="d-flex gap-2">
                          <Label>Ignore this section Sir</Label>
                        </div>
                        <CustomTagSelect
                          values={values}
                          setFieldValue={setFieldValue}
                          handleTagSearch={handleTagSearch}
                          optionTags={optionTags}
                          NoOptionsMessage={NoOptionsMessage}
                          setAddTagValue={setAddTagValue}
                        />

                        {errors.tags && touched.tags ? (
                          <div className="position-absolute text-danger">
                            {errors.tags}
                          </div>
                        ) : null}
                      </div> */}
                    </Row>
                  </CardBody>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={mediaLoader || bimaBhaiLoader}
                  type="submit"
                  btnText={childId ? "Update Raksha Video" : "Add Raksha Video"}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

// const CustomTagSelect = ({
//   values,
//   setFieldValue,
//   handleTagSearch,
//   optionTags,
//   NoOptionsMessage,
//   setAddTagValue,
// }) => {
//   return (
//     <>
//       <Select
//         value={values.tags}
//         isMulti={true}
//         name="tags"
//         onChange={arr => {
//           setFieldValue("tags", arr)
//         }}
//         onInputChange={e => {
//           if (e) {
//             setAddTagValue(e)
//             handleTagSearch(e, setFieldValue, "tags")
//           }
//         }}
//         options={optionTags}
//         classNamePrefix="select2-selection"
//         closeMenuOnSelect={false}
//         components={{ NoOptionsMessage }}
//       />
//     </>
//   )
// }

export default FormBimaBhaiVideos
