import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_PARTNER,
  DELETE_PARTNER,
  GET_ALL_PARTNERS,
  GET_PARTNER,
  UPDATE_PARTNER,
} from "./actionTypes"
import { getAllPartnersSuccess, setLoading } from "./actions"
import {
  addPartner,
  deletePartner,
  getAllPartners,
  getPartner,
  updatePartner,
} from "./httpCalls.partner"

function* GetAllPartners(action) {
  const response = yield call(getAllPartners, action.payload)
  if (response?.success) {
    yield put(getAllPartnersSuccess(response))
  } else {
    yield put(setLoading(false))
  }
}

function* GetPartner(action) {
  const response = yield call(getPartner, action.payload.partnerId)
  if (response?.success) {
    action.payload.cb(response.data[0])
  }
}

function* AddPartner(action) {
  const response = yield call(addPartner, action.payload.data)
  if (response?.success) {
    yield put(getAllPartnersSuccess(response))
    toast.success(response.message)
    action.payload.cb()
  } else {
    yield put(setLoading(false))
  }
}

function* UpdatePartner(action) {
  yield put(setLoading(true))
  const response = yield call(
    updatePartner,
    action.payload.partnerId,
    action.payload.data
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(false))
}

function* DeletePartner(action) {
  const response = yield call(deletePartner, action.payload.partnerIdList)
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(false))
}

function* partnerSaga() {
  yield takeEvery(GET_ALL_PARTNERS, GetAllPartners)
  yield takeEvery(GET_PARTNER, GetPartner)
  yield takeEvery(ADD_PARTNER, AddPartner)
  yield takeEvery(UPDATE_PARTNER, UpdatePartner)
  yield takeEvery(DELETE_PARTNER, DeletePartner)
}

export default partnerSaga
