import { SEARCH, SEARCH_ERROR, SEARCH_SUCCESS } from "./actionTypes"

export const search = (params, cb) => {
  return {
    type: SEARCH,
    payload: { params, cb },
  }
}

export const searchSuccess = response => {
  return {
    type: SEARCH_SUCCESS,
    payload: response,
  }
}

export const searchError = () => {
  return {
    type: SEARCH_ERROR,
  }
}
