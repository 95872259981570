import TabPill from "components/common/tab-pill"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import styles from "./banner.module.css"
import { BANNER_CHILD_URL } from "helpers/api-end-points.constant.helper"
import { MdDelete } from "react-icons/md"
import { FaRegEdit, FaLink } from "react-icons/fa"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const BannerCard = ({ banner, filterBanner, deleteBanner, isFeaturedTab }) => {
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false)
  const History = useHistory()

  const linkClickHandler = (BannerId, link) => {
    if (!link)
      History.push(
        isFeaturedTab
          ? `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.EDIT_BANNER}/${BannerId}?callback=${ROUTES_PATH.FEATURED}`
          : `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.EDIT_BANNER}/${BannerId}`
      )
  }
  return (
    <div className={`${styles.image_container}`}>
      <img
        src={banner.images.location}
        alt="image"
        className={`${styles.banner_image}`}
      />
      <div className={`${styles.actions_container}`}>
        {deleteBtnClicked ? (
          <>
            <button
              className="btn btn-dark"
              onClick={e => {
                e.stopPropagation()
                setDeleteBtnClicked(!deleteBtnClicked)
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={e => {
                e.stopPropagation()
                setDeleteBtnClicked(!deleteBtnClicked)
                deleteBanner(banner.id, filterBanner, BANNER_CHILD_URL)
              }}
              id={`deletebtn${banner.id}`}
            >
              Delete
            </button>
            <UncontrolledTooltip
              placement="bottom"
              target={`deletebtn${banner.id}`}
            >
              You are About to Delete this Banner. Are you sure?
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <TabPill
              classNames={`${styles.tab_pill}`}
              label={banner.language_code}
              status={true}
            />

            <FaLink
              id={`link${banner.id}`}
              className={`${styles.action_icons} ${
                banner.link ? "text-success" : "disabled"
              } me-2 ms-1 icons`}
              onClick={() => linkClickHandler(banner.id, banner.link)}
            />
            <UncontrolledTooltip placement="bottom" target={`link${banner.id}`}>
              {banner.link ? banner.link : "Add Link"}
            </UncontrolledTooltip>

            <Link
              to={
                isFeaturedTab
                  ? `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.EDIT_BANNER}/${banner.id}?callback=${ROUTES_PATH.FEATURED}`
                  : `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.EDIT_BANNER}/${banner.id}`
              }
            >
              <FaRegEdit
                id={`editTooltip${banner.id}`}
                className={`${styles.action_icons} text-success me-1 icons`}
              />
            </Link>
            <UncontrolledTooltip
              target={`editTooltip${banner.id}`}
              placement="bottom"
            >
              Edit
            </UncontrolledTooltip>

            {!isFeaturedTab && (
              <>
                <MdDelete
                  onClick={() => setDeleteBtnClicked(true)}
                  id={`deleteTooltip${banner.id}`}
                  className={`${styles.action_icons} text-danger me-2 icons`}
                />
                <UncontrolledTooltip
                  target={`deleteTooltip${banner.id}`}
                  placement="bottom"
                >
                  Delete
                </UncontrolledTooltip>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default BannerCard
