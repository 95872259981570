import React from "react"
import TabPill from "components/common/tab-pill"
import TableFooter from "components/common/TableFooter"
// import { Link } from "react-router-dom"
import { Card, CardBody, Table, UncontrolledTooltip } from "reactstrap"
import styles from "./blog.module.css"
import { Link, useHistory } from "react-router-dom"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { getRemainingLanguages } from "helpers/utils.helper"
import CustomTableLoader from "components/loader/CustomTableLoader"
import { SEARCH_WARNING_MESSAGE } from "helpers/constants/messages.constants"
import FormButton from "components/form-components/FormButton"
import { MdDelete, MdInfoOutline, MdSettings } from "react-icons/md"

const blogTableTableHead = [
  {
    value: "Title",
    id: "id",
    classNames: "text-start text-truncate",
    style:{maxWidth:"5rem"}
  },
  { value: "Category", id: "category", style: { width: "6rem" } },
  { value: "Languages", id: "language", style: { width: "12rem" } },
  { value: "Slug", id: "slug", style: { width: "6rem" } },
  { value: "Created Date", id: "date", style: { width: "8rem" } },
]

const BlogParentList = props => {
  const {
    parentBlogLoader,
    parentBlogList,
    parentBlogMetadata,
    currentPage,
    recordLimit,
    languages,
    setCurrentPage,
    setRecordLimit,
    setSelectedBlog,
    setSettingModal,
    setDeleteModal,
    searchText,
    setSearchText,
  } = props

  const { vertical_align_middle } = styles
  return (
    <Card>
      <CardBody>
        <div className="row mb-2">
          <div className="col-3">
            <input
              className="form-control"
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={e => {
                setCurrentPage(0)
                setSearchText(e.target.value)
              }}
            />
          </div>
          <div className="col-6 d-flex align-items-center p-0">
            {searchText && parentBlogList.length ? (
              <>
                <MdInfoOutline className="text-warning search-info-icon" />
                <span className="text-warning ps-1 search-info-text">
                  {SEARCH_WARNING_MESSAGE}
                </span>
              </>
            ) : null}
          </div>
          <div className="col-3">
            <Link
              to={`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.ADD_BLOG}`}
            >
              <div>
                <FormButton
                  customClass="btn-dark "
                  type="button"
                  btnText="Add Blog"
                />
              </div>
            </Link>
          </div>
        </div>
        <>
          <div className="table-responsive">
            <Table className="table-striped mb-0">
              <thead className="bg-dark text-white sticky">
                <tr className="text-center">
                  {blogTableTableHead.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className={item.classNames || null}
                        style={item.style}
                      >
                        {item.value}
                      </th>
                    )
                  })}
                  <th className="action-tab">Action</th>
                </tr>
              </thead>
              <tbody>
                {parentBlogLoader ? (
                  <CustomTableLoader col={6} />
                ) : parentBlogList && parentBlogList.length ? (
                  parentBlogList.map(item => {
                    return (
                      <tr className={vertical_align_middle} key={item.id}>
                        <td>
                          <div
                            className="text-start text-truncate"
                            id={`titleTooltip-${item.id}`}
                            style={{maxWidth:"12rem"}}
                          >
                            {item?.BlogLanguage[0]?.title}
                            {/* <UncontrolledTooltip
                              target={`titleTooltip-${item.id}`}
                            >
                              {item?.BlogLanguage[0]?.title}
                            </UncontrolledTooltip> */}
                          </div>
                        </td>
                        <td>
                          <TabPill
                            classNames="mb-0"
                            label={item.master_blog_categories.name}
                            status={true}
                          />
                        </td>
                        <td>
                          <LanguageTabs
                            languages={languages}
                            parentItem={item}
                          />
                        </td>
                        <td>
                          <div
                            className="text-truncate"
                            style={{ maxWidth: "26rem" }}
                          >
                            {item?.slug}
                          </div>
                        </td>
                        <td>
                          {new Date(item.blog_created_date).toLocaleDateString(
                            "en-IN"
                          )}
                        </td>
                        <td className="icon-container">
                          <div>
                            <>
                              <MdSettings
                                className="
                                text-success icons"
                                id={`setting-${item.id}`}
                                onClick={() => {
                                  setSelectedBlog(item)
                                  setSettingModal(true)
                                }}
                              />
                              <UncontrolledTooltip
                                target={`setting-${item.id}`}
                                placement="bottom"
                              >
                                Settings
                              </UncontrolledTooltip>
                            </>
                            <>
                              <MdDelete
                                className="text-danger icons"
                                id={`delete-${item.id}`}
                                onClick={() => {
                                  setSelectedBlog(item)
                                  setDeleteModal(true)
                                }}
                              />
                              <UncontrolledTooltip
                                target={`delete-${item.id}`}
                                placement="bottom"
                              >
                                Delete
                              </UncontrolledTooltip>
                            </>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={200} className="text-center">
                      <h3>No Blog Found !</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
        {parentBlogList && parentBlogList.length ? (
          <TableFooter
            metadata={parentBlogMetadata}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordLimit={recordLimit}
            setrecordLimit={setRecordLimit}
          />
        ) : null}
      </CardBody>
    </Card>
  )
}

const LanguageTabs = ({ languages, parentItem }) => {
  const history = useHistory()
  const remainingLanguages = getRemainingLanguages(
    languages,
    parentItem.BlogLanguage
  )
  return (
    <div className="d-flex flex-nowrap justify-content-center">
      {parentItem.BlogLanguage.map((languageObj, i) => (
        <TabPill
          key={i}
          status={languageObj.status}
          label={languageObj.language_code}
          classNames="mb-0 lang-tab"
        />
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code} +`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.ADD_BLOG}/${language.language_code}/${parentItem?.id}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
      {/* TODO: have to discuss for rendering sequense */}
      {/* {languages.map(lang => {
        const isLangAdded = parentItem.BlogLanguage.find(
          item => item.language_code === lang.language_code
        )
        if (isLangAdded)
          return (
            <TabPill
              key={lang.id}
              status={isLangAdded.status}
              label={lang.language_code}
              classNames="mb-0 lang-tab"
            />
          )
        else
          return (
            <TabPill
              key={lang.id}
              status={false}
              label={lang.language_code + " +"}
              classNames="mb-0 lang-tab"
            />
          )
      })} */}
    </div>
  )
}

export default BlogParentList
