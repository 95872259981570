import {
  ADD_TEXT_SECTION,
  ADD_TEXT_SECTION_ERROR,
  ADD_TEXT_SECTION_SUCCESS,
  ADD_VIDEO_SECTION,
  ADD_VIDEO_SECTION_ERROR,
  ADD_VIDEO_SECTION_SUCCESS,
  DELETE_LEAD_SECTION,
  DELETE_LEAD_SECTION_ERROR,
  DELETE_LEAD_SECTION_SUCCESS,
  GET_ALL_LEAD_SECTION,
  GET_ALL_LEAD_SECTION_ERROR,
  GET_ALL_LEAD_SECTION_SUCCESS,
  SINGLE_SECTION,
  SINGLE_SECTION_ERROR,
  SINGLE_SECTION_SUCCESS,
  UPDATE_TEXT_SECTION,
  UPDATE_TEXT_SECTION_ERROR,
  UPDATE_TEXT_SECTION_SUCCESS,
  UPDATE_VIDEO_SECTION,
  UPDATE_VIDEO_SECTION_ERROR,
  UPDATE_VIDEO_SECTION_SUCCESS,
} from "./actionTypes"

const initialState = {
  LeadPageAllSections: [],
  LeadPageSection: {},
  metadata: {},
  loading: false,
  table_loader: false,
}

const leadPages = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEXT_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TEXT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_TEXT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_VIDEO_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_VIDEO_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_VIDEO_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_ALL_LEAD_SECTION:
      state = {
        ...state,
        LeadPageAllSections: [],
        metadata: {},
        loading: true,
        table_loader: true,
      }
      break
    case GET_ALL_LEAD_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        table_loader: false,
        LeadPageAllSections: action?.payload?.data || [],
        metadata: action?.payload?.metadata || {},
      }
      break
    case GET_ALL_LEAD_SECTION_ERROR:
      state = {
        ...state,
        table_loader: false,
        loading: false,
      }
      break
    case SINGLE_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case SINGLE_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case SINGLE_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_LEAD_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_LEAD_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_LEAD_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_TEXT_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_TEXT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_TEXT_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_VIDEO_SECTION:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_VIDEO_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_VIDEO_SECTION_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default leadPages
