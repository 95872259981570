import { FILE_TYPE } from "helpers/constants/constants.helper"
import { formatBytes } from "helpers/utils.helper"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"
import { MdOutlineCloudUpload } from "react-icons/md"

const FileDropBox = props => {
  const { value } = props
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    props.handleChange(files)
  }

  useEffect(() => {
    if (value.length) {
      setselectedFiles(value)
    }
  }, [value])

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {/* <Card className="mb-0">
            <CardBody> */}
          <div
            className="d-flex align-items-center form-control"
            style={{ padding: "20px" }}
          >
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
              accept={props?.fileType}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="dropzone dz-clickable"
                  style={{ minHeight: "0", padding: "12px" }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <MdOutlineCloudUpload className="text-muted display-6" />
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews" id="file-previews">
              {selectedFiles.length ? (
                selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete ms-4"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          {props.fileType.includes(FILE_TYPE.VIDEO) ? (
                            <Col className="col-auto">
                              <video
                                src={f.preview}
                                alt={f.name}
                                height="48"
                                width="48"
                              ></video>
                            </Col>
                          ) : (
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="48"
                                width="48"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )}
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold filedrop_text"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })
              ) : (
                <h3 className="m-0 ms-4">No file selected</h3>
              )}
            </div>
          </div>
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FileDropBox
