import {
  GET_ALL_BIMABHAI,
  GET_ALL_BIMABHAI_SUCCESS,
  GET_BIMABHAI,
  GET_BIMABHAI_SUCCESS,
  loadingStateBimabhai,
  SET_BIMABHAI_LOADING,
} from "./actionTypes"

const initialState = {
  bimabhaiVideos: [],
  metadata: {},
  [loadingStateBimabhai.getLoading]: false,
  [loadingStateBimabhai.deleteLoading]: false,
}

const BimaBhaiVideos = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BIMABHAI:
      state = {
        ...state,
        bimabhaiVideoList: [],
        bimabhaiSingleVideo: {},
        loading: true,
      }
      break
    case GET_ALL_BIMABHAI_SUCCESS:
      state = {
        ...state,
        bimabhaiVideoList: action.payload?.data || [],
        metadata: action.payload?.metadata,
      }
      break
    case GET_BIMABHAI:
      state = {
        ...state,
        bimabhaiSingleVideo: {},
        loading: true,
      }
      break
    case GET_BIMABHAI_SUCCESS:
      state = {
        ...state,
        bimabhaiSingleVideo: action.payload?.data,
        metadata: action.payload?.metadata,
      }
      break
    case SET_BIMABHAI_LOADING:
      state = {
        ...state,
        [action.payload.key]: action.payload.val,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default BimaBhaiVideos
