import { del, get, patch, post } from "helpers/api.helper"

export async function getAboutUs(params) {
  try {
    const res = await get(`/aboutUs`, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function singleAboutUs(aboutUsId) {
  try {
    const res = await get(`/aboutUs/${aboutUsId}`)
    return res
  } catch (err) {
    return err
  }
}

export async function addAboutUs(aboutUsFormData) {
  try {
    const res = await post(`/aboutUs`, aboutUsFormData)
    return res
  } catch (err) {
    return err
  }
}

export async function deleteAboutUs(aboutUsId) {
  try {
    const res = await del(`/aboutUs`, { data: { ids: aboutUsId } })
    return res
  } catch (err) {
    return err
  }
}

export async function editAboutUs(params) {
  try {
    const { aboutId, description, content, name } = params
    const res = await patch(`/aboutUs/${aboutId}`, {
      description,
      content,
      name,
    })
    return res
  } catch (err) {
    return err
  }
}
