import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import {
  setBreadcrumbItems,
  addMedia,
  setCurrentLanguageCode,
  addBanner,
  getParentBanner,
  getBanner,
  updateBanner,
} from "store/actions"
import BannerForm from "./BannerForm"
import * as Yup from "yup"
import { formatBytes } from "helpers/utils.helper"
const initalFaqForm = {
  title: "",
  link: "",
  image: "",
}
const BannerValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title required !"),
  link: Yup.string().url("Enter valid website"),
  image: Yup.array().min(1, "Image required !").required("Image required"),
})
const AddBannerBreadcrumbItems = [{ title: BREADCRUM.ADD_BANNER, link: "/" }]
const EditBannerBreadcrumbItems = [{ title: BREADCRUM.EDIT_BANNER, link: "/" }]
const BannerAdd = ({
  setBreadcrumbItems,
  addMedia,
  currentLanguageCode,
  setCurrentLanguageCode,
  addBanner,
  bannerLoading,
  mediaLoader,
  getParentBanner,
  getBanner,
  updateBanner,
}) => {
  const [formData, setFormData] = useState(initalFaqForm)
  const [submitbtn, setSubmitbtn] = useState("Add Banner")
  const { childBannerId, parentBannerId, bannerLanguage } = useParams()
  const history = useHistory()
  function createMediaObj(obj) {
    if (!obj) return []
    return [
      {
        id: obj?.id || "",
        formattedSize: obj?.size ? formatBytes(obj?.size) : "Unknown",
        preview: obj?.location || "",
        name: obj?.originalname || "Unknown",
      },
    ]
  }
  const setBannerData = data => {
    setFormData({
      ...formData,
      title: data.title,
      link: data?.link || "",
      image: createMediaObj(data.images),
    })
    setCurrentLanguageCode(data.language_Code)
  }
  useEffect(() => {
    if (childBannerId) {
      setBreadcrumbItems(
        {
          title: BREADCRUM.BANNER,
          pageTitleLink: `/${ROUTES_PATH.BANNER}`,
        },
        EditBannerBreadcrumbItems
      )
      setSubmitbtn("Edit Banner")
      getBanner(childBannerId, setBannerData)
    } else {
      setBreadcrumbItems(
        {
          title: BREADCRUM.BANNER,
          pageTitleLink: `/${ROUTES_PATH.BANNER}`,
        },
        AddBannerBreadcrumbItems
      )
    }
  }, [])
  function uploadFile(file, name, setFieldValue) {
    const formdata = new FormData()
    formdata.append("name", file[0].name)
    formdata.append("file", file[0])
    addMedia({
      formdata,
      cb: res => {
        setFieldValue(name, res)
      },
    })
  }
  const checkParentBannerExist = res => {
    if (res?.code != 200) {
      history.push(`/${ROUTES_PATH.BANNER}`)
      toast.error("Parent Banner dose not Exist", { toastId: "parent banner" })
    }
  }
  useEffect(() => {
    if (parentBannerId) {
      setCurrentLanguageCode(bannerLanguage)
      getParentBanner(parentBannerId, checkParentBannerExist)
    }
  }, [])
  const cb = () => {
    history.location.search.includes(ROUTES_PATH.FEATURED)
      ? history.push(`/${ROUTES_PATH.BANNER}?tab=${ROUTES_PATH.FEATURED}`)
      : history.push(`/${ROUTES_PATH.BANNER}`)
  }
  const onSubmit = values => {
    const data = {
      title: values.title,
      image: values.image[0].id,
    }
    if (values.link) data.link = values.link
    if (childBannerId) {
      data.id = childBannerId
      updateBanner(data, cb)
    } else {
      data.language_code = currentLanguageCode
      if (parentBannerId) {
        data.id = parentBannerId
      }
      addBanner(data, cb)
    }
  }
  return (
    <>
      <BannerForm
        formData={formData}
        submitbtn={submitbtn}
        uploadFile={uploadFile}
        onSubmit={onSubmit}
        parentBannerId={parentBannerId}
        childBannerId={childBannerId}
        BannerValidationSchema={BannerValidationSchema}
        submitLoader={bannerLoading}
        mediaLoader={mediaLoader}
      />
    </>
  )
}
const mapStatetoProps = state => {
  const { Language, Banner, Media } = state
  const { currentLanguageCode } = Language
  const { bannerLoading } = Banner
  const { loading: mediaLoader } = Media
  return { currentLanguageCode, bannerLoading, mediaLoader }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  addMedia,
  setCurrentLanguageCode,
  addBanner,
  getParentBanner,
  getBanner,
  updateBanner,
})(BannerAdd)
