import {
  GET_ALL_LANGUAGES,
  GET_ALL_LANGUAGES_ERROR,
  GET_ALL_LANGUAGES_SUCCESS,
  SET_CURRENT_LANGUAGE_CODE,
} from "./actionTypes"

const initialState = {
  languages: [],
  metadata: {},
  currentLanguageCode: "en",
  loading: false,
}

const language = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LANGUAGES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALL_LANGUAGES_SUCCESS:
      state = {
        ...state,
        languages: action.payload?.data,
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case GET_ALL_LANGUAGES_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case SET_CURRENT_LANGUAGE_CODE:
      state = {
        ...state,
        currentLanguageCode: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default language
