import Select from "react-select"

export const reactSelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? "#e9ecef" : "#fff",
    color: "#5b626b",
    borderColor: state.isFocused ? "#b9bfc4" : "#ced4da",
    minHeight: "33.5px",
    height: "33.5px",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: provided => ({
    ...provided,
    height: "33.5px",
    padding: "0 6px",
  }),

  input: provided => ({
    ...provided,
    margin: "0px",
    color: "#5b626b",
  }),
  // indicatorSeparator: state => ({
  //   display: "none",
  // }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "33.5px",
  }),

  singleValue: provided => ({
    ...provided,
    color: "#5b626b",
  }),
}

export default function CustomSelect({
  onChange,
  options,
  value,
  name,
  className = "",
  placeholder = "Select...",
  defaultSelectValue = null,
  maxMenuHeight = null,
  isMulti = false,
  disabled,
}) {
  return (
    <div>
      <Select
        className={className}
        classNamePrefix="select2-selection"
        name={name}
        value={value}
        onChange={value => {
          onChange(value)
        }}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultSelectValue}
        maxMenuHeight={maxMenuHeight}
        // styles={customStyles}
        isMulti={isMulti}
        isDisabled={disabled}
        styles={reactSelectCustomStyles}
      />
    </div>
  )
}
