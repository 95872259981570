import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_SOCIAL_MEDIA,
  DELETE_SOCIAL_MEDIA,
  GET_ALL_SOCIAL_MEDIAS,
  GET_SOCIAL_MEDIA,
  UPDATE_SOCIAL_MEDIA,
} from "./actionTypes"
import { getAllSocialMediaSuccess, setLoading } from "./actions"
import {
  addSocialMedia,
  deleteSocialMedia,
  getAllSocialMedias,
  getSocialMedia,
  updateSocialMedia,
} from "./httpCalls.social"

function* GetAllSocialMedias(action) {
  const response = yield call(getAllSocialMedias, action.payload)
  if (response?.success) {
    yield put(getAllSocialMediaSuccess(response))
  } else {
    yield put(setLoading(false))
  }
}

function* GetSocialMedia(action) {
  const response = yield call(getSocialMedia, action.payload.socialMediaId)
  if (response?.success) {
    action.payload.cb(response.data[0])
  }
}

function* AddSocialMedia(action) {
  const response = yield call(addSocialMedia, action.payload.data)
  if (response?.success) {
    yield put(getAllSocialMediaSuccess(response))
    toast.success(response.message)
    action.payload.cb()
  } else {
    yield put(setLoading(false))
  }
}

function* UpdateSocialMedia(action) {
  // yield put(setLoading(true))
  const response = yield call(
    updateSocialMedia,
    action.payload.socialMediaId,
    action.payload.data
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  // yield put(setLoading(false))
}

function* DeleteSocialMedia(action) {
  const response = yield call(
    deleteSocialMedia,
    action.payload.socialMediaIdList
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(false))
}

function* socialMediaSaga() {
  yield takeEvery(GET_ALL_SOCIAL_MEDIAS, GetAllSocialMedias)
  yield takeEvery(GET_SOCIAL_MEDIA, GetSocialMedia)
  yield takeEvery(ADD_SOCIAL_MEDIA, AddSocialMedia)
  yield takeEvery(UPDATE_SOCIAL_MEDIA, UpdateSocialMedia)
  yield takeEvery(DELETE_SOCIAL_MEDIA, DeleteSocialMedia)
}

export default socialMediaSaga
