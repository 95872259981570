import { get } from "helpers/api.helper"

let leadsAboartController
export async function getleads(leadParams) {
  try {
    const { activeTab, start, end, recordLimit, currentPage } = leadParams
    if (leadsAboartController) {
      leadsAboartController.abort()
    }
    leadsAboartController = new AbortController()
    const res = await get(
      "/lead",
      {
        params: {
          startDate: start,
          endDate: end,
          pageNum: currentPage,
          limit: recordLimit,
          productType: activeTab,
        },
      },
      {
        headers: {
          signal: leadsAboartController.signal,
        },
      }
    )
    return res
  } catch (err) {
    return err
  }
}

let downloadAboartController
export async function downloadleads(params) {
  try {
    const { activeTab, start, end, cb } = params
    if (downloadAboartController) {
      downloadAboartController.abort()
    }
    downloadAboartController = new AbortController()
    const res = await get("/lead/download", {
      params: {
        startDate: start,
        endDate: end,
        productType: activeTab,
      },
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        signal: downloadAboartController.signal,
      },
    })
    return { res, cb }
  } catch (err) {
    return err
  }
}
