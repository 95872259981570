import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { filterBimaBhaiVideo } from "store/actions"
import { Modal } from "reactstrap"
import SkeletonLoader from "./SkeletonLoader"
import Pagination from "./Pagination"

const SelectMediaModal = props => {
  const {
    multiple = false,
    modalShow,
    setModalShow,
    setVideos,
    currentLanguageCode,
    bimabhaiVideoList,
    filterBimaBhaiVideo,
    loaderBimaBhai,

  } = props
  const [bimaBhaiVideos, setBimaBhaiVideos] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [searchText, setSearchText] = useState("")
  const [currentPage, setCurrentPage] = useState(0);
  const [metadata, setMetaData] = useState({});

  const handelResponseData = (data, metadata) => {
    setMetaData(metadata)
    let filteredArray = data
      .filter(item => {
        if (item?.master_media_video_id || item?.master_media_thumbnail_id) {
          return true
        } else return false
      })
      .map(videoObj => {
        return {
          bhemaBhaiVideoId: videoObj.id,
          video_title: videoObj.title,
          thumbnail: videoObj.master_media_thumbnail_id.location,
          selected: false,
        }
      })
    setBimaBhaiVideos(filteredArray)
  }

  const multipleVideoCardClickHandler = targetVideoObj => {
    let newSelectedVideosArray = bimaBhaiVideos.map(video => {
      if (video.bhemaBhaiVideoId === targetVideoObj.bhemaBhaiVideoId) {
        video.selected = !targetVideoObj.selected
      }
      return video
    })
    let selectedFilterdVideo = newSelectedVideosArray.filter(item => {
      return item.selected
    })
    setSelectedItems(selectedFilterdVideo)
    setBimaBhaiVideos(newSelectedVideosArray)
  }

  const singleVideoCardClickHandler = targetVideoObj => {
    let selectedFilteredVideos = bimaBhaiVideos.map(video => {
      if (video.bhemaBhaiVideoId === targetVideoObj.bhemaBhaiVideoId) {
        video.selected = !targetVideoObj.selected
      } else {
        video.selected = false
      }
      return video
    })
    setSelectedItems([targetVideoObj])
    setBimaBhaiVideos(selectedFilteredVideos)
  }
  useEffect(() => {
    if (bimabhaiVideoList && bimabhaiVideoList?.length) {
      bimabhaiVideoList.map(parentBimaBhaiObj => {
        parentBimaBhaiObj.bima_bhai_by_languages.map(childObj => {
          return childObj.language_code === currentLanguageCode
        })
      })
    }
  }, [bimabhaiVideoList])

  useEffect(() => {
    let timeout
    setSelectedItems([])
    if (searchText) {
      timeout = setTimeout(() => {
        if (currentPage === 0) {
          filterBimaBhaiVideo(
            {
              language: currentLanguageCode,
              recordLimit: 8,
              pageNum: currentPage,
              searchText: searchText,
            },
            handelResponseData
          )
        } else { setCurrentPage(0) }
      }, 500)

    } else {
      filterBimaBhaiVideo(
        { language: currentLanguageCode, recordLimit: 8, pageNum: currentPage },
        handelResponseData
      )
    }

    return () => clearTimeout(timeout)
  }, [searchText, currentPage])
  return (
    <Modal isOpen={modalShow} centered={true} size="lg">
      <div className="modal-body">
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value)
            }}
          />
        </div>
        <div className="mt-3 w-100 d-flex flex-wrap gap-4 ml-2rem">
          {loaderBimaBhai ? <VideoLoader /> :
            (bimaBhaiVideos.length ? (
              bimaBhaiVideos.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`video_card_container`}
                    data-selected={item.selected}
                    onClick={() => {
                      if (multiple) {
                        multipleVideoCardClickHandler(item)
                      } else {
                        singleVideoCardClickHandler(item)
                      }
                      // console.log(bimaBhaiVideos, "Videos")
                      // setSelectedItems([{ ...item, selected: true }])
                    }}
                  >
                    <div>
                      <img
                        src={item.thumbnail}
                        alt="imagedd"
                        width="100%"
                        height="90px"
                      />
                    </div>
                    <div
                      className="text-center video-title text-truncate"
                      title={item.video_title}
                    >
                      {item.video_title}
                    </div>
                  </div>
                )
              })
            ) : (
              <h4 className="text-danger">
                Please add some videos in
                <strong> &quot;{currentLanguageCode}&quot;</strong>
              </h4>
            ))}
        </div>
      </div>
      <div className="modal-footer justify-content-between">
        <div>

          {bimaBhaiVideos.length > 0 && <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            metadata={metadata}
          />}
        </div>
        <div>
          <button
            onClick={() => {
              if (multiple) {
                setVideos(oldState => {
                  if (selectedItems.length && oldState?.length) {
                    let filterdUniqueVideos = selectedItems.filter(
                      selectedValues => {
                        return !oldState.some(oldValues => {
                          return (
                            oldValues.bhemaBhaiVideoId ===
                            selectedValues.bhemaBhaiVideoId
                          )
                        })
                      }
                    )
                    return [...oldState, ...filterdUniqueVideos]
                  } else {
                    return selectedItems
                  }
                })
              } else {
                setVideos(selectedItems)
              }
              setModalShow(false)
            }}
            disabled={
              bimaBhaiVideos.length && selectedItems?.length ? false : true
            }
            className="btn btn-primary mx-2 w-md"
          >
            Add to Video Section
          </button>
          <button
            onClick={() => setModalShow(false)}
            className="btn btn-danger mx-2 w-md"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}



const VideoLoader = () => {
  return (
    <>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
      <div className='videoloader'>
        <SkeletonLoader customClass="h-100" />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { Language, BimaBhaiVideos } = state
  const { currentLanguageCode } = Language
  const {
    loading: loaderBimaBhai,
    bimabhaiVideoList,
    metadata: bimaBhaiMetadata,
  } = BimaBhaiVideos
  return {
    currentLanguageCode,
    loaderBimaBhai,
    bimabhaiVideoList,
    bimaBhaiMetadata,
  }
}


export default connect(mapStateToProps, { filterBimaBhaiVideo })(
  SelectMediaModal
)
