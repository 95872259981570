import React, { useState } from "react"
import PropTypes from "prop-types"
import { FaEyeSlash, FaEye } from "react-icons/fa"

import { Row, Col, CardBody, Card, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// actions
import { loginUser } from "../../store/actions"

// import images
import logoLightPng from "../../assets/images/policyLeaderLogoNew.webp"
import logoDark from "../../assets/images/policyLeaderLogoNew.webp"
import { Formik } from "formik"
import * as Yup from "yup"
import { IoHeart } from "react-icons/io5"

const initalLoginForm = {
  email: "",
  password: "",
}

const Login = props => {
  const { loading } = props
  const [showPassword, setShowPassword] = useState(false)

  // ValidationSchema
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email required!").email("Invalid email!"),
    password: Yup.string().required("Password required!"),
  })
  // handleValidSubmit
  const handleSubmit = values => {
    props.loginUser(values, props.history)
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="50"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLightPng}
                        alt=""
                        height="50"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Policy leader CMS.
                    </p>
                    <Formik
                      initialValues={initalLoginForm}
                      validationSchema={loginValidationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        handleBlur,
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="form-horizontal mt-4"
                        >
                          <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <input
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              required
                            />
                            {errors.email && touched.email ? (
                              <div className="position-absolute text-danger small">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="password">Password</label>
                            <div className="position-relative">
                              <input
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                required
                                placeholder="Enter Password"
                              />
                              {showPassword ? (
                                <FaEyeSlash
                                  className="position-absolute password_eye cursor-pointer"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <FaEye
                                  className="position-absolute password_eye cursor-pointer"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              )}
                            </div>
                            {errors.password && touched.password ? (
                              <div className="position-absolute text-danger small">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 row mt-4">
                            {/* TODO:No Need In Present But we will need these in future */}
                            {/* <div className="col-6">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div> */}
                            <div className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                              >
                                {loading && (
                                  <span
                                    className="spinner-border spinner-border-sm text-light position-absolute"
                                    style={{ left: "8px", top: "8px" }}
                                  ></span>
                                )}
                                Log In
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to={`/${ROUTES_PATH.REGISTER}`}
                    className="text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Policy leader{" "}
                  <span className="d-none d-sm-inline-block">
                    {" "}
                    - Crafted with <IoHeart className="text-danger" /> by
                    GlobalVox.
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, loading } = state.Login
  return { error, loading }
}

export default withRouter(connect(mapStateToProps, { loginUser })(Login))

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
