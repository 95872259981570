export const GET_INSURER_LIST = "GET_INSURER_LIST"
export const GET_INSURER_LIST_SUCCESS = "GET_INSURER_LIST_SUCCESS"

export const ADD_INSURER = "ADD_INSURER"

export const GET_INSURER = "GET_INSURER"

export const UPDATE_INSURER = "UPDATE_INSURER"

export const DELETE_INSURER = "DELETE_INSURER"

export const SET_INSURER_LOADING = "SET_INSURER_LOADING"
