import { PARTNER_URL } from "helpers/api-end-points.constant.helper"
import { get, post, del, patch } from "helpers/api.helper"

// let leadsAboartController
export async function getAllPartners(params) {
  try {
    const res = await get(PARTNER_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function getPartner(partnerId) {
  try {
    const res = await get(PARTNER_URL + partnerId)
    return res
  } catch (err) {
    return err
  }
}

export async function addPartner(data) {
  try {
    const res = await post(PARTNER_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function updatePartner(partnerId, data) {
  try {
    const res = await patch(PARTNER_URL + partnerId, data)
    return res
  } catch (err) {
    return err
  }
}

export async function deletePartner(partnerIdList) {
  try {
    const res = await del(PARTNER_URL, { data: { ids: partnerIdList } })
    return res
  } catch (err) {
    return err
  }
}
