import { get, post, del, patch } from "helpers/api.helper"

export async function getAllSocialMedias(params) {
  try {
    const res = await get("/social-media", { params })
    return res
  } catch (err) {
    return err
  }
}

export async function getSocialMedia(socialMediaId) {
  try {
    const res = await get(`/social-media/${socialMediaId}`)
    return res
  } catch (err) {
    return err
  }
}

export async function addSocialMedia(data) {
  try {
    const res = await post("/social-media", data)
    return res
  } catch (err) {
    return err
  }
}

// TODO: Change this later
export async function updateSocialMedia(socialMediaId, data) {
  try {
    const res = await patch("/social-media", {
      id: socialMediaId,
      changes: data,
    })
    return res
  } catch (err) {
    return err
  }
}

export async function deleteSocialMedia(socialMediaIdList) {
  try {
    const res = await del("/social-media", { data: { ids: socialMediaIdList } })
    return res
  } catch (err) {
    return err
  }
}
