import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import {
  setBreadcrumbItems,
  getAllParentFaqs,
  updateParentFAQ,
  deleteFaq,
  updateChildFaq,
} from "store/actions"
import FaqSettingModal from "./FaqSettingModal"
import classNames from "classnames"
// import { FAQ_CATEGORY } from "helpers/constants/enum.constants.helper"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { getUserPreference } from "helpers/utils.helper"
import FAQTabBody from "./FAQTabBody"
import { useHistory } from "react-router-dom"
import { FAQS_MESSAGES } from "helpers/constants/messages.constants"

// const breadcrumbItems = [
//   { title: BREADCRUM.POLICY_LEADER, link: "/" },
//   { title: BREADCRUM.FAQS, link: "/" },
// ]

const FAQTabs = [
  {
    value: "Featured",
    id: 1,
  },
  {
    value: "Active/In-active",
    id: 2,
  },
]

const FAQs = props => {
  const {
    getAllParentFaqs,
    setBreadcrumbItems,
    parentFAQMetadata,
    parentFAQList,
    getParentLoader,
    updateParentLoader,
    updateParentFAQ,
    deleteFaq,
    deleteFAQLoader,
    updateChildFaq,
  } = props
  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSettingModal, setShowSettingModal] = useState(false)
  const [showFeatureModal, setShowFeatureModal] = useState(false)
  const [activeTab, setActiveTab] = useState(
    history.location.search.includes(ROUTES_PATH.FEATURED) ? 1 : 2
  )
  const [currentPage, setCurrentPage] = useState(0)
  const [currentPageOnFeaturedTab, setCurrentPageOnFeaturedTab] = useState(0)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [searchText, setSearchText] = useState("")
  const getParentFAQListParams = () => ({
    pageNum: activeTab === 1 ? currentPageOnFeaturedTab : currentPage,
    recordLimit: recordLimit,
    is_featured: activeTab === 1 ? true : false,
  })

  const updateFAQsStatus = (id, status, cb) => {
    const params = getParentFAQListParams()
    const callback = () => {
      cb()
      setcurrentSelectedParentFAQ({
        ...currentSelectedParentFAQ,
        FaqLanguage: currentSelectedParentFAQ.FaqLanguage.map(faq => {
          return faq.id === id ? { ...faq, status } : faq
        }),
      })
    }
    updateChildFaq(
      {
        faqLangId: id,
        changes: {
          status,
        },
      },
      callback,
      searchText
        ? {
            ...params,
            searchText: searchText,
          }
        : getParentFAQListParams()
    )
  }

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.FAQS)
  }, [])
  useEffect(() => {
    setSearchText("")
  }, [activeTab])
  useEffect(() => {
    let timeout
    if (searchText) {
      const params = getParentFAQListParams()
      timeout = setTimeout(() => {
        getAllParentFaqs({
          ...params,
          searchText: searchText,
        })
      }, 500)
    } else {
      getAllParentFaqs(getParentFAQListParams())
    }

    if (
      history.location.search.includes(ROUTES_PATH.FEATURED) &&
      activeTab === 2
    )
      history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}`)
    return () => clearTimeout(timeout)
  }, [
    currentPage,
    recordLimit,
    activeTab,
    currentPageOnFeaturedTab,
    searchText,
  ])

  // Selected faq features
  const [currentSelectedParentFAQ, setcurrentSelectedParentFAQ] = useState(null)
  useEffect(() => {
    if (!showDeleteModal) setcurrentSelectedParentFAQ(null)
  }, [showDeleteModal])
  useEffect(() => {
    if (!showFeatureModal) setcurrentSelectedParentFAQ(null)
  }, [showFeatureModal])
  useEffect(() => {
    if (!showSettingModal) setcurrentSelectedParentFAQ(null)
  }, [showSettingModal])
  const filterFaq = deletedFaqID => {
    getAllParentFaqs(getParentFAQListParams())
    const filteredFaqs = currentSelectedParentFAQ?.FaqLanguage.filter(
      faq => faq.id !== deletedFaqID
    )
    setcurrentSelectedParentFAQ({
      ...currentSelectedParentFAQ,
      FaqLanguage: filteredFaqs,
    })
    if (!filteredFaqs.length) {
      setShowSettingModal(false)
    }
  }
  const getFaqs = currentPageOfSeletedTab => {
    if (currentPageOfSeletedTab >= 1) {
      activeTab === 1
        ? setCurrentPageOnFeaturedTab(currentPageOnFeaturedTab - 1)
        : setCurrentPage(currentPage - 1)
      getAllParentFaqs({
        pageNum:
          activeTab === 1 ? currentPageOnFeaturedTab - 1 : currentPage - 1,
        recordLimit: recordLimit,
        isfeatured: activeTab === 1 ? true : false,
      })
    } else getAllParentFaqs(getParentFAQListParams())
  }
  return (
    <>
      <Nav tabs>
        {FAQTabs.map((item, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeTab === item.id,
                })}
                onClick={() => {
                  setActiveTab(item.id)
                }}
              >
                {item.value}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted bg-white">
        <TabPane tabId={1}>
          <FAQTabBody
            getParentLoader={getParentLoader}
            parentFAQList={parentFAQList}
            setShowFeatureModal={setShowFeatureModal}
            setShowDeleteModal={setShowDeleteModal}
            setShowSettingModal={setShowSettingModal}
            parentFAQMetadata={parentFAQMetadata}
            currentPage={currentPage}
            currentPageOnFeaturedTab={currentPageOnFeaturedTab}
            setCurrentPage={setCurrentPage}
            setCurrentPageOnFeaturedTab={setCurrentPageOnFeaturedTab}
            recordLimit={recordLimit}
            setRecordLimit={setRecordLimit}
            isFeatureTab={true}
            setcurrentSelectedParentFAQ={setcurrentSelectedParentFAQ}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </TabPane>
        <TabPane tabId={2}>
          <FAQTabBody
            getParentLoader={getParentLoader}
            parentFAQList={parentFAQList}
            setShowFeatureModal={setShowFeatureModal}
            setShowDeleteModal={setShowDeleteModal}
            setShowSettingModal={setShowSettingModal}
            parentFAQMetadata={parentFAQMetadata}
            currentPage={currentPage}
            currentPageOnFeaturedTab={currentPageOnFeaturedTab}
            setCurrentPage={setCurrentPage}
            recordLimit={recordLimit}
            setRecordLimit={setRecordLimit}
            isFeatureTab={false}
            setcurrentSelectedParentFAQ={setcurrentSelectedParentFAQ}
            setCurrentPageOnFeaturedTab={setCurrentPageOnFeaturedTab}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </TabPane>
      </TabContent>
      <FaqSettingModal
        filterFaq={filterFaq}
        modalHeaderText="FAQs Settings"
        modalShow={showSettingModal}
        setModalShow={setShowSettingModal}
        currentSelectedParentFAQ={currentSelectedParentFAQ}
        updateChildFaq={updateFAQsStatus}
        isFeatureTab={activeTab === 1 ? true : false}
      />
      <ConfirmationModal
        confirmBtnText="Delete"
        modalHeaderText={FAQS_MESSAGES.DELETE_PARENT}
        confirmAction={() => {
          deleteFaq(currentSelectedParentFAQ.id, () => {
            setShowDeleteModal(false)
            parentFAQList.length === 1
              ? getFaqs(
                  activeTab === 1 ? currentPageOnFeaturedTab : currentPage
                )
              : getAllParentFaqs(getParentFAQListParams())
          })
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={deleteFAQLoader}
      />
      <ConfirmationModal
        confirmBtnText={activeTab === 1 ? "Remove" : "Add to Featured"}
        modalHeaderText={
          activeTab === 1
            ? FAQS_MESSAGES.REMOVE_FROM_FEATURED
            : "You are about to add FAQ items to Featured, Are you sure?"
        }
        confirmAction={() => {
          updateParentFAQ(
            {
              faqId: currentSelectedParentFAQ.id,
              changes: {
                is_featured: activeTab === 1 ? false : true,
              },
            },
            () => {
              setShowFeatureModal(false)
              parentFAQList.length === 1
                ? getFaqs(
                    activeTab === 1 ? currentPageOnFeaturedTab : currentPage
                  )
                : getAllParentFaqs(getParentFAQListParams())
            }
          )
        }}
        modalShow={showFeatureModal}
        setModalShow={setShowFeatureModal}
        loading={updateParentLoader}
      />
    </>
  )
}

const mapStatetoProps = state => {
  const { Faq } = state
  const {
    parentFAQList,
    parentFAQMetadata,
    getParentLoader,
    updateParentLoader,
    deleteFAQLoader,
  } = Faq

  return {
    parentFAQList,
    parentFAQMetadata,
    getParentLoader,
    updateParentLoader,
    deleteFAQLoader,
  }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getAllParentFaqs,
  updateParentFAQ,
  deleteFaq,
  updateChildFaq,
})(FAQs)
