import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  getAllBimabhai,
  deleteBimabhaiVideo,
  getBimabhai,
  updateBimabhaiVideo,
  checkAssociations,
} from "store/actions"
import { BREADCRUM } from "helpers/constants/constants.helper"
import { getUserPreference } from "helpers/utils.helper"
import ConfirmationModal from "components/modal/ConfirmationModal"
import BimabhaiParentList from "./BimabhaiParentList"
import BimabhaiSettingModal from "./BimabhaiSettingModal"
import { BIMA_BHAI_VIDEOS_MESSAGES } from "helpers/constants/messages.constants"

const bimaBhaiTableTableHead = [
  { value: "Title", id: "id", classNames: "text-start" },
  { value: "Category", id: "name", style: { width: "10rem" } },
  { value: "Languages", id: "name", style: { width: "18rem" } },
]

const BimaBhaiVideos = props => {
  const {
    getAllBimabhai,
    setBreadcrumbItems,
    loaderBimaBhai,
    bimabhaiVideoList,
    bimaBhaiMetadata,
    deleteBimabhaiVideo,
    deleteLoading,
    updateBimabhaiVideo,
    checkAssociations,
  } = props

  const [currentPage, setCurrentPage] = useState(0)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [selectedBimabhai, setSelectedBimabhai] = useState(null)
  const [settingModal, setSettingModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteSelectedId, setDeleteSelectedId] = useState(null)
  const [modalAlertList, setModalAlertList] = useState(false)
  const [searchText, setSearchText] = useState("")
  // TODO: for the future tag filter
  // const tagsList = []

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.BIMA_BHAI_VIDEOS)
  }, [])

  useEffect(() => {
    let timeout
    if (searchText) {
      timeout = setTimeout(() => {
        getAllBimabhai({
          pageNum: currentPage,
          recordLimit: recordLimit,
          searchText: searchText,
        })
      }, 500)
    } else {
      getAllBimabhai({
        pageNum: currentPage,
        recordLimit: recordLimit,
      })
    }
    return () => clearTimeout(timeout)
  }, [currentPage, recordLimit, searchText])

  useEffect(() => {
    if (!showDeleteModal && !settingModal) {
      setSelectedBimabhai(null)
    }
  }, [showDeleteModal, settingModal])

  const getParams = (currentPage, recordLimit) => ({
    pageNum: currentPage,
    recordLimit: recordLimit,
  })

  const handleStatusChange = (childVideoId, status, cb) => {
    const callback = () => {
      setSelectedBimabhai({
        ...selectedBimabhai,
        bima_bhai_by_languages: selectedBimabhai.bima_bhai_by_languages.map(
          childVideo => {
            return childVideo.id === childVideoId
              ? { ...childVideo, status }
              : childVideo
          }
        ),
      })
      cb()
    }
    updateBimabhaiVideo(
      childVideoId,
      { status },
      callback,
      getParams(currentPage, recordLimit)
    )
  }

  const handleDeleteClick = childId => {
    checkAssociations(childId, res => {
      if (res?.length) {
        setDeleteSelectedId(null)
        setModalAlertList(res)
      } else {
        setDeleteSelectedId(childId)
        setModalAlertList([])
      }
    })
  }

  const handleConfirmClick = childId => {
    deleteBimabhaiVideo(
      [childId],
      () => {
        const filterdVideosList =
          selectedBimabhai?.bima_bhai_by_languages?.filter(
            item => item.id !== childId
          )
        if (filterdVideosList.length) {
          setSelectedBimabhai({
            ...selectedBimabhai,
            bima_bhai_by_languages: filterdVideosList,
          })
        } else {
          setSettingModal(false)
        }
      },
      true,
      getParams(currentPage, recordLimit)
    )
    setDeleteSelectedId(null)
  }

  const getVideoList = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1)
      getAllBimabhai({
        pageNum: currentPage - 1,
        recordLimit: recordLimit,
      })
    } else {
      getAllBimabhai({
        pageNum: currentPage,
        recordLimit: recordLimit,
      })
    }
  }

  return (
    <>
      <BimabhaiParentList
        bimaBhaiTableTableHead={bimaBhaiTableTableHead}
        loaderBimaBhai={loaderBimaBhai}
        bimabhaiVideoList={bimabhaiVideoList}
        setSelectedBimabhai={setSelectedBimabhai}
        setShowDeleteModal={setShowDeleteModal}
        bimaBhaiMetadata={bimaBhaiMetadata}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordLimit={recordLimit}
        setRecordLimit={setRecordLimit}
        setSettingModal={setSettingModal}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <BimabhaiSettingModal
        modalHeaderText="Raksha Video"
        modalShow={settingModal}
        setModalShow={setSettingModal}
        selectedBimabhai={selectedBimabhai}
        handleStatusChange={handleStatusChange}
        handleConfirmClick={handleConfirmClick}
        handleDeleteClick={handleDeleteClick}
        deleteSelectedId={deleteSelectedId}
        setDeleteSelectedId={setDeleteSelectedId}
        modalAlertList={modalAlertList}
        setModalAlertList={setModalAlertList}
      />
      <ConfirmationModal
        confirmBtnText="Delete"
        modalHeaderText={BIMA_BHAI_VIDEOS_MESSAGES.DELETE_PARENT}
        confirmAction={() => {
          deleteBimabhaiVideo([selectedBimabhai.id], () => {
            setShowDeleteModal(false)
            bimabhaiVideoList.length === 1
              ? getVideoList()
              : getAllBimabhai({
                  pageNum: currentPage,
                  recordLimit: recordLimit,
                })
          })
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={deleteLoading}
      />
    </>
  )
}

// TODO: for the future tag filter
// const TagTabs = ({ tagArray }) => {
//   return (
//     <div>
//       {tagArray.map((tagObj, i) => (
//         <TabPill
//           key={i}
//           status={tagObj.status}
//           label={tagObj.label}
//           action={() => console.log("clicked tag", tagObj.label)}
//         />
//       ))}
//     </div>
//   )
// }

const mapStateToProps = state => {
  const { Language, BimaBhaiVideos } = state
  const { currentLanguageCode } = Language
  const {
    loading: loaderBimaBhai,
    bimabhaiVideoList,
    metadata: bimaBhaiMetadata,
    deleteLoading,
  } = BimaBhaiVideos
  return {
    currentLanguageCode,
    loaderBimaBhai,
    bimabhaiVideoList,
    bimaBhaiMetadata,
    deleteLoading,
  }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  getAllBimabhai,
  deleteBimabhaiVideo,
  getBimabhai,
  updateBimabhaiVideo,
  checkAssociations,
})(BimaBhaiVideos)
