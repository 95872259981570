import {
  GET_ALL_PRIVACY,
  GET_ALL_PRIVACY_ERROR,
  GET_ALL_PRIVACY_SUCCESS,
  GET_PRIVACY,
  GET_PRIVACY_ERROR,
  GET_PRIVACY_SUCCESS,
  ADD_PRIVACY,
  ADD_PRIVACY_SUCCESS,
  ADD_PRIVACY_ERROR,
  EDIT_PRIVACY,
  EDIT_PRIVACY_SUCCESS,
  EDIT_PRIVACY_ERROR,
  DELETE_PRIVACY,
  DELETE_PRIVACY_SUCCESS,
  DELETE_PRIVACY_ERROR,
} from "./actionTypes"

export const getAllPrivacy = params => {
  return {
    type: GET_ALL_PRIVACY,
    payload: params,
  }
}

export const getAllPrivacySuccess = params => {
  return {
    type: GET_ALL_PRIVACY_SUCCESS,
    payload: params,
  }
}

export const getAllPrivacyError = error => {
  return {
    type: GET_ALL_PRIVACY_ERROR,
    payload: error,
  }
}

export const getPrivacy = params => {
  return {
    type: GET_PRIVACY,
    payload: params,
  }
}

export const getPrivacySuccess = params => {
  return {
    type: GET_PRIVACY_SUCCESS,
    payload: params,
  }
}

export const getPrivacyError = error => {
  return {
    type: GET_PRIVACY_ERROR,
    payload: error,
  }
}

export const addPrivacy = params => {
  return {
    type: ADD_PRIVACY,
    payload: params,
  }
}

export const addPrivacySuccess = params => {
  return {
    type: ADD_PRIVACY_SUCCESS,
    payload: params,
  }
}

export const addPrivacyError = error => {
  return {
    type: ADD_PRIVACY_ERROR,
    payload: error,
  }
}

export const editPrivacy = params => {
  return {
    type: EDIT_PRIVACY,
    payload: params,
  }
}

export const editPrivacySuccess = params => {
  return {
    type: EDIT_PRIVACY_SUCCESS,
    payload: params,
  }
}

export const editPrivacyError = error => {
  return {
    type: EDIT_PRIVACY_ERROR,
    payload: error,
  }
}

export const deletePrivacy = ({ data, cb }) => {
  return {
    type: DELETE_PRIVACY,
    payload: data,
    cb,
  }
}

export const deletePrivacySuccess = params => {
  return {
    type: DELETE_PRIVACY_SUCCESS,
    payload: params,
  }
}

export const deletePrivacyError = error => {
  return {
    type: DELETE_PRIVACY_ERROR,
    payload: error,
  }
}
