import axios from "axios"
import {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  setRefreshToken,
  setUserInfo,
  userLogoutActions,
} from "./utils.helper"
import { toast } from "react-toastify"
import { ROUTES_PATH } from "./constants/constants.helper"
import { API_CODE_MESSAGES } from "./constants/messages.constants"

//apply base url for axios
// const API_URL = "https://uat-admin-api.policyleader.co.in/v1"
// const API_URL = "http://192.168.1.60:8080/v1"
let retry = true

const axiosApi = axios.create({
  baseURL: process?.env?.REACT_APP_BAKEND_API,
  // baseURL: API_URL,
  timeout: 5000,
})

axiosApi.interceptors.request.use(
  config => {
    const token = getAuthToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalConfig = error.config
    if (!navigator.onLine || !error?.response) {
      toast.error(API_CODE_MESSAGES.OFFLINE, {
        toastId: "NOINTERNET",
      })
    } else if (error?.code === "ECONNABORTED") {
      toast.error(API_CODE_MESSAGES.ECONNABORTED, {
        toastId: "server-limit",
      })
    } else if (error.response.status === 401) {
      if (retry) {
        retry = false
        try {
          const rs = await axios.post(
            `${process?.env?.REACT_APP_BAKEND_API}/user/refresh`,
            {
              refresh_token: getRefreshToken(),
            }
          )
          const { access_token, refresh_token } = rs.data
          setAuthToken(access_token)
          setRefreshToken(refresh_token)
          setUserInfo(rs?.data)
          return axiosApi(originalConfig)
        } catch (_error) {
          toast.error(API_CODE_MESSAGES[401])
          userLogoutActions()
          window.location = `/${ROUTES_PATH.LOGIN}`
          return Promise.reject(_error)
        }
      } else {
        userLogoutActions()
        window.location = `/${ROUTES_PATH.LOGIN}`
      }
    } else if (error.response.status === 400) {
      toast.error(error.response?.data?.message || API_CODE_MESSAGES[400], {
        toastId: "error400",
      })
    }
    if (error.response.status === 404) {
      toast.error(error.response?.data?.message || API_CODE_MESSAGES[404], {
        toastId: "error404",
      })
    } else if (error.response.status === 406) {
      toast.error(error.response?.data?.message || API_CODE_MESSAGES[406], {
        toastId: "error406",
      })
    } else if (error.response.status === 409) {
      toast.error(error.response?.data?.message || API_CODE_MESSAGES[409], {
        toastId: "error409",
      })
    } else if (error.response.status === 500) {
      toast.error(error.response?.data?.message || API_CODE_MESSAGES[500], {
        toastId: "error500",
      })
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    return response.data
  })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export default axiosApi
