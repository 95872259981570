export const CATEGORY_ENUM = {
  CAR: "fd1c35d0-70a3-410d-889b-5cefe63fdc16",
  BIKE: "8dc48bc3-d4cb-4d06-a700-46b1a52bd830",
  HEALTH: "fdc370ce-ee2d-4dd5-be66-aca112146317",
}
export const FAQ_CATEGORY = [
  { id: "15766530-0696-4542-830a-d49970838601", name: "Two Wheeler" },
  { id: "15766530-0696-4542-830a-d49970838602", name: "Car" },
  { id: "15766530-0696-4542-830a-d49970838603", name: "Health" },
  { id: "15766530-0696-4542-830a-d49970838604", name: "General" },
]

export const BLOG_CATEGORY_ARRAY = [
  { id: "15766530-0691-4242-830a-d40070838602", name: "Car" },
  { id: "15766530-0691-4242-830a-d40070838603", name: "Health" },
  { id: "15766530-0691-4242-830a-d40070838604", name: "Two Wheeler" },
  { id: "15766530-0691-4242-830a-d40070838605", name: "Other" },
]

export const BLOG_CATEGORY_OBJ = {
  CAR: BLOG_CATEGORY_ARRAY[0],
  HEALTH: BLOG_CATEGORY_ARRAY[1],
  TWO_WHEELER: BLOG_CATEGORY_ARRAY[2],
  OTHER: BLOG_CATEGORY_ARRAY[3],
}

export const ITEM_STATUS = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "In-Active",
    value: false,
  },
]
