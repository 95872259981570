import {
  ADMIN_USER_DATA,
  CMS_USER_DATA,
  CRM_USER_DATA,
  ROUTES_PATH,
  SUPER_ADMIN_USER_DATA,
} from "helpers/constants/constants.helper"
import { useSelector } from "react-redux"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems, logoutUser } from "../../store/actions"

const Dashboard = () => {
  const role = useSelector(({ Login }) => Login?.userInfo?.roleName)

  switch (role) {
    case CMS_USER_DATA.role:
      return <Redirect to={`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.HOME}`} />
    case CRM_USER_DATA.role:
      return <Redirect to={`/${ROUTES_PATH.LEADS}`} />
    case ADMIN_USER_DATA.role:
      return <Redirect to={`/${ROUTES_PATH.LEADS}`} />
    case SUPER_ADMIN_USER_DATA.role:
      return <Redirect to={`/${ROUTES_PATH.LEADS}`} />

    default:
      return <Redirect to={`/${ROUTES_PATH.LOGIN}`} />
  }

  // const breadcrumbItems = [
  //   { title: "KIBPL", link: "#" },
  //   { title: "Dashboard", link: "#" },
  // ]
  // useEffect(() => {
  //   props.setBreadcrumbItems("Dashboard", breadcrumbItems)
  // })
  // return (
  //   <React.Fragment>
  //     <MetaTags>
  //       <title>Dashboard | KIBPL CMS Dashboard</title>
  //     </MetaTags>
  //   </React.Fragment>
  // )
}

export default connect(null, { setBreadcrumbItems, logoutUser })(Dashboard)
