import FormButton from "components/form-components/FormButton"
import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Modal } from "reactstrap"
import * as Yup from "yup"
import {
  setBreadcrumbItems,
  addSocialMedia,
  getAllSocialMedia,
  updateSocialMedia,
} from "store/actions"
import { toast } from "react-toastify"
import { SOCIAL_MEDIA } from "helpers/constants/labels.constants"
const initialFormData = {
  name: "",
  web_link: "",
}

const socialMediaFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required !!"),
  web_link: Yup.string().required("Required !!"),
})

const SocialMediaEditFormModal = props => {
  const {
    loading,
    getAllSocialMedia,
    updateSocialMedia,
    modalHeaderText,
    modalShow,
    setModalShow,
    currentSelectedData,
    currentSocialMediaId,
  } = props

  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => {
    if (currentSelectedData) {
      setFormData(currentSelectedData)
    }
  }, [currentSelectedData])

  const handleSocialMediaFormSubmit = values => {
    currentSocialMediaId
      ? updateSocialMedia(currentSocialMediaId, { ...values }, () => {
          setModalShow(false)
          getAllSocialMedia()
        })
      : toast.error("Id not found")
  }

  const { WEBSITE_LINK, NAME } = SOCIAL_MEDIA.SOCIAL_MEDIA_FORM

  // const handleSubmitSuccess = () =>
  //   history?.push(
  //     `/${ROUTES_PATH.GENERAL_SECTION}/${ROUTES_PATH.SOCIAL_MEDIA_LINK}`
  //   )

  return (
    <Modal isOpen={modalShow} centered={true} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalHeaderText}</h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={formData}
          validationSchema={socialMediaFormValidationSchema}
          onSubmit={values => handleSocialMediaFormSubmit(values)}
          enableReinitialize
        >
          {({ handleSubmit, values, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="form-horizontal">
              <div className="row">
                <div className="mb-4">
                  <label htmlFor="name">{NAME}</label>
                  <Field
                    name="name"
                    className="form-control"
                    disabled={values.name ? true : false}
                  />
                  {errors.name && touched.name ? (
                    <div className="position-absolute text-danger small ms-1">
                      {errors.name}
                    </div>
                  ) : null}
                </div>
                <div className=" mb-4">
                  <label htmlFor="name">{WEBSITE_LINK}</label>
                  <Field name="web_link" className="form-control" />
                  {errors.web_link && touched.web_link ? (
                    <div className="position-absolute text-danger small ms-1">
                      {errors.web_link}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-between gap-3">
                <div className="w-50">
                  <FormButton
                    customClass="btn-dark"
                    loading={loading}
                    type="submit"
                    btnText="Update Link"
                  />
                </div>
                <div className="w-50">
                <FormButton
                    customClass="btn-danger"
                    btnText="Cancel"
                    clickEvent={() => setModalShow(false)}
                />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => {
  const { Language, SocialMedia } = state
  const { currentLanguageCode } = Language
  const { loading } = SocialMedia
  return { currentLanguageCode, loading }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  addSocialMedia,
  getAllSocialMedia,
  updateSocialMedia,
})(SocialMediaEditFormModal)
