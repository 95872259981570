import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import {
  setBreadcrumbItems,
  addInsurer,
  getInsurer,
  updateInsurer,
} from "store/actions"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import InsurerForm from "./InsurerForm"
import * as Yup from "yup"
const AddInsurerBreadcrumbItems = [{ title: BREADCRUM.ADD_INSURER, link: "/" }]
const EditInsurerBreadcrumbItems = [
  { title: BREADCRUM.EDIT_INSURER, link: "/" },
]
const initalFormValues = {
  name: "",
  email: "",
  phone: "",
  health_phone: "",
  url: "",
}
const InsurerValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Name must be 5 character long ")
    .required("Insurer Name required !!!"),
  email: Yup.string().email("Invalid Email").required("Email required !!!"),
  phone: Yup.string()
    .matches(/^\d+(,\d+)*$/, "Invalid Phone Number")
    .required("Phone Number required !!!"),
  health_phone: Yup.string()
    .matches(/^\d+(,\d+)*$/, "Invalid Phone Number")
    .required("Phone Number required !!!"),
  url: Yup.string().url().required("Website is required !!!"),
})
const InsurerAdd = ({
  setBreadcrumbItems,
  insurerLoading,
  addInsurer,
  getInsurer,
  updateInsurer,
}) => {
  const { insurerId } = useParams()
  const [formValues, setFormValues] = useState(initalFormValues)
  const history = useHistory()
  const setInsurerDetails = data => {
    setFormValues({
      name: data.name,
      email: data.email,
      phone: data.phone,
      health_phone: data.health_phone,
      url: data.url,
    })
  }
  useEffect(() => {
    if (insurerId) {
      setBreadcrumbItems(
        {
          title: BREADCRUM.INSURER,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}`,
        },
        EditInsurerBreadcrumbItems
      )
      getInsurer(insurerId, setInsurerDetails)
    } else {
      setBreadcrumbItems(
        {
          title: BREADCRUM.INSURER,
          pageTitleLink: `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}`,
        },
        AddInsurerBreadcrumbItems
      )
    }
  }, [])
  const onSubmit = values => {
    if (insurerId) {
      updateInsurer({ insurerId, changes: values }, () => {
        history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}`)
      })
    } else {
      addInsurer(values, () => {
        history.push(`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}`)
      })
    }
  }
  return (
    <>
      <InsurerForm
        formValues={formValues}
        onSubmit={onSubmit}
        insurerLoading={insurerLoading}
        insurerId={insurerId}
        InsurerValidationSchema={InsurerValidationSchema}
      />
    </>
  )
}

const mapStatetoProps = state => {
  const { insurerLoading } = state.Insurer
  return { insurerLoading }
}
export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  addInsurer,
  getInsurer,
  updateInsurer,
})(InsurerAdd)
