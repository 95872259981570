import {
  ADD_CONTACT_LOADING,
  DELETE_CONTACT_LOADING,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  LIST_CONTACT_LOADING,
  SET_LOADING_CONTACTS,
  UPDATE_CONTACT_LOADING,
} from "./actionTypes"

const initialState = {
  contacts: [],
  metadata: {},
  [LIST_CONTACT_LOADING]: false,
  [ADD_CONTACT_LOADING]: false,
  [UPDATE_CONTACT_LOADING]: false,
  [DELETE_CONTACT_LOADING]: false,
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTACTS:
      state = {
        ...state,
        contacts: [],
        metadata: {},
      }
      break
    case GET_ALL_CONTACTS_SUCCESS:
      state = {
        ...state,
        contacts: action.payload?.data,
        metadata: action.payload?.metadata,
      }
      break
    case SET_LOADING_CONTACTS:
      state = {
        ...state,
        [action.payload?.loading_key]: action.payload?.loading_value,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default contactReducer
