import React from "react"
import styles from "./tabPill.module.css"

const TabPill = ({ status, label, action = () => {}, classNames = "" }) => {
  const { tab_pill, active } = styles
  return (
    <div
      className={`${tab_pill} ${status ? active : null} ${classNames}`}
      onClick={action}
    >
      {label}
    </div>
  )
}

export default TabPill
