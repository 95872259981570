import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_LANGUAGE, GET_ALL_LANGUAGES } from "./actionTypes"
import { getAllLanguagesError, getAllLanguagesSuccess } from "./actions"
import { addLanguage, getallLanguages } from "./httpCalls.language"
import { toast } from "react-toastify"

function* GetAllLanguages({ payload }) {
  try {
    const response = yield call(getallLanguages, {
      payload,
    })
    if (response?.success) {
      yield put(getAllLanguagesSuccess(response))
    } else {
      yield put(getAllLanguagesError(response))
      // toast.error(response.message)
    }
  } catch (error) {
    console.log(error)
  }
}

function* AddLanguage({ payload }) {
  const { data, cb } = payload
  try {
    const response = yield call(addLanguage, data)
    if (response.success) {
      toast.success(response.message)
    }
    cb(response.success)
  } catch (error) {
    cb(false)
    console.log(error, "error")
  }
}

function* languageSaga() {
  yield takeEvery(GET_ALL_LANGUAGES, GetAllLanguages)
  yield takeEvery(ADD_LANGUAGE, AddLanguage)
}

export default languageSaga
