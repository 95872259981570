import React from "react"
import Pagination from "./Pagination"
import ShowEntries from "./ShowEntries"
import { PAGE_LIMIT } from "helpers/constants/constants.helper"
import { getUserPreference, setUserPreference } from "helpers/utils.helper"

const TableFooter = ({
  metadata,
  setrecordLimit,
  recordLimit,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div className="d-flex gap-3 align-items-center">
        <ShowEntries
          currentPage={currentPage}
          recordLimit={recordLimit}
          metadata={metadata}
        />
        <select
          name="page_limit"
          id="page_limit"
          defaultValue={getUserPreference().recordLimit}
          onChange={e => {
            setUserPreference({ recordLimit: Number(e.target.value) })
            setCurrentPage(0)
            setrecordLimit(getUserPreference().recordLimit)
          }}
          className="select_page_limit cursor-pointer"
        >
          {PAGE_LIMIT.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <option value={item.value}>{item.lable}</option>
              </React.Fragment>
            )
          })}
        </select>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        metadata={metadata}
      />
    </div>
  )
}

export default TableFooter
