export const GET_ALL_PRIVACY = "GET_ALL_PRIVACY"
export const GET_ALL_PRIVACY_SUCCESS = "GET_ALL_PRIVACY_SUCCESS"
export const GET_ALL_PRIVACY_ERROR = "GET_ALL_PRIVACY_ERROR"

export const GET_PRIVACY = "GET_PRIVACY"
export const GET_PRIVACY_SUCCESS = "GET_PRIVACY_SUCCESS"
export const GET_PRIVACY_ERROR = "GET_PRIVACY_ERROR"

export const ADD_PRIVACY = "ADD_PRIVACY"
export const ADD_PRIVACY_SUCCESS = "ADD_PRIVACY_SUCCESS"
export const ADD_PRIVACY_ERROR = "ADD_PRIVACY_ERROR"

export const EDIT_PRIVACY = "EDIT_PRIVACY"
export const EDIT_PRIVACY_SUCCESS = "EDIT_PRIVACY_SUCCESS"
export const EDIT_PRIVACY_ERROR = "EDIT_PRIVACY_ERROR"

export const DELETE_PRIVACY = "DELETE_PRIVACY"
export const DELETE_PRIVACY_SUCCESS = "DELETE_PRIVACY_SUCCESS"
export const DELETE_PRIVACY_ERROR = "DELETE_PRIVACY_ERROR"
