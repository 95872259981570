import TabPill from "components/common/tab-pill"
import SwitchCheckbox from "components/form-components/SwitchCheckbox"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { FaRegEdit } from "react-icons/fa"
import { MdDelete, MdClose, MdCheck } from "react-icons/md"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Modal,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import styles from "./blog.module.css"
import { IoChevronDown } from "react-icons/io5"

const blogTableTableHead = [
  {
    value: "Title",
    id: "id",
    classNames: "text-start",
    style: { minWidth: "10rem" },
  },
  { value: "Language", id: "language", style: { minWidth: "8rem" } },
  { value: "Status", id: "date", style: { minWidth: "6rem" } },
]

const BlogSettingModal = props => {
  const { vertical_align_middle } = styles
  const {
    modalHeaderText,
    modalShow,
    currentSelectedBlog,
    setModalShow,
    handleDelete,
    handleStatusChange,
    languages,
  } = props

  const history = useHistory()
  const [singlebtn, setSinglebtn] = useState(false)
  const [selectedBlogId, setSelectedBlogId] = useState(null)
  const remainingLanguages = languages.filter(language => {
    return !currentSelectedBlog?.BlogLanguage.some(
      blog => blog.language_code === language.language_code
    )
  })
  useEffect(() => {
    if (!currentSelectedBlog?.id) setModalShow(false)
  }, [])

  return (
    <Modal isOpen={modalShow} centered={true} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalHeaderText}</h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflowX: "scroll" }}>
        {currentSelectedBlog?.id ? (
          <>
            <div className="info-container mb-2">
              <div className="row">
                <div className="col-6">
                  <div className="row mb-1">
                    {/* <div className="col-6 mb-3">Blog Catagory:</div>
                    <div className="col-4">
                      <TabPill
                        classNames="mb-0 w-100"
                        label={currentSelectedBlog.master_blog_categories.name}
                        status={true}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-4 ">
                  {remainingLanguages.length ? (
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                      className=""
                    >
                      <DropdownToggle className="btn btn-dark w-100" caret>
                        Add Blog <IoChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        {remainingLanguages.map((language, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              history.push(
                                `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.ADD_BLOG}/${language.language_code}/${currentSelectedBlog?.id}`
                              )
                            }}
                          >
                            {language.name}
                            {` (${language.language_code})`}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="table-container">
              <div className="table-responsive">
                <Table className="table-striped mb-0">
                  <thead className="bg-dark text-white sticky">
                    <tr className="text-center">
                      {blogTableTableHead.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className={item.classNames || null}
                            style={item.style}
                          >
                            {item.value}
                          </th>
                        )
                      })}
                      <th className="action-tab">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-striped ">
                    {currentSelectedBlog?.BlogLanguage?.length ? (
                      currentSelectedBlog.BlogLanguage.map((item, index) => {
                        return (
                          <tr className={vertical_align_middle} key={index}>
                            <td>
                              <div
                                className="text-start text-truncate"
                                style={{ maxWidth: "20rem" }}
                              >
                                {item?.title}
                              </div>
                            </td>
                            <td>
                              <TabPill
                                classNames="mb-0"
                                label={item?.language_code}
                                status={item?.status}
                              />
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="form-check form-switch form-switch-md">
                                  <SwitchCheckbox
                                    status={item?.status}
                                    changeHandler={handleStatusChange}
                                    id={item.id}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <IconContainer
                                item={item}
                                handleDelete={handleDelete}
                                selectedBlogId={selectedBlogId}
                                setSelectedBlogId={setSelectedBlogId}
                                parentBlogId={currentSelectedBlog?.id}
                              />
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <h2 className="text-center">
                            No data available into the Blog list
                          </h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}

const IconContainer = props => {
  const {
    item,
    selectedBlogId,
    setSelectedBlogId,
    handleDelete,
    parentBlogId,
  } = props
  const history = useHistory()
  {
    return item.id === selectedBlogId ? (
      <div className="icon-container d-flex justify-content-around">
        <div className="ms-2">
          <MdCheck
            className="text-danger icons"
            id={`check-${item.language_code}`}
            onClick={() => {
              handleDelete(item.id, true)
            }}
          />
          <UncontrolledTooltip
            target={`check-${item.language_code}`}
            placement="bottom"
          >
            Confirm
          </UncontrolledTooltip>
        </div>
        <div className="ms-2">
          <MdClose
            className="text-secondary icons"
            id={`close-${item.language_code}`}
            onClick={() => {
              setSelectedBlogId(null)
            }}
          />
          {/* unexpected error occur */}
          {/* <UncontrolledTooltip
            target={`two-${item.language_code}`}
            placement="bottom"
          >
            close
          </UncontrolledTooltip> */}
        </div>
      </div>
    ) : (
      <div className="icon-container d-flex justify-content-around">
        <div className="ms-2">
          <FaRegEdit
            className="text-success icons"
            id={`edit-${item.language_code}`}
            onClick={() => {
              history.push(
                `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.BLOG}/${ROUTES_PATH.EDIT_BLOG}/${parentBlogId}/${item.id}`
              )
            }}
          />
          {/* i changed the location to line 281 then it  works well */}
          {/* <UncontrolledTooltip
            target={`edit-${item.language_code}`}
            placement="bottom"
          >
            Edit
          </UncontrolledTooltip> */}
        </div>
        <div className="ms-2">
          <MdDelete
            className="text-danger icons"
            id={`delete-${item.language_code}`}
            onClick={() => {
              setSelectedBlogId(item.id)
            }}
          />
          <UncontrolledTooltip
            target={`delete-${item.language_code}`}
            placement="bottom"
          >
            Delete
          </UncontrolledTooltip>
        </div>
        <UncontrolledTooltip
          target={`edit-${item.language_code}`}
          placement="bottom"
        >
          Edit
        </UncontrolledTooltip>
      </div>
    )
  }
}

const mapStatetoProps = state => {
  const { Language } = state
  const { languages } = Language
  return { languages }
}

const mapDispatchToProps = {}
export default connect(mapStatetoProps, mapDispatchToProps)(BlogSettingModal)
