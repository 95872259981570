import FormButton from "components/form-components/FormButton"
import CustomTableLoader from "components/loader/CustomTableLoader"
import React, { useEffect, useRef, useState } from "react"
import { Modal, Table } from "reactstrap"
import styles from "./Testimonial.module.css"

const SelectTestimonialModal = ({
  setModalShow,
  modalShow,
  modalHeaderText,
  selectFeatureList,
  getStars,
  setSelectedTestimonials,
  selectedTestimonials,
  addToFeature,
  loader,
  setCurrentPage,
  TestmonialMetadata,
  isfeatured,
  getAllTestimonialAPI,
}) => {
  const { select_testimonial_list } = styles
  const [testimonialList, setTestimonialList] = useState([])
  const trRef = useRef(null)

  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      if (trRef.current) setCurrentPage(page => page + 1)
    }
  }

  const observerOptions = {
    root: document.querySelector(".modal-body"),
    rootMargin: "0px 15px",
    threshold: 1.0,
  }
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, observerOptions)
    if (modalShow && isfeatured) {
      if (trRef.current) {
        observer.observe(trRef.current)
      }
    }
    return () => {
      observer.disconnect()
    }
  }, [testimonialList])

  useEffect(() => {
    if (modalShow && isfeatured) {
      getAllTestimonialAPI()
    }
  }, [])

  function AddSelectedTestimonial(parent) {
    setSelectedTestimonials(prevState => {
      if (prevState?.length) {
        if (
          prevState.some(prevTestimonial => prevTestimonial.id === parent.id)
        ) {
          let filteredArray = prevState.filter(
            prevTestimonial => prevTestimonial.id !== parent.id
          )
          return filteredArray
        }
        if (selectedTestimonials.length > 2) prevState.pop()
        return [...prevState, parent]
      } else {
        return [parent]
      }
    })
  }

  function checkSelectedTestimonial(id) {
    if (selectedTestimonials?.length) {
      return selectedTestimonials.some(testimonial => testimonial.id === id)
    } else {
      return false
    }
  }

  function checkSaveButtonDisableStatus() {
    return selectedTestimonials?.length === 3 ? false : true
  }

  function removeSelectedTestimonial(testimonialID) {
    setSelectedTestimonials(prevState => {
      if (prevState.length) {
        let uniqueTestimonial = prevState.filter(
          testimonial => testimonial.id != testimonialID
        )
        if (uniqueTestimonial?.length) return uniqueTestimonial
        return []
      }
    })
  }

  useEffect(() => {
    if (selectFeatureList?.length && isfeatured) {
      setTestimonialList(prevTestimonials => [
        ...prevTestimonials,
        ...selectFeatureList,
      ])
    }
  }, [selectFeatureList])

  useEffect(() => {
    if (!modalShow) {
      setTestimonialList([])
      setCurrentPage(0)
    }
  }, [modalShow])

  return (
    <Modal isOpen={modalShow} centered={true} size="xl">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalHeaderText}</h5>
      </div>
      <div className={`modal-body`}>
        <div className={`pb-2 ${styles.font_bold}`}>
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-3">
              <div
                className={`d-flex gap-2 align-items-center ${styles.selected_no}`}
              >
                selected <span>{selectedTestimonials?.length || 0}</span>
              </div>
              <div className={styles.selected_name_container}>
                {selectedTestimonials?.length
                  ? selectedTestimonials.map((testimonial, idx) => {
                      return (
                        <div
                          className={styles.selected_name_card}
                          key={idx}
                          onClick={() =>
                            removeSelectedTestimonial(testimonial.id)
                          }
                        >
                          {testimonial.label}{" "}
                          <span className="remove_name">x</span>
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
            <div>
              {/* <input
                name="search"
                id="search"
                className="form-control"
                type="text"
                placeholder="Search..."
                onChange={e => {
                  setTestimonialList([])
                  setCurrentPage(0)
                  setSearchText(e.target.value)
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className={styles.table_container}>
          <Table className="mb-0 text-center mb-3">
            <thead className={`bg-dark text-white ${styles.sticky}`}>
              <tr>
                <th>User Name</th>
                <th>Feedback</th>
                <th>Ratings</th>
              </tr>
            </thead>
            <tbody>
              {testimonialList?.length ? (
                testimonialList.map((parentObj, idx) => {
                  let isSelected = checkSelectedTestimonial(parentObj?.id)
                  return (
                    <tr
                      key={parentObj?.id || idx}
                      className={`${select_testimonial_list} ${
                        isSelected && selectedTestimonials?.length <= 3
                          ? styles.selected_testimonial
                          : ""
                      }`}
                      onClick={() => AddSelectedTestimonial(parentObj)}
                    >
                      <td>
                        <p>{parentObj?.label || "-"}</p>
                      </td>
                      <td
                        className="text-truncate"
                        style={{ maxWidth: "100px" }}
                      >
                        {parentObj?.testimonials?.length
                          ? parentObj?.testimonials[0]?.feedback
                          : "-"}
                      </td>
                      <td>{getStars(parentObj?.ratings)}</td>
                    </tr>
                  )
                })
              ) : loader ? null : (
                <tr>
                  <td colSpan={5}>
                    <h4>Add Testimonials in all languages</h4>
                  </td>
                </tr>
              )}
              {loader ? <CustomTableLoader col={3} /> : null}
              {modalShow &&
              TestmonialMetadata.totalRecords > testimonialList.length ? (
                <tr id="infinite-loading" ref={trRef} />
              ) : null}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="modal-footer">
        <div className="gap-3 d-flex">
          <FormButton
            customClass="px-2 text-white bg-danger"
            loading={false}
            disabled={checkSaveButtonDisableStatus()}
            type="submit"
            clickEvent={() => {
              addToFeature(selectedTestimonials, true)
            }}
            btnText="Save Testimonials"
          />
          <button
            className="btn text-white bg-dark"
            onClick={() => {
              setModalShow(false)
              setSelectedTestimonials([])
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SelectTestimonialModal
