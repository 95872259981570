import TabPill from "components/common/tab-pill"
import CustomTableLoader from "components/loader/CustomTableLoader"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { getRemainingLanguages } from "helpers/utils.helper"
import React from "react"
import { MdDelete, MdSettings, MdStarBorder, MdStarRate } from "react-icons/md"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Table, UncontrolledTooltip } from "reactstrap"
const BannerTableHead = [
  { value: "Title", id: "id", classNames: "text-start" },
  { value: "Languages", id: "Languages", classNames: "text-center", style: { width: "561px" } },
]
const ParentBannerListing = ({
  bannerList,
  isFeaturedTab,
  getParentLoader,
  setShowSettingModal,
  setCurrentSelectedParentBanner,
  setShowFeatureModal,
  setShowDeleteModal,
  languages,
}) => {
  const onFeaturedIconClicked = banner => {
    setCurrentSelectedParentBanner(banner)
    setShowFeatureModal(true)
  }
  return (
    <Table className="table-striped mb-0">
      <thead className="bg-dark text-white sticky">
        <tr className="text-center">
          {BannerTableHead.map((item, index) => {
            return (
              <th key={index} className={item.classNames || null} style={item.style}>
                {item.value}
              </th>
            )
          })}
          <th className="action-tab">Action</th>
        </tr>
      </thead>
      <tbody className="table-striped ">
        {getParentLoader ? (
          <CustomTableLoader col={3} />
        ) : bannerList.length ? (
          bannerList.map((banner, index) => (
            <tr key={index}>
              <td className="text-start">{banner.label}</td>
              <td>
                <LanguageTabs
                  languageItemArray={banner?.BannerByLanguages || []}
                  languages={languages}
                  parentBannerId={banner.id}
                />
              </td>
              <td
                className="icon-container"  
              >
                <div className=" justify-content-center">
                {isFeaturedTab ? (
                    <>
                      <MdStarRate
                        id={`featureIcon${index}${isFeaturedTab}`}
                        onClick={() => onFeaturedIconClicked(banner)}
                        className="text-success icons"
                      />
                      <UncontrolledTooltip
                        target={`featureIcon${index}${isFeaturedTab}`}
                      >
                        Remove from Featured
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <MdStarBorder id={`featureIcon${index}`}
                        onClick={() => onFeaturedIconClicked(banner)}
                        className=" text-success icons"/>
                      <UncontrolledTooltip target={`featureIcon${index}`}>
                        Move to Featured
                      </UncontrolledTooltip>
                    </>
                  )}
                  <MdSettings
                    id={`settingIcon${index}`}
                    className="text-success icons"
                    onClick={() => {
                      setCurrentSelectedParentBanner(banner)
                      setShowSettingModal(true)
                    }}
                  />
                  <UncontrolledTooltip target={`settingIcon${index}`}>
                    Settings
                  </UncontrolledTooltip>
                 
                  {isFeaturedTab ? null : (
                    <>
                      <MdDelete id={`deleteIcon${index}`}
                        onClick={() => {
                          setCurrentSelectedParentBanner(banner)
                          setShowDeleteModal(true)
                        }}
                        className="text-danger icons"/>
                      <UncontrolledTooltip target={`deleteIcon${index}`}>
                        Delete
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              <h3>No Banner Found</h3>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
const LanguageTabs = ({ languageItemArray, languages, parentBannerId }) => {
  const remainingLanguages = getRemainingLanguages(languages, languageItemArray)
  const history = useHistory()
  return (
    <div className="w-100 d-flex justify-content-center">
      {languageItemArray.map((languageItemObj, i) => (
        <TabPill
          key={i}
          status={languageItemObj.status}
          label={languageItemObj.language_code}
          action={() => {}}
          classNames="mb-0 cursor-default lang-tab"
        />
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code}+`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.ADD_BANNER}/${language.language_code}/${parentBannerId}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
    </div>
  )
}
const mapStatetoProps = state => {
  const { languages } = state.Language
  return { languages }
}
export default connect(mapStatetoProps, {})(ParentBannerListing)
