import { FAQ_URL } from "helpers/api-end-points.constant.helper"
import { del, get, patch, post } from "helpers/api.helper"

export async function getAllParentFaqs(params) {
  try {
    const res = await get(FAQ_URL, { params })
    return res
  } catch (err) {
    return err
  }
}
export async function getParentFaq(id) {
  try {
    const res = await get(`${FAQ_URL}/${id}`)
    return res
  } catch (err) {
    return err
  }
}
export async function updateParentFAQ(data) {
  try {
    const res = await patch(FAQ_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function addFaq(data) {
  try {
    const res = await post(FAQ_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function deleteFaq(faqId) {
  try {
    const res = await del(FAQ_URL, { data: { id: faqId } })
    return res
  } catch (err) {
    return err
  }
}

export async function getChildFaq(childFaqId) {
  try {
    const res = await get(FAQ_URL + "/" + childFaqId)
    return res
  } catch (err) {
    return err
  }
}

export async function updateChildFaq(data) {
  try {
    const res = await patch(FAQ_URL, data)
    return res
  } catch (err) {
    return err
  }
}
