import { getUserData, getUserInfo } from "helpers/utils.helper"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER_ERROR,
  SET_USER_DATA,
} from "./actionTypes"

const initialState = {
  userInfo: getUserInfo(),
  userData: getUserData(getUserInfo()?.roleName),
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload?.data,
        userData: getUserData(action.payload?.data?.roleName),
        loading: false,
      }

    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      }

    case LOGOUT_USER:
      return { ...state, loading: true }

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOGOUT_USER_ERROR:
      return {
        ...state,
        loading: false,
      }

    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      }

    default:
      return { ...state }
  }
}

export default login
