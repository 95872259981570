import TableFooter from "components/common/TableFooter"
import FormButton from "components/form-components/FormButton"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { SEARCH_WARNING_MESSAGE } from "helpers/constants/messages.constants"
import React from "react"
import { MdInfoOutline } from "react-icons/md"
import { Link } from "react-router-dom"
import ParentTestimonialListing from "./ParentTestimonialListing"

const TestimonialTabBody = ({
  getParentLoader,
  parentTestimonialList,
  setShowFeatureModal,
  setShowDeleteModal,
  setShowSettingModal,
  parentTestimonialMetadata,
  currentPage,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
  setcurrentSelectedParentTestimonial,
  isFeatureTab,
  getStars,
  searchText,
  setSearchText,
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col-3">
          <input
            name="search"
            id="search"
            className="form-control"
            type="text"
            placeholder="Search..."
            onChange={e => {
              setCurrentPage(0)
              setSearchText(e.target.value)
            }}
          />
        </div>
        <div className="col-6">
          {searchText && parentTestimonialList.length ? (
            <>
              <MdInfoOutline className="text-warning search-info-icon"/>
              <span className="text-warning ps-1 search-info-text">
                {SEARCH_WARNING_MESSAGE}
              </span>
            </>
          ) : null}
        </div>
        <div className="col-3">
          {isFeatureTab ? null : (
            <Link
              to={`/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.ADD_TESTIMONIAL}`}
            >
              <FormButton
                btnText="Add new Testimonial"
                customClass="btn-dark"
                type="button"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="table-responsive">
        <ParentTestimonialListing
          getParentLoader={getParentLoader}
          parentTestimonialList={parentTestimonialList}
          setShowFeatureModal={setShowFeatureModal}
          setShowDeleteModal={setShowDeleteModal}
          setShowSettingModal={setShowSettingModal}
          getStars={getStars}
          setcurrentSelectedParentTestimonial={
            setcurrentSelectedParentTestimonial
          }
          isFeatureTab={isFeatureTab}
        />
      </div>
      {parentTestimonialList?.length ? (
        <TableFooter
          metadata={parentTestimonialMetadata}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordLimit={recordLimit}
          setrecordLimit={setRecordLimit}
        />
      ) : null}
    </>
  )
}

export default TestimonialTabBody
