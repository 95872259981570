export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL"
export const SET_LOADING_TESTIMONIAL = "SET_LOADING/TESTIMONIAL"
export const SET_DELETE_LOADING_TESTIMONIAL = "SET_DELETE_LOADING_TESTIMONIAL"
export const GET_ALL_TESTIMONIAL = "GET_ALL_TESTIMONIAL"
export const GET_ALL_TESTIMONIAL_SUCCESS = "GET_ALL_TESTIMONIAL_SUCCESS"
export const GET_ALL_FILTERED_TESTIMONIAL_SUCCESS =
  "GET_ALL_FILTERED_TESTIMONIAL_SUCCESS"
export const GET_ALL_TESTIMONIAL_ERROR = "GET_ALL_TESTIMONIAL_ERROR"
export const GET_TESTIMONIAL = "GET_TESTIMONIAL"
export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL"
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL"
export const ADD_FEATURED_TESTIMONIAL = "ADD_FEATURED_TESTIMONIAL"
export const ADD_FEATURED_TESTIMONIAL_LOADING =
  "ADD_FEATURED_TESTIMONIAL_LOADING"
export const SET_LOADING_FEATURED_TESTIMONIAL =
  "SET_LOADING_FEATURED_TESTIMONIAL"
