import { useState, useEffect, useRef } from "react"
import styles from "./accordion.module.css"
import PropTypes from "prop-types"
import TabPill from "../tab-pill"
import { Link } from "react-router-dom"
import SwitchCheckbox from "components/form-components/SwitchCheckbox"
import { UncontrolledTooltip } from "reactstrap"
import { MdDelete } from "react-icons/md"
import { FaRegEdit } from "react-icons/fa"

const Accordion = ({
  title,
  description,
  index,
  active,
  setActive,
  editFormRoute,
  languageCode,
  childId,
  updateChild,
  status,
  deleteAction,
  deleteLoader,
  filterList,
  deleteConfirmationText,
  deleteCallBackAction = () => {},
  showStatus = true,
  showEdit = true,
  showDelete = true,
  showAdd = true,
}) => {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState("0px")
  const contentDiv = useRef(null)
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false)

  const openAccordion = () => {
    if (open) {
      setActive(0)
    } else {
      setActive(index)
    }
    setOpen(!open)
  }
  useEffect(() => {
    if (active !== index) {
      setOpen(false)
      setHeight("0px")
    } else {
      setHeight(`${contentDiv.current.scrollHeight}px`)
    }
  }, [active])

  return (
    <div
      className={`${styles.accordion_container} ${
        active === index ? styles.active : ""
      }`}
      onClick={openAccordion}
    >
      <div className={`${styles.title_contanier} d-flex w-100`}>
        <h4 className={`${styles.title_div} d-flex align-items-center m-0`}>
          <span className={`${styles.question}`}>{title}</span>
        </h4>
        <div className={`${styles.action_container} d-flex align-items-center`}>
          {showAdd && deleteBtnClicked ? (
            <>
              <button
                className="btn btn-dark"
                onClick={e => {
                  e.stopPropagation()
                  setDeleteBtnClicked(!deleteBtnClicked)
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={e => {
                  e.stopPropagation()
                  deleteAction(childId, () => {
                    setDeleteBtnClicked(false)
                    filterList(childId)
                    deleteCallBackAction()
                  })
                  setDeleteBtnClicked(false)
                }}
                id={`deletebtn${index}`}
                disabled={deleteLoader}
              >
                Delete
              </button>
              <UncontrolledTooltip
                placement="left"
                target={`deletebtn${index}`}
              >
                {deleteConfirmationText}
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              <TabPill classNames="mb-0" label={languageCode} status={status} />
              {showStatus ? (
                <>
                  <div
                    id={`statusTooltip${index}`}
                    className="d-flex justify-content-center align-items-center"
                    onClick={e => e.stopPropagation()}
                  >
                    <div className="form-check form-switch form-switch-md">
                      <SwitchCheckbox
                        status={status}
                        changeHandler={updateChild}
                        id={childId}
                      />
                    </div>
                  </div>
                  <UncontrolledTooltip target={`statusTooltip${index}`}>
                    Status
                  </UncontrolledTooltip>
                </>
              ) : null}

              {showEdit ? (
                <>
                  <Link id={`editTooltip${index}`} to={editFormRoute}>
                    <FaRegEdit
                      className={`${styles.action_icons} text-success icons`}
                    />
                  </Link>
                  <UncontrolledTooltip target={`editTooltip${index}`}>
                    Edit
                  </UncontrolledTooltip>
                </>
              ) : null}

              {showDelete ? (
                <>
                  <MdDelete
                    onClick={e => {
                      e.stopPropagation()
                      setDeleteBtnClicked(!deleteBtnClicked)
                    }}
                    id={`deleteTooltip${index}`}
                    className={`${styles.action_icons} text-danger icons`}
                  />
                  <UncontrolledTooltip target={`deleteTooltip${index}`}>
                    Delete
                  </UncontrolledTooltip>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div
        ref={contentDiv}
        className={`${styles.content_div}`}
        style={{ maxHeight: `${height}` }}
        dangerouslySetInnerHTML={{ __html: description }}
        onClick={e => e.stopPropagation()}
      ></div>
    </div>
  )
}
Accordion.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  deleteConfirmationText: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.number,
  setActive: PropTypes.func,
}
export default Accordion
