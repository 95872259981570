import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_CONTACT,
  ADD_CONTACT_LOADING,
  DELETE_CONTACT,
  DELETE_CONTACT_LOADING,
  GET_ALL_CONTACTS,
  GET_CONTACT,
  LIST_CONTACT_LOADING,
  UPDATE_CONTACT,
  UPDATE_CONTACT_LOADING,
} from "./actionTypes"
import { getAllContactsSuccess, setLoading } from "./actions"
import {
  addContact,
  deleteContact,
  getAllContacts,
  getContact,
  updateContact,
} from "./httpCalls.contact-us"

function* GetAllContacts(action) {
  yield put(setLoading(LIST_CONTACT_LOADING, true))
  const response = yield call(getAllContacts, action.payload)
  if (response?.success) {
    yield put(getAllContactsSuccess(response))
  }
  yield put(setLoading(LIST_CONTACT_LOADING, false))
}

function* GetContact(action) {
  const response = yield call(getContact, action.payload.contactUsId)
  if (response?.success) {
    action.payload.cb(response.data[0])
  }
}

function* AddContact(action) {
  yield put(setLoading(ADD_CONTACT_LOADING, true))
  const response = yield call(addContact, action.payload.data)
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(ADD_CONTACT_LOADING, false))
}

function* UpdateContact(action) {
  yield put(setLoading(UPDATE_CONTACT_LOADING, true))
  yield put(setLoading(true))
  const response = yield call(
    updateContact,
    action.payload.contactUsId,
    action.payload.data
  )
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(UPDATE_CONTACT_LOADING, false))
}

function* DeleteContact(action) {
  yield put(setLoading(DELETE_CONTACT_LOADING, true))
  const response = yield call(deleteContact, action.payload.contactIdList)
  if (response?.success) {
    toast.success(response.message)
    action.payload.cb()
  }
  yield put(setLoading(DELETE_CONTACT_LOADING, false))
}

function* contactUsSaga() {
  yield takeEvery(GET_ALL_CONTACTS, GetAllContacts)
  yield takeEvery(GET_CONTACT, GetContact)
  yield takeEvery(ADD_CONTACT, AddContact)
  yield takeEvery(UPDATE_CONTACT, UpdateContact)
  yield takeEvery(DELETE_CONTACT, DeleteContact)
}

export default contactUsSaga
