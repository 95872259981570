import TabPill from "components/common/tab-pill"
import SwitchCheckbox from "components/form-components/SwitchCheckbox"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useRef, useState } from "react"
import { FaRegEdit } from "react-icons/fa"
import { MdDelete, MdClose, MdCheck } from "react-icons/md"
import { IoChevronDown } from "react-icons/io5"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Modal,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import styles from "./bimaBhaiVideo.module.css"

const blogTableTableHead = [
  {
    value: "Title",
    id: "id",
    classNames: "text-start",
    style: { minWidth: "10rem" },
  },
  { value: "Language", id: "language", style: { minWidth: "8rem" } },
  { value: "Status", id: "status", style: { minWidth: "6rem" } },
]

const BimabhaiSettingModal = props => {
  const { vertical_align_middle, alert_close_button } = styles
  const {
    modalHeaderText,
    modalShow,
    setModalShow,
    selectedBimabhai,
    languages,
    handleStatusChange,
    handleDeleteClick,
    handleConfirmClick,
    deleteSelectedId,
    setDeleteSelectedId,
    // eslint-disable-next-line no-unused-vars
    modalAlertList,
    setModalAlertList,
  } = props

  const history = useHistory()
  const [singlebtn, setSinglebtn] = useState(false)
  const [height, setHeight] = useState("0px")
  const contentDiv = useRef(null)

  const remainingLanguages = () =>
    languages.filter(language => {
      return !selectedBimabhai?.bima_bhai_by_languages.some(
        item => item.language_code === language.language_code
      )
    })

  useEffect(() => {
    if (!selectedBimabhai?.id) setModalShow(false)
  }, [])

  useEffect(() => {
    if (modalAlertList?.length)
      setHeight(`${contentDiv?.current?.scrollHeight}px`)
  }, [modalAlertList?.length])

  useEffect(() => {
    if (!modalShow) {
      setDeleteSelectedId(null)
      setSinglebtn(false)
      setModalAlertList([])
    }
  }, [modalShow])

  return (
    <Modal isOpen={modalShow} centered={true} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalHeaderText}</h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflowX: "scroll" }}>
        {selectedBimabhai?.id ? (
          <>
            <div className="info-container mb-2">
              <div className="row">
                <div className="col-8">
                  <div
                    className="position-relative"
                    style={{
                      maxHeight: height,
                      transition: "all ease-in-out 0.5s",
                      overflow: "hidden",
                    }}
                    ref={contentDiv}
                  >
                    {modalAlertList?.length > 0 && (
                      <>
                        <div className="alert alert-danger mb-0">
                          <h4>!Opps, you can not delete this video</h4>
                          <ul className="mb-0">
                            {modalAlertList.map(item => (
                              <li
                                key={item}
                                dangerouslySetInnerHTML={{ __html: item }}
                              ></li>
                            ))}
                          </ul>
                        </div>
                        <div className={alert_close_button}>
                          <MdClose
                            className="text-secondary"
                            onClick={() => {
                              setModalAlertList([])
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  {remainingLanguages()?.length ? (
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                      className=""
                    >
                      <DropdownToggle className="btn btn-dark w-100" caret>
                        Add Video <IoChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        {remainingLanguages()?.map((language, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              history.push(
                                `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.ADD_BIMA_BHAI_VIDEOS}/${selectedBimabhai.id}/${language.language_code}`
                              )
                            }}
                          >
                            {language.name}
                            {` (${language.language_code})`}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="table-container">
              <div className="table-responsive">
                <Table className="table-striped mb-0">
                  <thead className="bg-dark text-white sticky">
                    <tr className="text-center">
                      {blogTableTableHead.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className={item.classNames || null}
                            style={item.style}
                          >
                            {item.value}
                          </th>
                        )
                      })}
                      <th className="action-tab">Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-striped ">
                    {selectedBimabhai?.bima_bhai_by_languages?.length ? (
                      selectedBimabhai.bima_bhai_by_languages.map(
                        (item, index) => {
                          return (
                            <tr className={vertical_align_middle} key={index}>
                              <td>
                                <div
                                  className="text-start text-truncate"
                                  style={{ maxWidth: "20rem" }}
                                >
                                  {item?.title}
                                </div>
                              </td>
                              <td>
                                <TabPill
                                  classNames="mb-0"
                                  label={item?.language_code}
                                  status={item?.status}
                                />
                              </td>
                              <td>
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="form-check form-switch form-switch-md">
                                    <SwitchCheckbox
                                      status={item?.status}
                                      changeHandler={handleStatusChange}
                                      id={item.id}
                                      domId={`status-${item.language_code}`}
                                    />
                                    <UncontrolledTooltip
                                      target={`status-${item.language_code}`}
                                      placement="bottom"
                                    >
                                      change status
                                    </UncontrolledTooltip>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <IconContainer
                                  selectedBimabhaiId={selectedBimabhai.id}
                                  item={item}
                                  deleteSelectedId={deleteSelectedId}
                                  handleDeleteClick={handleDeleteClick}
                                  setDeleteSelectedId={setDeleteSelectedId}
                                  handleConfirmClick={handleConfirmClick}
                                />
                              </td>
                            </tr>
                          )
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <h2 className="text-center">
                            No data available into the Raksha Video list
                          </h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}

const IconContainer = props => {
  const {
    item,
    selectedBimabhaiId,
    handleDeleteClick,
    deleteSelectedId,
    setDeleteSelectedId,
    handleConfirmClick,
  } = props
  const history = useHistory()
  return item.id === deleteSelectedId ? (
    <div className="icon-container d-flex justify-content-around">
      <div className="ms-2">
        <MdCheck
          className="text-danger icons"
          id={`check-${item.language_code}`}
          onClick={() => {
            handleConfirmClick(item.id)
          }}
        />
        <UncontrolledTooltip target={`check-${item.language_code}`}>
          Confirm
        </UncontrolledTooltip>
      </div>
      <div className="ms-2">
        <MdClose
          className="text-secondary icons"
          id={`close-${item.language_code}`}
          onClick={() => {
            setDeleteSelectedId(null)
          }}
        />
        {/* here unexpected error occur */}
        {/* <UncontrolledTooltip
          target={`close-${item.language_code}`}
        >
          cancel
        </UncontrolledTooltip> */}
      </div>
    </div>
  ) : (
    <div className="icon-container d-flex justify-content-around">
      <div className="ms-2">
        <FaRegEdit
          className="text-success icons"
          id={`edit-${item.language_code}`}
          onClick={() => {
            history.push(
              `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.EDIT_BIMA_BHAI_VIDEOS}/${selectedBimabhaiId}/${item.id}`
            )
          }}
        />
        {/* i changed the location to line 330 its working well*/}
        {/* <UncontrolledTooltip
          target={`edit-${item.language_code}`}
          placement="bottom"
        >
          Edit
        </UncontrolledTooltip> */}
      </div>
      <div className="ms-2">
        <MdDelete
          className="text-danger icons"
          id={`delete-${item.language_code}`}
          onClick={() => {
            handleDeleteClick(item.id)
          }}
        />
        <UncontrolledTooltip
          target={`delete-${item.language_code}`}
          placement="bottom"
        >
          Delete
        </UncontrolledTooltip>
      </div>
      <UncontrolledTooltip
        target={`edit-${item.language_code}`}
        placement="bottom"
      >
        Edit
      </UncontrolledTooltip>
    </div>
  )
}

const mapStatetoProps = state => {
  const { Language } = state
  const { languages } = Language
  return { languages }
}

export default connect(mapStatetoProps, {})(BimabhaiSettingModal)
