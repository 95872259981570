import TextEditor from "components/common/TextEditor"
import { Field, Form, Formik } from "formik"
import React from "react"
import { Card, Col, Label, Row, UncontrolledTooltip } from "reactstrap"
import Select from "react-select"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import FileDropBox from "components/form-components/FileDropBox"
import { FILE_TYPE } from "helpers/constants/constants.helper"
import { reactSelectCustomStyles } from "components/common/CustomSelect"
import styles from "../blog.module.css"
import FormButton from "components/form-components/FormButton"
import { BLOG } from "helpers/constants/labels.constants"
import { MdInfoOutline } from "react-icons/md"

const BlogForm = ({
  formData,
  editorText,
  selectedVideos,
  // setSelectedVideos,
  blogValidationSchema,
  onSubmit,
  setEditorText,
  submitbtn,
  blogCategoryList,
  // currentLanguageCode,
  submitLoader,
  requiredEditor,
  setRequiredEditor,
  uploadFile,
  parentBlogId,
  blogUpdateLoader,
  isUniqueSlugState,
  setSlugStr,
  fileLoader,
}) => {
  const { slug_icon } = styles

  const returnSlug = text => {
    const val = text
    let slug = val
      .toLowerCase()
      // .replace(/ +/g, "-") // replace continuous space with hyphen
      .replace(/[^\w-]+/g, "-") // only accept apha-numeric and hyphen
      .substring(0, 20)
    return slug
  }

  const {
    AUTHOR_NAME,
    BLOG_CATEGORY,
    BLOG_SLUG,
    CONTENT,
    CREATED_DATE,
    LANGUAGE,
    POSTER,
    SHORT_SUMMARY,
    SLUG_INFO,
    TITLE,
  } = BLOG.BLOG_FORM
  return (
    <div>
      <Formik
        initialValues={formData}
        validationSchema={blogValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (editorText) {
                handleSubmit(e)
              } else {
                setRequiredEditor(true)
              }
            }}
          >
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label htmlFor="title">{TITLE}</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={values.title}
                        id="title"
                        onChange={handleChange}
                        onBlur={() => {
                          const titleValue = values?.title?.trim()
                          setFieldValue("title", titleValue || "")
                          if (!parentBlogId) {
                            const slugValue = returnSlug(titleValue)
                            if (!touched.slug) {
                              setFieldValue("slug", slugValue || "")
                              setSlugStr(slugValue)
                            }
                          }
                          setFieldTouched("title", true)
                        }}
                      />
                      {errors.title && touched.title ? (
                        <div className="position-absolute text-danger">
                          {errors.title}
                        </div>
                      ) : null}
                    </div>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label htmlFor="authorname">
                          {AUTHOR_NAME} (Optional)
                        </Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="authorname"
                          value={values.authorname}
                          id="authorname"
                          onBlur={() => {
                            setFieldValue(
                              "authorname",
                              values.authorname.trim()
                            )
                            setFieldTouched("authorname", true)
                          }}
                        />
                        {errors.authorname && touched.authorname ? (
                          <div className="position-absolute text-danger">
                            {errors.authorname}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-4">
                        <Label htmlFor="slug">
                          <span>{BLOG_SLUG}</span>
                          <MdInfoOutline
                            id="slug-info-icon"
                            className="cursor-pointer"
                          />
                          <UncontrolledTooltip target="slug-info-icon">
                            {SLUG_INFO}
                          </UncontrolledTooltip>
                        </Label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            name="slug"
                            value={values.slug}
                            id="slug"
                            disabled={parentBlogId ? true : false}
                            onChange={e => {
                              const value = returnSlug(e.target.value)
                              setFieldValue("slug", value)
                              if (!touched.slug) setFieldTouched("slug", true)
                              setSlugStr(value)
                            }}
                          />
                          {!parentBlogId && (
                            <div className={slug_icon}>
                              {values.slug &&
                                (isUniqueSlugState ? (
                                  <span className="ion ion-md-checkmark-circle position-absolute end-0 top-25 text-success"></span>
                                ) : (
                                  <span className="ion ion-md-close-circle position-absolute end-0 top-25 text-danger"></span>
                                ))}
                            </div>
                          )}
                        </div>
                        {errors.slug && touched.slug ? (
                          <div className="position-absolute text-danger">
                            {errors.slug}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <div className="mb-4">
                      <Label htmlFor="summary">{SHORT_SUMMARY}</Label>
                      <Field
                        as="textarea"
                        className="form-control"
                        name="summary"
                        value={values.summary}
                        rows={3}
                        id="summary"
                        onBlur={() => {
                          setFieldValue("summary", values.summary.trim())
                          setFieldTouched("summary", true)
                        }}
                      />
                      {errors.summary && touched.summary ? (
                        <div className="position-absolute text-danger">
                          {errors.summary}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="content">{CONTENT}</Label>
                      <TextEditor
                        content={editorText}
                        setContent={setEditorText}
                        id="content"
                      />
                      {requiredEditor ? (
                        <div className="position-absolute text-danger">
                          Content required!
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label htmlFor="blogCategory">{BLOG_CATEGORY} </Label>
                      <Select
                        name="blogCategory"
                        value={values.blogCategory}
                        options={blogCategoryList}
                        onChange={option => {
                          setFieldTouched("blogCategory", true)
                          setFieldValue("blogCategory", option)
                        }}
                        isDisabled={parentBlogId ? true : false}
                        id="blogCategory"
                        styles={reactSelectCustomStyles}
                      />
                      {errors.blogCategory && touched.blogCategory ? (
                        <div className="position-absolute text-danger">
                          {errors.blogCategory}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="langugageCode">{LANGUAGE}</Label>
                      <LanguageDropDown
                        disabled={parentBlogId ? true : false}
                        id="language"
                      />
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="blogCreatedDate">{CREATED_DATE}</Label>
                      <Field
                        type="date"
                        className="form-control"
                        name="blogCreatedDate"
                        value={values.blogCreatedDate}
                        id="blogCreatedDate"
                        disabled={parentBlogId ? true : false}
                      />
                      {errors.blogCreatedDate && touched.blogCreatedDate ? (
                        <div className="position-absolute text-danger">
                          {errors.blogCreatedDate}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-2">
                      <Label htmlFor="posterId">{POSTER}</Label>
                      <FileDropBox
                        value={selectedVideos || []}
                        handleChange={file => {
                          uploadFile(file, "posterId", setFieldValue)
                        }}
                        fileType={FILE_TYPE.IMAGE}
                      />
                      {errors.posterId && touched.posterId ? (
                        <div className="position-absolute text-danger">
                          {errors.posterId}
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={submitLoader || blogUpdateLoader || fileLoader}
                  type="submit"
                  btnText={submitbtn}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BlogForm
