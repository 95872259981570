import classNames from "classnames"
import { getUserPreference } from "helpers/utils.helper"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import TestimonialTabBody from "./TestimonialTabBody"
import {
  setBreadcrumbItems,
  getAllTestimonial,
  updateTestimonial,
  deleteTestimonial,
  addFeaturedTestimonial,
} from "store/actions"
import { BREADCRUM, ROUTES_PATH } from "helpers/constants/constants.helper"
import TestimonialSettingModal from "./TestimonialSettingModal"
import ConfirmationModal from "components/modal/ConfirmationModal"
import TestimonialFeaturedList from "./TestimonialFeaturedList"
import { useHistory } from "react-router-dom"
import { TESTIMONIALS_MESSAGES } from "helpers/constants/messages.constants"
import { MdStar } from "react-icons/md"

const TESTIMONIALTabs = [
  {
    value: "Featured",
    id: 1,
    location: `/${ROUTES_PATH.TESTIMONIAL}/featured`,
  },
  {
    value: "Active/In-active",
    id: 2,
    location: `/${ROUTES_PATH.TESTIMONIAL}/featured`,
  },
]

const Testimonials = ({
  setBreadcrumbItems,
  getAllTestimonial,
  parentTestimonialsList,
  parentTestimonialMetadata,
  languages,
  updateTestimonial,
  deleteTestimonial,
  filteredTestimonialList,
  addFeaturedTestimonial,
  testimonialDeleteLoader,
  TESTIMONIAL_LIST_LOADER,
  FEATURED_TESTIMONIAL_LIST_LOADER,
  TestmonialMetadata,
}) => {
  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSettingModal, setShowSettingModal] = useState(false)
  const [activeTab, setActiveTab] = useState(
    history.location.search.includes(ROUTES_PATH.FEATURED) ? 1 : 2
  )
  const [currentPage, setCurrentPage] = useState(0)
  const [
    currentSelectedParentTestimonial,
    setcurrentSelectedParentTestimonial,
  ] = useState(null)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const [searchText, setSearchText] = useState("")

  const getAllTestimonialParams = () => ({
    pageNum: activeTab === 1 ? 0 : currentPage,
    recordLimit: recordLimit,
    isfeatured: activeTab === 1 ? true : false,
  })

  const getStars = (ratings = 0) => {
    return new Array(5).fill(null).map((star, idx) => {
      if (idx + 1 <= ratings) {
        return (
          <MdStar
            className="text-warning"
            style={{ fontSize: "16px" }}
            key={idx}
          />
        )
      } else {
        return (
          <MdStar
            className="text-dark"
            style={{ fontSize: "16px" }}
            key={idx}
          />
        )
      }
    })
  }

  const updateStatusTestimonial = (testimonialChildId, childStatus, cb) => {
    function changeStatusInList() {
      if (parentTestimonialsList && parentTestimonialsList.length) {
        setcurrentSelectedParentTestimonial({
          ...currentSelectedParentTestimonial,
          testimonials: currentSelectedParentTestimonial.testimonials.map(
            testimonialChild => {
              return testimonialChild.id === testimonialChildId
                ? { ...testimonialChild, status: childStatus }
                : testimonialChild
            }
          ),
        })
      }
    }
    updateTestimonial({ id: testimonialChildId, status: childStatus }, () => {
      cb()
      changeStatusInList()
    })
  }

  const filterTestimonials = deletedTestimonialID => {
    getAllTestimonial(getAllTestimonialParams())
    const newTestimonials =
      currentSelectedParentTestimonial?.testimonials.filter(
        testimonial => testimonial.id !== deletedTestimonialID
      )
    setcurrentSelectedParentTestimonial({
      ...currentSelectedParentTestimonial,
      testimonials: newTestimonials,
    })
    if (!newTestimonials.length) {
      setShowSettingModal(false)
    }
  }

  const callbackGetAllTestimonial = currentPageOfSeletedTab => {
    if (currentPageOfSeletedTab >= 1) {
      setCurrentPage(currentPage - 1)
      getAllTestimonial({
        pageNum: currentPage - 1,
        recordLimit: recordLimit,
        isfeatured: activeTab === 1 ? true : false,
      })
    } else {
      getAllTestimonial(getAllTestimonialParams())
    }
  }

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.TESTIMONIAL)
  }, [])

  useEffect(() => {
    if (
      activeTab === 2 &&
      history.location.search.includes(ROUTES_PATH.FEATURED)
    ) {
      history.push(`/${ROUTES_PATH.TESTIMONIAL}`)
    }
    setcurrentSelectedParentTestimonial(null)
    let timeout
    if (searchText) {
      const params = getAllTestimonialParams()
      timeout = setTimeout(() => {
        getAllTestimonial(
          { ...params, searchText: searchText },
          activeTab === 1 ? true : false
        )
      }, 500)
    } else {
      getAllTestimonial(
        getAllTestimonialParams(),
        activeTab === 1 ? true : false
      )
    }
    return () => clearTimeout(timeout)
  }, [currentPage, recordLimit, activeTab, searchText])
  useEffect(() => {
    setSearchText("")
  }, [activeTab])
  return (
    <>
      <Nav tabs>
        {TESTIMONIALTabs.map((item, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeTab === item.id,
                })}
                onClick={() => {
                  setActiveTab(item.id)
                }}
              >
                {item.value}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted bg-white">
        <TabPane tabId={1}>
          <TestimonialFeaturedList
            FeaturedLoader={FEATURED_TESTIMONIAL_LIST_LOADER}
            SelectModalLoader={TESTIMONIAL_LIST_LOADER}
            parentTestimonialList={parentTestimonialsList}
            getAllTestimonial={getAllTestimonial}
            filteredTestimonialList={filteredTestimonialList}
            getStars={getStars}
            addToFeatureAction={addFeaturedTestimonial}
            activeTab={activeTab}
            TestmonialMetadata={TestmonialMetadata}
            setShowSettingModal={setShowSettingModal}
            setcurrentSelectedParentTestimonial={
              setcurrentSelectedParentTestimonial
            }
          />
        </TabPane>
        <TabPane tabId={2}>
          <TestimonialTabBody
            getParentLoader={TESTIMONIAL_LIST_LOADER}
            parentTestimonialList={parentTestimonialsList}
            setShowDeleteModal={setShowDeleteModal}
            setShowSettingModal={setShowSettingModal}
            parentTestimonialMetadata={parentTestimonialMetadata}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordLimit={recordLimit}
            setRecordLimit={setRecordLimit}
            isFeatureTab={false}
            getStars={getStars}
            setcurrentSelectedParentTestimonial={
              setcurrentSelectedParentTestimonial
            }
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </TabPane>
      </TabContent>

      {/* TODO: Uncomment this later when api is ready  */}
      <TestimonialSettingModal
        filterTestimonials={filterTestimonials}
        modalHeaderText="Testimonial Settings"
        modalShow={showSettingModal}
        setModalShow={setShowSettingModal}
        currentSelectedParentTestimonial={currentSelectedParentTestimonial}
        updateChildTestimonial={updateStatusTestimonial}
        languages={languages}
        modalDeleteAction={deleteTestimonial}
        testimonialDeleteLoader={testimonialDeleteLoader}
        deleteCallBackAction={() => {
          parentTestimonialsList.length === 1
            ? callbackGetAllTestimonial(currentPage)
            : getAllTestimonial(getAllTestimonialParams())
        }}
      />

      <ConfirmationModal
        confirmBtnText="Delete"
        modalHeaderText={TESTIMONIALS_MESSAGES.DELETE_PARENT}
        confirmAction={() => {
          deleteTestimonial(
            [currentSelectedParentTestimonial.id],
            () => {
              parentTestimonialsList.length === 1
                ? callbackGetAllTestimonial(currentPage)
                : getAllTestimonial(getAllTestimonialParams())
              setShowDeleteModal(false)
            },
            false
          )
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={testimonialDeleteLoader}
      />
    </>
  )
}

const mapStatetoProps = state => {
  const {
    testimonials: parentTestimonialsList,
    metadata: parentTestimonialMetadata,
    loading: parentLoading,
    deleteLoader: testimonialDeleteLoader,
    filteredTestimonialList,
    FEATURED_TESTIMONIAL_LIST_LOADER = false,
    TESTIMONIAL_LIST_LOADER = false,
    metadata: TestmonialMetadata,
  } = state.Testimonial

  const { languages } = state.Language
  return {
    testimonialDeleteLoader,
    parentTestimonialsList,
    parentTestimonialMetadata,
    parentLoading,
    languages,
    TestmonialMetadata,
    filteredTestimonialList,
    TESTIMONIAL_LIST_LOADER,
    FEATURED_TESTIMONIAL_LIST_LOADER,
  }
}

export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getAllTestimonial,
  updateTestimonial,
  deleteTestimonial,
  addFeaturedTestimonial,
})(Testimonials)
