import { call, put, takeEvery } from "redux-saga/effects"
import { callAddMedia } from "./httpCalls.media"
import { addMediaError, addMediaSuccess } from "./actions"
import { ADD_MEDIA } from "./actionTypes"
import { toast } from "react-toastify"
import { ERROR_TOAST, SUCCESS_TOAST } from "helpers/constants/constants.helper"
import { formatBytes } from "helpers/utils.helper"

// TODO: Remove success and error actions later
// Also remove reducer is not in use
function* addMedia(action) {
  try {
    const {
      payload: { formdata, cb },
    } = action
    const response = yield call(callAddMedia, formdata)
    if (response?.data?.success) {
      toast.success(response?.data?.message, {
        toastId: SUCCESS_TOAST.UPLOAD_IMAGE,
      })
      const {
        id = "",
        location = "",
        originalname = "Unknown",
        size,
      } = response?.data?.data
      cb([
        {
          id,
          preview: location,
          name: originalname,
          formattedSize: size ? formatBytes(size) : "Unknown",
        },
      ])
      yield put(addMediaSuccess(response?.data))
    } else {
      toast.error(response?.data?.message, {
        toastId: ERROR_TOAST.UPLOAD_IMAGE,
      })
      yield put(addMediaError())
    }
  } catch (error) {
    yield put(addMediaError())
  }
}

function* MediaSaga() {
  yield takeEvery(ADD_MEDIA, addMedia)
}

export default MediaSaga
