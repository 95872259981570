import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_CHILD_FAQ,
  DELETE_FAQS,
  GET_ALL_PARENT_FAQS,
  GET_CHILD_FAQ,
  GET_PARENT_FAQ,
  UPDATE_CHILD_FAQ,
  UPDATE_PARENT_FAQ,
} from "./actionTypes"
import {
  getAllParentFaqsSuccess,
  setChildFaqLoader,
  setChildFAQLoader,
  setDeleteFaqLoader,
  setParentFaqLoader,
  setUpdateChildFaqLoader,
  setUpdateParentFAQLoader,
} from "./actions"
import {
  addFaq,
  deleteFaq,
  getAllParentFaqs,
  getChildFaq,
  getParentFaq,
  updateChildFaq,
  updateParentFAQ,
} from "./httpCalls.faqs"
import { SUCCESS_TOAST } from "helpers/constants/constants.helper"

function* GetAllParentFaqs(action) {
  yield put(setParentFaqLoader(true))
  try {
    const { payload } = action
    const response = yield call(getAllParentFaqs, payload)
    if (response?.success) {
      yield put(getAllParentFaqsSuccess(response.data, response.metadata))
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setParentFaqLoader(false))
}

function* UpdateParentFAQ(action) {
  yield put(setUpdateParentFAQLoader(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(updateParentFAQ, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "faq-parent-update" })
      cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setUpdateParentFAQLoader(false))
}

function* AddFaq(action) {
  yield put(setChildFAQLoader(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(addFaq, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "add-child-faq" })
      cb && cb()
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setChildFAQLoader(false))
}

function* DeleteFaq(action) {
  yield put(setDeleteFaqLoader(true))
  try {
    const { faqId, cb } = action.payload
    const response = yield call(deleteFaq, faqId)
    if (response?.success) {
      toast.success(response?.message, { toastId: SUCCESS_TOAST.DELETE_FAQ })
      cb && cb(faqId)
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setDeleteFaqLoader(false))
}

function* GetChildFaq(action) {
  yield put(setChildFaqLoader(true))
  try {
    const { childFaqId, cb } = action.payload
    const response = yield call(getChildFaq, childFaqId)
    if (response?.success) {
      cb && cb(response.data[0])
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setChildFaqLoader(false))
}

function* UpdateChildFaq(action) {
  yield put(setUpdateChildFaqLoader(true))
  try {
    const { data, cb, params } = action.payload
    const response = yield call(updateChildFaq, data)
    if (response?.success) {
      toast.success(response?.message)
      cb && cb()
      if (params) {
        const response = yield call(getAllParentFaqs, params)
        if (response?.success) {
          yield put(getAllParentFaqsSuccess(response.data, response.metadata))
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield put(setUpdateChildFaqLoader(false))
}
function* GetParentFaq(action) {
  yield put(setParentFaqLoader(true))
  const { parentFaqId, cb } = action.payload
  try {
    const response = yield call(getParentFaq, parentFaqId)
    // if (response?.success) {
    //   yield put(getParentFaqSuccess(response.data))
    // }
    cb(response)
  } catch (error) {
    cb({})
    console.log(error, "error")
  }
  yield put(setParentFaqLoader(false))
}
function* faqSaga() {
  yield takeEvery(GET_ALL_PARENT_FAQS, GetAllParentFaqs)
  yield takeEvery(UPDATE_PARENT_FAQ, UpdateParentFAQ)
  yield takeEvery(ADD_CHILD_FAQ, AddFaq)
  yield takeEvery(DELETE_FAQS, DeleteFaq)
  yield takeEvery(GET_CHILD_FAQ, GetChildFaq)
  yield takeEvery(UPDATE_CHILD_FAQ, UpdateChildFaq)
  yield takeEvery(GET_PARENT_FAQ, GetParentFaq)
}

export default faqSaga
