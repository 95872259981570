import { post } from "helpers/api.helper"

export async function callAddTag(tag) {
  try {
    const res = await post("/tags", { tagName: tag })
    return res
  } catch (err) {
    return err
  }
}
