import {
  ADD_PARENT_BLOG,
  DELETE_BLOG,
  GET_BLOG_DATA,
  GET_PARENT_BLOG_LIST,
  GET_PARENT_BLOG_LIST_SUCCESS,
  IS_UNIQUE_BLOG_SLUG,
  SET_BLOG_LOADER,
  UPDATE_CHILD_BLOG,
} from "./actionTypes.blog"

export const getParentBlogList = params => ({
  type: GET_PARENT_BLOG_LIST,
  payload: params,
})

export const getParentBlogListSuccess = (data, metaData) => ({
  type: GET_PARENT_BLOG_LIST_SUCCESS,
  payload: { data, metaData },
})

export const getBlogData = (blogId, cb) => ({
  type: GET_BLOG_DATA,
  payload: { blogId, cb },
})

export const isUniqueBlogSlug = (blogSlug, cb) => ({
  type: IS_UNIQUE_BLOG_SLUG,
  payload: { blogSlug, cb },
})

export const addParentBlog = (data, cb) => ({
  type: ADD_PARENT_BLOG,
  payload: { data, cb },
})

export const updateChildBlog = (data, cb, params = null) => ({
  type: UPDATE_CHILD_BLOG,
  payload: { data, cb, params },
})

export const deleteBlog = (blogId, currentPage, arrayLength, cb) => ({
  type: DELETE_BLOG,
  payload: { blogId, currentPage, arrayLength, cb },
})

export const setBlogLoader = (key, value) => ({
  type: SET_BLOG_LOADER,
  payload: { key, value },
})
