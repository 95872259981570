import { get } from "helpers/api.helper"

export async function getallproducts() {
  try {
    const params = { recordLimit: 4, pageNum: 0 }
    const res = await get("/product", { params })
    return res
  } catch (err) {
    return err
  }
}
