import TabPill from "components/common/tab-pill"
import CustomTableLoader from "components/loader/CustomTableLoader"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { getRemainingLanguages } from "helpers/utils.helper"
import React from "react"
import { MdDelete, MdSettings } from "react-icons/md"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Table, UncontrolledTooltip } from "reactstrap"
import styles from "./Testimonial.module.css"

const TestimonialTableHead = [
  { value: "Name", id: "id", classNames: "text-start" },
  { value: "Ratings", id: "ratings", style: { width: "169px" } },
  { value: "Language", id: "Language", style: { width: "561px" } }
]
const ParentTestimonialListing = ({
  getParentLoader,
  parentTestimonialList,
  setShowDeleteModal,
  setShowSettingModal,
  setcurrentSelectedParentTestimonial,
  isFeatureTab,
  getStars,
  languages,
}) => {
  const { vertical_align_middle } = styles

  return (
    <Table className="table-striped mb-0">
      <thead className="bg-dark text-white sticky">
        <tr className="text-center">
          {TestimonialTableHead.map((item, index) => {
            return (
              <th key={index} className={item.classNames || null} style={item.style}>
                {item.value}
              </th>
            )
          })}
          <th className="action-tab">Action</th>
        </tr>
      </thead>
      <tbody className="table-striped ">
        {getParentLoader ? (
          <CustomTableLoader col={4} />
        ) : parentTestimonialList.length ? (
          parentTestimonialList.map((item, index) => {
            return (
              <tr className={vertical_align_middle} key={item.id || index}>
                <td
                  className="text-start text-truncate"
                  id={`title${index}`}
                  style={{ maxWidth: "80px" }}
                >
                  {item?.label}
                </td>
                <UncontrolledTooltip
                target={`title${index}`}
                >
                 {item?.label}
                </UncontrolledTooltip>
                <td className="text-truncate" style={{ maxWidth: "80px" }}>
                  {getStars(item?.ratings)}
                </td>
                <td>
                  <LanguageTabs
                    languageItemArray={item?.testimonials || []}
                    languages={languages}
                    parentTestimonialId={item.id}
                  />
                </td>
                <td className="icon-container" style={{ maxWidth: "40px" }}>
                  <div>
                    {isFeatureTab ? null : (
                      <>
                        <MdSettings
                          className="text-success icons"
                          id={`settingTooltip${index}`}
                          onClick={() => {
                            setcurrentSelectedParentTestimonial(item)
                            setShowSettingModal(true)
                          }}
                        />
                        <UncontrolledTooltip target={`settingTooltip${index}`}>
                          Settings
                        </UncontrolledTooltip>
                        <MdDelete
                          className="text-danger icons"
                          id={`deleteTooltip${index}`}
                          onClick={() => {
                            setcurrentSelectedParentTestimonial(item)
                            setShowDeleteModal(true)
                          }}
                        />
                        <UncontrolledTooltip target={`deleteTooltip${index}`}>
                          Delete
                        </UncontrolledTooltip>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              <h3>
                {isFeatureTab
                  ? "No Featured Testimonial Found"
                  : "No Testimonial Found"}
              </h3>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const LanguageTabs = ({
  languageItemArray,
  languages,
  parentTestimonialId,
}) => {
  const remainingLanguages = getRemainingLanguages(languages, languageItemArray)
  const history = useHistory()
  return (
    <div>
      {languageItemArray.map(languageItemObj => (
        <React.Fragment key={languageItemObj.id}>
          <TabPill
            status={languageItemObj.status}
            label={languageItemObj.language_code}
            action={() => {}}
            classNames="mb-0 lang-tab"
          />
        </React.Fragment>
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code}+`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.ADD_TESTIMONIAL}/${language.language_code}/${parentTestimonialId}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
    </div>
  )
}
const mapStatetoProps = state => {
  const { languages } = state.Language
  return { languages }
}
export default connect(mapStatetoProps, {})(ParentTestimonialListing)
