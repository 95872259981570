import { get, post } from "helpers/api.helper"

let loginAboartController
export async function login(params) {
  try {
    if (loginAboartController) {
      loginAboartController.abort()
    }
    loginAboartController = new AbortController()
    const res = await post("/user/login", params, {
      headers: {
        signal: loginAboartController.signal,
      },
    })
    return res
  } catch (err) {
    return err
  }
}

export async function logout() {
  try {
    const res = await get("/user/logout")
    return res
  } catch (err) {
    return err
  }
}
