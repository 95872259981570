import React from "react"
import { Editor } from "@tinymce/tinymce-react"

const TextEditor = ({ content, setContent, id = "" }) => {
  return (
    <>
      <Editor
        value={content}
        apiKey="fg13eu3a2lpaw5j2gig0kidv8t3geclx743hzwyes0gbr60s"
        textareaName="content"
        onEditorChange={text => setContent(text)}
        init={{
          height: 300,
          plugins:
            "importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons ",
          image_caption: true,
          menubar: false,
          editimage_cors_hosts: ["picsum.photos"],
        }}
        id={id}
      />
    </>
  )
}
export default TextEditor
