import React, { useEffect, useState } from "react"

const SwitchCheckbox = ({
  status,
  changeHandler,
  id,
  classNames = "",
  domId = "customSwitchsizemd",
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const checkHandler = () => {
    setIsChecked(!isChecked)
    setIsDisabled(false)
  }

  useEffect(() => {
    setIsChecked(status)

    return () => setIsChecked(false)
  }, [status])

  return (
    <div>
      <input
        type="checkbox"
        className={`form-check-input cursor-pointer ${classNames}`}
        id={domId}
        disabled={isDisabled}
        checked={isChecked}
        onChange={() => {
          setIsDisabled(true)
          changeHandler(id, !isChecked, checkHandler)
        }}
      />
    </div>
  )
}

export default SwitchCheckbox
