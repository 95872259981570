import { CONTACT_URL } from "helpers/api-end-points.constant.helper"
import { get, post, del, patch } from "helpers/api.helper"

// let leadsAboartController
export async function getAllContacts(params) {
  try {
    const res = await get(CONTACT_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function getContact(contactUsId) {
  try {
    const res = await get(CONTACT_URL + "/" + contactUsId)
    return res
  } catch (err) {
    return err
  }
}

export async function addContact(data) {
  try {
    const res = await post(CONTACT_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function updateContact(contactUsId, dataChange) {
  try {
    const res = await patch(CONTACT_URL, {
      conId: contactUsId,
      changes: dataChange,
    })
    return res
  } catch (err) {
    return err
  }
}

export async function deleteContact(contactIdList) {
  try {
    const res = await del(CONTACT_URL, { data: { id: contactIdList } })
    return res
  } catch (err) {
    return err
  }
}
