import { ADD_MEDIA, ADD_MEDIA_ERROR, ADD_MEDIA_SUCCESS } from "./actionTypes"

export const addMedia = ({ formdata, cb }) => {
  return {
    type: ADD_MEDIA,
    payload: { formdata, cb },
  }
}

export const addMediaSuccess = response => {
  return {
    type: ADD_MEDIA_SUCCESS,
    payload: response,
  }
}

export const addMediaError = () => {
  return {
    type: ADD_MEDIA_ERROR,
  }
}
