export const GET_BANNER_LIST = "GET_BANNER_LIST"
export const GET_BANNER_LIST_SUCCESS = "GET_BANNER_LIST_SUCCESS"

export const ADD_BANNER = "ADD_BANNER"
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"

export const GET_PARENT_BANNER = "GET_PARENT_BANNER"

export const GET_BANNER = "GET_BANNER"
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS"

export const UPDATE_BANNER = "UPDATE_BANNER"

export const DELETE_BANNER = "DELETE_BANNER"

export const SET_LOADING = "SET_LOADING"
