import TabPill from "components/common/tab-pill"
import TableFooter from "components/common/TableFooter"
import FormButton from "components/form-components/FormButton"
import CustomTableLoader from "components/loader/CustomTableLoader"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { SEARCH_WARNING_MESSAGE } from "helpers/constants/messages.constants"
import { getRemainingLanguages } from "helpers/utils.helper"
import React from "react"
import { MdDelete, MdInfoOutline, MdSettings } from "react-icons/md"
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Card, CardBody, Table, UncontrolledTooltip } from "reactstrap"
import styles from "./bimaBhaiVideo.module.css"

const BimabhaiParentList = ({
  bimaBhaiTableTableHead,
  loaderBimaBhai,
  bimabhaiVideoList,
  setSelectedBimabhai,
  setShowDeleteModal,
  bimaBhaiMetadata,
  currentPage,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
  setSettingModal,
  languages,
  searchText,
  setSearchText,
}) => {
  const { vertical_align_middle } = styles
  return (
    <Card>
      <CardBody>
        <div className="row mb-2">
          <div className="col-3">
            <input
              className="form-control"
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={e => {
                setCurrentPage(0)
                setSearchText(e.target.value)
              }}
            />
          </div>
          <div className="col-6 d-flex align-items-center p-0">
            {searchText && bimabhaiVideoList.length ? (
              <>
                <MdInfoOutline className="text-warning search-info-icon" />
                <span className="text-warning ps-1 search-info-text">
                  {SEARCH_WARNING_MESSAGE}
                </span>
              </>
            ) : null}
          </div>
          <div className="col-3">
            <Link
              to={`/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.ADD_BIMA_BHAI_VIDEOS}`}
            >
              <FormButton
                customClass="btn-dark"
                type="button"
                btnText="Add Raksha Video"
              />
            </Link>
          </div>
        </div>
        {/* 
          // TODO: for the future tag filter
          {tagsList && tagsList.length ? (
            <div className="row mb-1">
              <div className="col-12">
                <div className="mb-1">Search by tags :</div>
                <TagTabs tagArray={tagOption} />
              </div>
            </div>
          ) : null} 
        */}
        <>
          <div className="table-responsive">
            <Table className="table-striped mb-0">
              <thead className="bg-dark text-white sticky">
                <tr className="text-center">
                  {bimaBhaiTableTableHead.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className={item.classNames || null}
                        style={item.style}
                      >
                        {item.value}
                      </th>
                    )
                  })}
                  <th className="action-tab">Action</th>
                </tr>
              </thead>
              <tbody>
                {loaderBimaBhai ? (
                  <CustomTableLoader col={4} />
                ) : bimabhaiVideoList && bimabhaiVideoList.length ? (
                  bimabhaiVideoList.map(item => {
                    return (
                      <tr className={vertical_align_middle} key={item.id}>
                        <td className="text-start text-truncate" style={{maxWidth:"400px"}}>{item.title}</td>
                        <td>
                          <TabPill
                            classNames="mb-0"
                            label={item.bima_bhai_video_category.name}
                            status={true}
                          />
                        </td>
                        <td>
                          <LanguageTabs
                            languageArray={item.bima_bhai_by_languages}
                            languages={languages}
                            videoId={item.id}
                          />
                        </td>
                        <td className="icon-container">
                          <div>
                            <>
                              <MdSettings
                                className="text-success icons"
                                id={`setting-${item.id}`}
                                onClick={() => {
                                  setSelectedBimabhai(item)
                                  setSettingModal(true)
                                }}
                              />
                              <UncontrolledTooltip
                                target={`setting-${item.id}`}
                              >
                                Settings
                              </UncontrolledTooltip>
                            </>
                            <>
                              <MdDelete
                                className="text-danger icons"
                                id={`delete-${item.id}`}
                                onClick={() => {
                                  setSelectedBimabhai(item)
                                  setShowDeleteModal(true)
                                }}
                              />
                              <UncontrolledTooltip target={`delete-${item.id}`}>
                                Delete
                              </UncontrolledTooltip>
                            </>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={200} className="text-center">
                      <h3>No Video Found !</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
        {bimabhaiVideoList && bimabhaiVideoList.length ? (
          <TableFooter
            metadata={bimaBhaiMetadata}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordLimit={recordLimit}
            setrecordLimit={setRecordLimit}
          />
        ) : null}
      </CardBody>
    </Card>
  )
}

const LanguageTabs = ({ languageArray, languages, videoId }) => {
  const remainingLanguages = getRemainingLanguages(languages, languageArray)
  const history = useHistory()
  return (
    <div>
      {languageArray.map((languageObj, i) => (
        <TabPill
          key={i}
          status={languageObj.status}
          label={languageObj.language_code}
          classNames="mb-0 cursor-default lang-tab"
        />
      ))}
      {remainingLanguages.map((language, index) => (
        <TabPill
          key={index}
          label={`${language.language_code}+`}
          action={() =>
            history.push(
              `/${ROUTES_PATH.BIMA_BHAI_VIDEOS}/${ROUTES_PATH.ADD_BIMA_BHAI_VIDEOS}/${videoId}/${language.language_code}`
            )
          }
          classNames="mb-0 cursor-pointer lang-tab add_language_badge"
        />
      ))}
    </div>
  )
}
const mapStatetoProps = state => {
  const { Language } = state
  const { languages } = Language
  return { languages }
}
export default connect(mapStatetoProps, {})(BimabhaiParentList)
