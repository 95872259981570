export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS"
export const GET_ALL_CONTACTS_SUCCESS = "GET_ALL_CONTACTS_SUCCESS"
export const GET_ALL_CONTACTS_ERROR = "GET_ALL_CONTACTS_ERROR"
export const ADD_CONTACT = "ADD_CONTACT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const DELETE_CONTACT = "DELETE_CONTACT"
export const SET_LOADING_CONTACTS = "SET_LOADING/CONTACTS"
export const GET_CONTACT = "GET_CONTACT"
export const LIST_CONTACT_LOADING = "list_contact_loading"
export const ADD_CONTACT_LOADING = "add_contact_loading"
export const UPDATE_CONTACT_LOADING = "update_contact_loading"
export const DELETE_CONTACT_LOADING = "delete_contact_loading"
