import SkeletonLoader from "components/common/SkeletonLoader"
import SwitchCheckbox from "components/form-components/SwitchCheckbox"
import { BREADCRUM } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { Card, CardBody, Table, UncontrolledTooltip } from "reactstrap"
import {
  setBreadcrumbItems,
  getAllSocialMedia,
  deleteSocialMedia,
  updateSocialMedia,
} from "store/actions"
import SocialMediaEditFormModal from "./SocialMediaEditFormModal"

const SocialMediaTableHead = [
  { value: "Name", id: "name" },
  { value: "Link", id: "link", style:{width:"430px"} },
]

const SocialMediaLinks = ({
  setBreadcrumbItems,
  getAllSocialMedia,
  updateSocialMedia,
  socialmedia,
  loading,
}) => {
  const updateStatus = (socialMediaId, status, cb) => {
    if (socialmedia && socialmedia.length) {
      socialmedia.map(parentObj => {
        if (parentObj?.id === socialMediaId) {
          parentObj.status = status
        }
      })
      updateSocialMedia(socialMediaId, { status }, () => {
        cb()
      })
    }
  }

  const [socialMediaEditModalShow, setSocialMediaEditModalShow] =
    useState(false)
  const [currentSocialMedia, setCurrentSocialMedial] = useState(false)
  const [currentSocialMediaId, setCurrentSocialMediaId] = useState(false)

  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.SOCIAL_MEDIA_LINK)
    getAllSocialMedia()
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table-striped mb-0 text-center">
              <thead className="bg-dark text-white sticky">
                <tr>
                  {SocialMediaTableHead.length
                    ? SocialMediaTableHead.map((item, index) => {
                        return <th key={index} style={item.style}>{item.value}</th>
                      })
                    : null}
                  <th style={{width:"300px"}}>Status</th>
                  <th style={{width:"130px"}}>Action</th>
                </tr>
              </thead>
              <tbody className="table-striped">
                {loading ? (
                  new Array(4).fill(null).map((item, idx) => (
                    <React.Fragment key={idx}>
                      <SocialMediaListLoader />
                    </React.Fragment>
                  ))
                ) : socialmedia?.length ? (
                  socialmedia.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.web_link}</td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="form-check form-switch form-switch-md">
                              <SwitchCheckbox
                                status={item.status}
                                changeHandler={updateStatus}
                                id={item.id}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="icon-container">
                          <div>
                            <FaRegEdit
                              className="text-success icons"
                              id={`editIcons${index}`}
                              onClick={() => {
                                setCurrentSocialMedial({
                                  name: item.name,
                                  web_link: item.web_link,
                                })
                                setSocialMediaEditModalShow(true)
                                setCurrentSocialMediaId(item.id)
                              }}
                            />
                            <UncontrolledTooltip
                            target={`editIcons${index}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <h3>No Social Media Links Found</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {socialMediaEditModalShow ? (
        <SocialMediaEditFormModal
          modalShow={socialMediaEditModalShow}
          setModalShow={setSocialMediaEditModalShow}
          modalHeaderText="Edit Social Media Link"
          currentSelectedData={currentSocialMedia}
          currentSocialMediaId={currentSocialMediaId}
        />
      ) : null}
    </>
  )
}

const SocialMediaListLoader = () => {
  return (
    <tr>
      <td>
        <SkeletonLoader />
      </td>
      <td>
        <SkeletonLoader />
      </td>
      <td>
        <SkeletonLoader />
      </td>
      <td>
        <SkeletonLoader />
      </td>
    </tr>
  )
}

const mapStateToProps = state => {
  const { Language, SocialMedia } = state
  const { currentLanguageCode } = Language
  const { loading, socialmedia, metadata } = SocialMedia
  return { currentLanguageCode, loading, socialmedia, metadata }
}

export default connect(mapStateToProps, {
  setBreadcrumbItems,
  getAllSocialMedia,
  deleteSocialMedia,
  updateSocialMedia,
})(SocialMediaLinks)
