import { call, put, takeEvery } from "redux-saga/effects"
import { callSearchApi } from "./httpCalls.search"
import { searchError, searchSuccess } from "./actions"
import { SEARCH } from "./actionTypes"

function* searchData(action) {
  try {
    const { params, cb } = action.payload
    const response = yield call(callSearchApi, params)
    if (response?.success) {
      cb(response)
      yield put(searchSuccess(response?.data))
    } else {
      yield put(searchError())
    }
  } catch (error) {
    yield put(searchError())
  }
}

function* SearchSaga() {
  yield takeEvery(SEARCH, searchData)
}

export default SearchSaga
