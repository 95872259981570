import React from "react"

const FormButton = props => {
  const {
    customClass,
    loading,
    type,
    btnText,
    disabled = false,
    clickEvent = () => {},
  } = props
  return (
    <button
      className={`btn w-md waves-effect waves-light w-100 ${customClass} position-relative`}
      type={type}
      disabled={loading || disabled}
      onClick={clickEvent}
    >
      {loading && (
        <span className="spinner-border spinner-border-sm text-light d-inline-block me-3"></span>
      )}
      <span>{btnText}</span>
    </button>
  )
}

export default FormButton
