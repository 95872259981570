import { GET_INSURER_LIST_SUCCESS, SET_INSURER_LOADING } from "./actionTypes"

const initialState = {
  insurerList: [],
  insurerLoading: false,
  metadata: {},
}

const Insurer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSURER_LIST_SUCCESS:
      state = {
        ...state,
        insurerList: action.payload.data,
        metadata: action.payload.metadata,
      }
      break
    case SET_INSURER_LOADING:
      state = {
        ...state,
        insurerLoading: action.payload,
      }
  }
  return state
}

export default Insurer
