import {
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_ERROR,
} from "./actionTypes"

export const getAllProducts = () => {
  return {
    type: GET_ALL_PRODUCT,
  }
}

export const getAllProductsSuccess = response => {
  return {
    type: GET_ALL_PRODUCT_SUCCESS,
    payload: response,
  }
}

export const getAllProductsError = () => {
  return {
    type: GET_ALL_PRODUCT_ERROR,
  }
}
