import React from "react"
import { Modal } from "reactstrap"

const ConfirmationModal = props => {
  const {
    confirmBtnText,
    modalHeaderText,
    confirmAction,
    modalShow,
    setModalShow,
    loading,
  } = props
  return (
    <Modal isOpen={modalShow} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {/* Are you sure you want to Logout? */}
          {modalHeaderText}
        </h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body ">
        <button
          onClick={confirmAction}
          disabled={loading || false}
          className="btn btn-danger px-5 position-relative"
        >
          {loading && (
            <span
              className="spinner-border spinner-border-sm text-light position-absolute"
              style={{ left: "16px", top: "8px" }}
            ></span>
          )}
          {confirmBtnText}
        </button>
        <button
          onClick={() => setModalShow(false)}
          className="btn btn-primary mx-2 px-5"
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
