import {
  ADD_BIMABHAI_VIDEO,
  CHECK_BIMABHAI_VIDEO_ASSOCIATIONS,
  DELETE_BIMABHAI_VIDEO,
  FILTER_BIMABHAI_VIDEO,
  GET_ALL_BIMABHAI,
  GET_ALL_BIMABHAI_SUCCESS,
  GET_BIMABHAI,
  GET_BIMABHAI_SUCCESS,
  GET_PARENT_BIMABHAI_VIDEO_DATA,
  SET_BIMABHAI_LOADING,
  UPDATE_BIMABHAI_VIDEO,
} from "./actionTypes"

export const getAllBimabhai = params => ({
  type: GET_ALL_BIMABHAI,
  payload: params,
})

export const getAllBimabhaiSuccess = res => ({
  type: GET_ALL_BIMABHAI_SUCCESS,
  payload: res,
})

export const getBimabhai = (params, cb) => ({
  type: GET_BIMABHAI,
  payload: { params, cb },
})

export const getBimabhaiSuccess = res => ({
  type: GET_BIMABHAI_SUCCESS,
  payload: res,
})

export const addBimabhaiVideo = (data, cb) => ({
  type: ADD_BIMABHAI_VIDEO,
  payload: { data, cb },
})

export const updateBimabhaiVideo = (id, data, cb, params) => ({
  type: UPDATE_BIMABHAI_VIDEO,
  payload: { id, data, cb, params },
})

export const deleteBimabhaiVideo = (id, cb, isChild = false, params = {}) => ({
  type: DELETE_BIMABHAI_VIDEO,
  payload: { id, cb, isChild, params },
})

export const filterBimaBhaiVideo = (params, cb) => ({
  type: FILTER_BIMABHAI_VIDEO,
  payload: { params, cb },
})

export const getParentBimaBhaiVideoData = (parentBVId, cb) => ({
  type: GET_PARENT_BIMABHAI_VIDEO_DATA,
  payload: { parentBVId, cb },
})

export const checkAssociations = (childVideoId, cb) => ({
  type: CHECK_BIMABHAI_VIDEO_ASSOCIATIONS,
  payload: { childVideoId, cb },
})

export const setBimabhaiLoading = (val, key) => ({
  type: SET_BIMABHAI_LOADING,
  payload: { val, key },
})
