import React from "react"
import SkeletonLoader from "components/common/SkeletonLoader"

const CustomTableLoader = ({ col, row = 6 }) => {
  let recordLimit = row
  return new Array(recordLimit).fill(null).map((item, idx) => {
    return (
      <tr key={idx}>
        {new Array(col).fill(null).map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <td>
                <SkeletonLoader />
              </td>
            </React.Fragment>
          )
        })}
      </tr>
    )
  })
}

export default CustomTableLoader
