import { ADD_MEDIA, ADD_MEDIA_ERROR, ADD_MEDIA_SUCCESS } from "./actionTypes"

const initialState = {
  file: {},
  metadata: {},
  loading: false,
}

const Media = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEDIA:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_MEDIA_SUCCESS:
      state = {
        ...state,
        file: action.payload?.data,
        loading: false,
      }
      break
    case ADD_MEDIA_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Media
