import {
  GET_ALL_PARENT_FAQS_SUCCESS,
  GET_PARENT_FAQ_SUCCESS,
  SET_CHILD_FAQ_LOADER,
  SET_DELETE_FAQS_LOADER,
  SET_GET_CHILD_FAQ_LOADER,
  SET_PARENT_FAQ_LOADING,
  SET_UPDATE_PARENT_FAQ_LOADER,
  UPDATE_CHILD_FAQ_LOADER,
} from "./actionTypes"

const initialState = {
  parentFAQList: [],
  parentFAQMetadata: {},
  getParentLoader: false,
  updateParentLoader: false,
  addChildFAQLoader: false,
  deleteFAQLoader: false,
  getChildFAQLoader: false,
  updateChildFaqLoader: false,
  parentFAQ: {},
}

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARENT_FAQS_SUCCESS:
      state = {
        ...state,
        parentFAQList: action.payload.parentFAQList,
        parentFAQMetadata: action.payload.parentFAQMetadata,
      }
      break
    case GET_PARENT_FAQ_SUCCESS:
      state = {
        ...state,
        parentFAQ: action.payload,
      }
      break
    case SET_PARENT_FAQ_LOADING:
      state = {
        ...state,
        getParentLoader: action.payload,
      }
      break
    case SET_UPDATE_PARENT_FAQ_LOADER:
      state = {
        ...state,
        updateParentLoader: action.payload,
      }
      break
    case SET_CHILD_FAQ_LOADER:
      state = {
        ...state,
        addChildFAQLoader: action.payload,
      }
      break
    case SET_DELETE_FAQS_LOADER:
      state = {
        ...state,
        deleteFAQLoader: action.payload,
      }
      break
    case SET_GET_CHILD_FAQ_LOADER:
      state = {
        ...state,
        getChildFAQLoader: action.payload,
      }
      break
    case UPDATE_CHILD_FAQ_LOADER:
      state = {
        ...state,
        updateChildFaqLoader: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default faqReducer
