import { DELETE_SOCIAL_MEDIA, GET_ALL_SOCIAL_MEDIAS } from "./actionTypes"
import { GET_ALL_SOCIAL_MEDIAS_SUCCESS } from "./actionTypes"
import { SET_LOADING_SOCIAL_MEDIAS } from "./actionTypes"
import { ADD_SOCIAL_MEDIA } from "./actionTypes"

const initialState = {
  socialmedia: [],
  metadata: {},
  loading: false,
}

const socialReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SOCIAL_MEDIAS:
      state = {
        ...state,
        loading: true,
        socialmedia: [],
        metadata: {},
      }
      break
    case GET_ALL_SOCIAL_MEDIAS_SUCCESS:
      state = {
        ...state,
        socialmedia: action.payload?.data,
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case ADD_SOCIAL_MEDIA:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_SOCIAL_MEDIA:
      state = {
        ...state,
        loading: true,
      }
      break
    case SET_LOADING_SOCIAL_MEDIAS:
      state = {
        ...state,
        loading: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default socialReducer
