export const ADD_TEXT_SECTION = "ADD_TEXT_SECTION"
export const ADD_TEXT_SECTION_SUCCESS = "ADD_TEXT_SECTION_SUCCESS"
export const ADD_TEXT_SECTION_ERROR = "ADD_TEXT_SECTION_ERROR"

export const ADD_VIDEO_SECTION = "ADD_VIDEO_SECTION"
export const ADD_VIDEO_SECTION_SUCCESS = "ADD_VIDEO_SECTION_SUCCESS"
export const ADD_VIDEO_SECTION_ERROR = "ADD_VIDEO_SECTION_ERROR"

export const GET_ALL_LEAD_SECTION = "GET_ALL_LEAD_SECTION"
export const GET_ALL_LEAD_SECTION_SUCCESS = "GET_ALL_LEAD_SECTION_SUCCESS"
export const GET_ALL_LEAD_SECTION_ERROR = "GET_ALL_LEAD_SECTION_ERROR"

export const GET_SINGLE_LEAD_SECTION = "GET_SINGLE_LEAD_SECTION"
export const GET_SINGLE_LEAD_SECTION_SUCCESS = "GET_SINGLE_LEAD_SECTION_SUCCESS"
export const GET_SINGLE_LEAD_SECTION_ERROR = "GET_SINGLE_LEAD_SECTION_ERROR"

export const DELETE_LEAD_SECTION = "DELETE_LEAD_SECTION"
export const DELETE_LEAD_SECTION_SUCCESS = "DELETE_LEAD_SECTION_SUCCESS"
export const DELETE_LEAD_SECTION_ERROR = "DELETE_LEAD_SECTION_ERROR"

export const SINGLE_SECTION = "SINGLE_SECTION"
export const SINGLE_SECTION_SUCCESS = "SINGLE_SECTION_SUCCESS"
export const SINGLE_SECTION_ERROR = "SINGLE_SECTION_ERROR"

export const UPDATE_TEXT_SECTION = "UPDATE_TEXT_SECTION"
export const UPDATE_TEXT_SECTION_SUCCESS = "UPDATE_TEXT_SECTION_SUCCESS"
export const UPDATE_TEXT_SECTION_ERROR = "UPDATE_TEXT_SECTION_ERROR"

export const UPDATE_VIDEO_SECTION = "UPDATE_VIDEO_SECTION"
export const UPDATE_VIDEO_SECTION_SUCCESS = "UPDATE_VIDEO_SECTION_SUCCESS"
export const UPDATE_VIDEO_SECTION_ERROR = "UPDATE_VIDEO_SECTION_ERROR"
