import TableFooter from "components/common/TableFooter"
import FormButton from "components/form-components/FormButton"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { SEARCH_WARNING_MESSAGE } from "helpers/constants/messages.constants"
import React from "react"
import { MdInfoOutline } from "react-icons/md"
import { Link } from "react-router-dom"
import ParentFAQListing from "./ParentFAQListing"

const FAQTabBody = ({
  getParentLoader,
  parentFAQList,
  setShowFeatureModal,
  setShowDeleteModal,
  setShowSettingModal,
  parentFAQMetadata,
  currentPage,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
  setcurrentSelectedParentFAQ,
  isFeatureTab,
  setCurrentPageOnFeaturedTab,
  currentPageOnFeaturedTab,
  searchText,
  setSearchText,
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col-3">
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={e => {
              isFeatureTab ? setCurrentPageOnFeaturedTab(0) : setCurrentPage(0)
              setSearchText(e.target.value)
            }}
          />
        </div>
        <div className="col-6 d-flex align-items-center p-0">
          {searchText && parentFAQList.length ? (
            <>
              <MdInfoOutline className="text-warning search-info-icon" />
              <span className="text-warning ps-1 search-info-text">
                {SEARCH_WARNING_MESSAGE}
              </span>
            </>
          ) : null}
        </div>
        <div className="col-3">
          {isFeatureTab ? null : (
            <Link
              to={`/${ROUTES_PATH.PAGES}/${ROUTES_PATH.FAQS}/${ROUTES_PATH.ADD_FAQ}`}
            >
              <FormButton
                customClass="btn-dark"
                type="button"
                btnText="Add FAQ"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="table-responsive">
        <ParentFAQListing
          getParentLoader={getParentLoader}
          parentFAQList={parentFAQList}
          setShowFeatureModal={setShowFeatureModal}
          setShowDeleteModal={setShowDeleteModal}
          setShowSettingModal={setShowSettingModal}
          setcurrentSelectedParentFAQ={setcurrentSelectedParentFAQ}
          isFeatureTab={isFeatureTab}
        />
      </div>

      {parentFAQList?.length ? (
        <TableFooter
          metadata={parentFAQMetadata}
          currentPage={isFeatureTab ? currentPageOnFeaturedTab : currentPage}
          setCurrentPage={
            isFeatureTab ? setCurrentPageOnFeaturedTab : setCurrentPage
          }
          recordLimit={recordLimit}
          setrecordLimit={setRecordLimit}
        />
      ) : null}
    </>
  )
}

export default FAQTabBody
