import classNames from "classnames"
import CreateTable from "components/common/CreateTable"
import React from "react"
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const LeadTabs = ({
  products,
  setActiveTab,
  activeTab,
  thead,
  leadsLoading,
  leads,
}) => {
  if (products && !products.length) {
    return <h1>No Product Found</h1>
  }

  return (
    <>
      <Nav tabs>
        {products.map(item => {
          return (
            <NavItem key={item.id}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeTab === item.name,
                })}
                onClick={() => {
                  setActiveTab(item.name)
                }}
              >
                {item.name}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>

      <TabContent activeTab={activeTab} className="p-3 text-muted bg-white">
        {products.map(items => {
          return (
            <TabPane tabId={items.name} key={items.id}>
              <Row>
                <Col sm="12">
                  <CreateTable
                    thead={thead}
                    leadsLoading={leadsLoading}
                    leads={leads}
                  />
                </Col>
              </Row>
            </TabPane>
          )
        })}
      </TabContent>
    </>
  )
}

export default LeadTabs
