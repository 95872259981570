import Accordion from "components/common/modal-accordion/Accordion"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { IoChevronDown } from "react-icons/io5"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
} from "reactstrap"
import styles from "./Testimonial.module.css"

const TestimonialSettingModal = props => {
  const {
    modalHeaderText,
    modalShow,
    setModalShow,
    currentSelectedParentTestimonial,
    updateChildTestimonial,
    modalDeleteAction,
    languages,
    filterTestimonials,
    testimonialDeleteLoader,
    deleteCallBackAction,
  } = props

  const { setting_modal_body } = styles

  const [singlebtn, setSinglebtn] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(0)
  const [remainingLanguageArray, setRemainingLanguageArray] = useState([])
  const history = useHistory()

  useEffect(() => {
    if (!currentSelectedParentTestimonial) setModalShow(false)
  }, [])

  useEffect(() => {
    if (currentSelectedParentTestimonial?.testimonials) {
      let newArray = languages.filter(language => {
        return !currentSelectedParentTestimonial.testimonials.some(langObj => {
          return langObj.language_code === language.language_code
        })
      })
      setRemainingLanguageArray(newArray)
    }
  }, [currentSelectedParentTestimonial])

  return (
    <Modal isOpen={modalShow} centered={true} size="xl">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{modalHeaderText}</h5>
        <button
          type="button"
          onClick={() => {
            setModalShow(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className={`modal-body ${setting_modal_body}`}>
        {currentSelectedParentTestimonial ? (
          <>
            <div className="info-container">
              <div className="row">
                <div className="col-6" />
                <div className="col-2" />
                <div className="col-4 ">
                  {!currentSelectedParentTestimonial?.isfeatured &&
                    remainingLanguageArray?.length ? (
                    <Dropdown
                      isOpen={singlebtn}
                      toggle={() => setSinglebtn(!singlebtn)}
                      className=""
                    >
                      <DropdownToggle className="btn btn-dark w-100" caret>
                        Add Testimonial <IoChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="w-100">
                        {remainingLanguageArray.map((language, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              history.push(
                                `/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.ADD_TESTIMONIAL}/${language.language_code}/${currentSelectedParentTestimonial.id}`
                              )
                            }}
                          >
                            {language.name}
                            {` (${language.language_code})`}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="table-container mt-4">
              <div className="table-responsive">
                {currentSelectedParentTestimonial?.testimonials?.length ? (
                  currentSelectedParentTestimonial.testimonials.map(
                    (item, index) => {
                      return (
                        <Accordion
                          key={index}
                          status={item.status}
                          updateChild={updateChildTestimonial}
                          childId={item.id}
                          active={activeAccordion}
                          setActive={setActiveAccordion}
                          index={index + 1}
                          title={item?.name}
                          description={item?.feedback}
                          languageCode={item?.language_code}
                          deleteAction={modalDeleteAction}
                          deleteCallBackAction={deleteCallBackAction}
                          deleteLoader={testimonialDeleteLoader}
                          filterList={filterTestimonials}
                          showStatus={
                            currentSelectedParentTestimonial?.isfeatured
                              ? false
                              : true
                          }
                          showDelete={
                            currentSelectedParentTestimonial?.isfeatured
                              ? false
                              : true
                          }
                          showAdd={
                            currentSelectedParentTestimonial?.isfeatured
                              ? false
                              : true
                          }
                          deleteConfirmationText="You are About to Delete this Testimonial. Are you sure?"
                          editFormRoute={`/${ROUTES_PATH.TESTIMONIAL}/${ROUTES_PATH.EDIT_TESTIMONIAL}/${item?.language_code}/${item.id}/${currentSelectedParentTestimonial.id}`}
                        />
                      )
                    }
                  )
                ) : (
                  <h2 className="text-center mt-4">
                    No data available into the Testimonial list
                  </h2>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  )
}

export default TestimonialSettingModal
