import { BREADCRUM } from "helpers/constants/constants.helper"
import { getUserPreference } from "helpers/utils.helper"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  setBreadcrumbItems,
  getInsurerList,
  deleteInsurer,
} from "store/actions"
import InsurerList from "./InsurerList"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { INSURER_MESSAGES } from "helpers/constants/messages.constants"
const Insurer = ({
  setBreadcrumbItems,
  getInsurerList,
  insurerList,
  insurerLoading,
  metadata,
  deleteInsurer,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [recordLimit, setRecordLimit] = useState(
    getUserPreference().recordLimit
  )
  const getParentInsurerListParams = () => ({
    pageNum: currentPage,
    recordLimit: recordLimit,
  })
  const [searchText, setSearchText] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentSelectedInsurer, setCurrentSelectedInsurer] = useState(null)
  useEffect(() => {
    setBreadcrumbItems(BREADCRUM.INSURER)
  }, [])
  useEffect(() => {
    let timeout
    if (searchText) {
      const params = getParentInsurerListParams()
      timeout = setTimeout(() => {
        getInsurerList({
          ...params,
          searchText: searchText,
        })
      }, 500)
    } else {
      getInsurerList(getParentInsurerListParams())
    }
    return () => clearTimeout(timeout)
  }, [currentPage, searchText, recordLimit])

  const getInsurers = page => {
    // will fetch insurer list as per currentpage state
    if (currentPage !== 0) {
      // if last data is deleted on selected page then will fetch second last page
      setCurrentPage(page - 1)
    } else {
      getInsurerList(getParentInsurerListParams())
    }
  }
  return (
    <>
      <InsurerList
        searchText={searchText}
        setCurrentPage={setCurrentPage}
        setSearchText={setSearchText}
        insurerList={insurerList}
        insurerLoading={insurerLoading}
        metadata={metadata}
        recordLimit={recordLimit}
        setRecordLimit={setRecordLimit}
        currentPage={currentPage}
        setCurrentSelectedInsurer={setCurrentSelectedInsurer}
        setShowDeleteModal={setShowDeleteModal}
      />
      <ConfirmationModal
        confirmBtnText="Detele"
        modalHeaderText={INSURER_MESSAGES.DELETE_INSURER}
        confirmAction={() => {
          deleteInsurer(currentSelectedInsurer.id, () => {
            setShowDeleteModal(false)
            insurerList.length === 1
              ? getInsurers(currentPage)
              : getInsurerList(getParentInsurerListParams())
          })
        }}
        modalShow={showDeleteModal}
        setModalShow={setShowDeleteModal}
        loading={insurerLoading}
      />
    </>
  )
}
const mapStatetoProps = state => {
  const { insurerList, insurerLoading, metadata } = state.Insurer
  return { insurerList, insurerLoading, metadata }
}
export default connect(mapStatetoProps, {
  setBreadcrumbItems,
  getInsurerList,
  deleteInsurer,
})(Insurer)
