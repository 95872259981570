import { get } from "helpers/api.helper"

let SearchAboartController
export async function callSearchApi(params) {
  try {
    if (SearchAboartController) {
      SearchAboartController.abort()
    }
    SearchAboartController = new AbortController()
    const res = await get(
      "/search",
      { params },
      {
        headers: {
          signal: SearchAboartController.signal,
        },
      }
    )
    return res
  } catch (err) {
    return err
  }
}
