export const GET_ALL_ABOUTUS = "GET_ALL_ABOUTUS"
export const GET_ALL_ABOUTUS_SUCCESS = "GET_ALL_ABOUTUS_SUCCESS"
export const GET_ALL_ABOUTUS_ERROR = "GET_ALL_ABOUTUS_ERROR"

export const GET_ABOUTUS = "GET_ABOUTUS"
export const GET_ABOUTUS_SUCCESS = "GET_ABOUTUS_SUCCESS"
export const GET_ABOUTUS_ERROR = "GET_ABOUTUS_ERROR"

export const ADD_ABOUTUS = "ADD_ABOUTUS"
export const ADD_ABOUTUS_SUCCESS = "ADD_ABOUTUS_SUCCESS"
export const ADD_ABOUTUS_ERROR = "ADD_ABOUTUS_ERROR"

export const EDIT_ABOUTUS = "EDIT_ABOUTUS"
export const EDIT_ABOUTUS_SUCCESS = "EDIT_ABOUTUS_SUCCESS"
export const EDIT_ABOUTUS_ERROR = "EDIT_ABOUTUS_ERROR"

export const DELETE_ABOUTUS = "DELETE_ABOUTUS"
export const DELETE_ABOUTUS_SUCCESS = "DELETE_ABOUTUS_SUCCESS"
export const DELETE_ABOUTUS_ERROR = "DELETE_ABOUTUS_ERROR"
