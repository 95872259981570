import CustomTableLoader from "components/loader/CustomTableLoader"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { FaRegEdit } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import { useHistory } from "react-router-dom"
import { Table, UncontrolledTooltip } from "reactstrap"

const InsurerTableHead = [
  // { value: "Sr. No", id: "s_no", classNames: "text-start" },
  {
    value: "Insurance Company Name",
    id: "name",
    classNames: "text-start",
    style: { width: "350px" },
  },
  {
    value: "Insurance site",
    id: "url",
    classNames: "text-center",
    style: { width: "350px" },
  },
  {
    value: "Motor toll free number",
    id: "mobile",
    classNames: "text-center",
    style: { width: "250px" },
  },
  {
    value: "Health toll free number",
    id: "health_mobile",
    classNames: "text-center",
    style: { width: "250px" },
  },
  {
    value: "Claim help mail ID",
    id: "email",
    classNames: "text-center",
    style: { width: "250px" },
  },
]
const InsurerTable = ({
  insuereList,
  insurerLoading,
  setCurrentSelectedInsurer,
  setShowDeleteModal,
}) => {
  const history = useHistory()
  return (
    <Table className="table-striped mb-0">
      <thead className="bg-dark text-white sticky">
        <tr className="text-center">
          {InsurerTableHead.map((item, index) => {
            return (
              <th
                key={index}
                className={item.classNames || null}
                style={item.style}
              >
                {item.value}
              </th>
            )
          })}
          <th className="action-tab">Action</th>
        </tr>
      </thead>
      <tbody className="table-striped ">
        {insurerLoading ? (
          <CustomTableLoader col={4} />
        ) : insuereList.length ? (
          insuereList.map((insurer, index) => (
            <tr key={index}>
              <td className="text-start">{insurer.name ?? "--"}</td>
              <td className="text-center">{insurer.url ?? "--"}</td>
              <td className="text-center">{insurer.phone ?? "--"}</td>
              <td className="text-center">{insurer.health_phone ?? "--"}</td>
              <td className="text-center">{insurer.email ?? "--"}</td>
              <td className="icon-container">
                <div className=" justify-content-center">
                  <FaRegEdit
                    className="text-success icons"
                    id={`editIcon${index}`}
                    onClick={() => {
                      history.push(
                        `/${ROUTES_PATH.PAGES}/${ROUTES_PATH.INSURER}/${ROUTES_PATH.EDIT_INSURER}/${insurer.id}`
                      )
                    }}
                  />
                  <UncontrolledTooltip target={`editIcon${index}`}>
                    Edit
                  </UncontrolledTooltip>
                  <MdDelete
                    id={`deleteIcon${index}`}
                    onClick={() => {
                      setShowDeleteModal(true)
                      setCurrentSelectedInsurer(insurer)
                    }}
                    className="text-danger icons"
                  />
                  <UncontrolledTooltip target={`deleteIcon${index}`}>
                    Delete
                  </UncontrolledTooltip>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              <h3>No Insurer Found</h3>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default InsurerTable
