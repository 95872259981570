import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Link } from "react-router-dom"
import { MdFullscreen, MdMenu } from "react-icons/md"

// Import menuDropdown
import ProfileMenu from "../common/ProfileMenu"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "assets/images/logo-sm.png"
import logoLightPng from "assets/images/policyLeaderLogoNew.webp"
import logoDark from "assets/images/policyLeaderLogoNew.webp"
import { getUserPreference, setUserPreference } from "helpers/utils.helper"

const Header = () => {
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
    if (body.classList.contains("vertical-collpsed"))
      setUserPreference({ sideBarCollapse: true })
    else setUserPreference({ sideBarCollapse: false })
  }

  useEffect(() => {
    const usePreference = getUserPreference()
    if (
      usePreference?.sideBarCollapse &&
      !document.body.classList.contains("vertical-collpsed")
    )
      tToggle()
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="40" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <MdMenu />
            </button>
            {/* TODO: Remove this after Checking all feature */}
            {/* <div className="d-none d-sm-block">
              <Dropdown
                isOpen={createmenu}
                toggle={() => setCreateMenu(!createmenu)}
                className="d-inline-block"
              >
                <div className="dropdown dropdown-topbar pt-3 mt-1 d-inline-block">
                  <DropdownToggle className="btn btn-light" tag="button">
                    Create <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="#">
                      Action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Another action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Something else here
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem tag="a" href="#">
                      Separated link
                    </DropdownItem>
                  </DropdownMenu>
                </div>
              </Dropdown>
            </div> */}
          </div>
          <div className="d-flex">
            {/* TODO: Commented Global Search bar */}
            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </form> 
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>*/}
            {/* TODO: Commented Language DropDown */}
            {/* <LanguageDropdown /> */}
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <MdFullscreen className="font-size-24" />
              </button>
            </div>
            {/* TODO: Commented Notification */}
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
            {/* TODO: Commented Settings for Layout */}
            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-spin mdi-cog"></i>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

export default Header
