import {
  ADD_PRIVACY,
  ADD_PRIVACY_ERROR,
  ADD_PRIVACY_SUCCESS,
  DELETE_PRIVACY,
  DELETE_PRIVACY_ERROR,
  DELETE_PRIVACY_SUCCESS,
  EDIT_PRIVACY,
  EDIT_PRIVACY_ERROR,
  EDIT_PRIVACY_SUCCESS,
  GET_ALL_PRIVACY,
  GET_ALL_PRIVACY_ERROR,
  GET_ALL_PRIVACY_SUCCESS,
  GET_PRIVACY,
  GET_PRIVACY_ERROR,
  GET_PRIVACY_SUCCESS,
} from "./actionTypes"

const initialState = {
  privacy: {},
  metadata: {},
  loading: false,
}

const Privacy = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRIVACY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ALL_PRIVACY_SUCCESS:
      state = {
        ...state,
        privacy: action.payload?.data,
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case GET_ALL_PRIVACY_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_PRIVACY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PRIVACY_SUCCESS:
      state = {
        ...state,
        privacy: action.payload?.data[0],
        metadata: action.payload?.metadata,
        loading: false,
      }
      break
    case GET_PRIVACY_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_PRIVACY:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_PRIVACY_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_PRIVACY_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case EDIT_PRIVACY:
      state = {
        ...state,
        loading: true,
      }
      break
    case EDIT_PRIVACY_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case EDIT_PRIVACY_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_PRIVACY:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_PRIVACY_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case DELETE_PRIVACY_ERROR:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Privacy
