export const GET_PARENT_BLOG_LIST = "GET_PARENT_BLOG_LIST"
export const GET_PARENT_BLOG_LIST_SUCCESS = "GET_PARENT_BLOG_LIST_SUCCESS"

export const GET_BLOG_DATA = "GET_BLOG_DATA"

export const IS_UNIQUE_BLOG_SLUG = "IS_UNIQUE_BLOG_SLUG"

export const ADD_PARENT_BLOG = "ADD_PARENT_BLOG"

export const UPDATE_CHILD_BLOG = "UPDATE_CHILD_BLOG"

export const DELETE_BLOG = "DELETE_BLOG"

export const SET_BLOG_LOADER = "SET_BLOG_LOADER"

export const LOADER_STATE = {
  PARENT_LOADER: "parentBlogLoader",
  BLOG_FORM_SUBMIT_LOADER: "blogFormSubmitLoader",
  BLOG_DELETE_LOADER: "blogDeleteLoader",
  BLOG_UPDATE_LOADER: "blogUpdateLoader",
}
