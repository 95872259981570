import TableFooter from "components/common/TableFooter"
import FormButton from "components/form-components/FormButton"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { SEARCH_WARNING_MESSAGE } from "helpers/constants/messages.constants"
import React from "react"
import { Link } from "react-router-dom"
import ParentBannerListing from "./ParentBannerListing"
import {MdInfoOutline } from 'react-icons/md';

const BannerTab = ({
  bannerList,
  isFeaturedTab = false,
  parentBannerMetadata,
  currentPage,
  setCurrentPage,
  recordLimit,
  setRecordLimit,
  setShowSettingModal,
  setCurrentSelectedParentBanner,
  parentLoader,
  setShowFeatureModal,
  setShowDeleteModal,
  setCurrentPageOnFeaturedTab,
  currentPageOnFeaturedTab,
  searchText,
  setSearchText,
}) => {
  return (
    <>
      <div className="row mb-2">
        <div className="col-3">
          <input
            className="form-control"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={e => {
              isFeaturedTab ? setCurrentPageOnFeaturedTab(0) : setCurrentPage(0)
              setSearchText(e.target.value)
            }}
          />
        </div>
        <div className="col-6 d-flex align-items-center p-0">
          {searchText && bannerList.length ? (
            <>
              <MdInfoOutline className="text-warning" />
              <span className="text-warning ps-1 search-info-text">
                {SEARCH_WARNING_MESSAGE}
              </span>
            </>
          ) : null}
        </div>
        <div className="col-3">
          <Link to={`/${ROUTES_PATH.BANNER}/${ROUTES_PATH.ADD_BANNER}`}>
            <FormButton 
            customClass="btn-dark"
            btnText="Add Banner"
            type="button"
            />
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <ParentBannerListing
          bannerList={bannerList}
          setShowSettingModal={setShowSettingModal}
          isFeaturedTab={isFeaturedTab}
          setCurrentSelectedParentBanner={setCurrentSelectedParentBanner}
          getParentLoader={parentLoader}
          setShowFeatureModal={setShowFeatureModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      </div>
      {bannerList?.length ? (
        <TableFooter
          metadata={parentBannerMetadata}
          currentPage={isFeaturedTab ? currentPageOnFeaturedTab : currentPage}
          setCurrentPage={
            isFeaturedTab ? setCurrentPageOnFeaturedTab : setCurrentPage
          }
          recordLimit={recordLimit}
          setrecordLimit={setRecordLimit}
        />
      ) : null}
    </>
  )
}

export default BannerTab
