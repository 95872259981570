import {
  ADD_CONTACT,
  DELETE_CONTACT,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_CONTACT,
  SET_LOADING_CONTACTS,
  UPDATE_CONTACT,
} from "./actionTypes"

export const getAllContacts = params => ({
  type: GET_ALL_CONTACTS,
  payload: params,
})

export const getAllContactsSuccess = data => ({
  type: GET_ALL_CONTACTS_SUCCESS,
  payload: data,
})

export const getContact = (contactUsId, cb) => ({
  type: GET_CONTACT,
  payload: { contactUsId, cb },
})

export const addContact = (data, cb) => ({
  type: ADD_CONTACT,
  payload: { data, cb },
})

export const updateContact = (contactUsId, data, cb) => ({
  type: UPDATE_CONTACT,
  payload: { contactUsId, data, cb },
})

export const deleteContact = (contactIdList, cb) => ({
  type: DELETE_CONTACT,
  payload: { contactIdList, cb },
})

export const setLoading = (loading_key, loading_value) => ({
  type: SET_LOADING_CONTACTS,
  payload: { loading_key, loading_value },
})
