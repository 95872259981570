import React from "react"

const ShowEntries = ({ currentPage = 1, recordLimit = 5, metadata }) => {
  let startIndex = currentPage * recordLimit + 1
  let endIndex = currentPage * recordLimit + recordLimit
  return (
    <p className="page_entries">
      Show Entries: {startIndex}-
      {endIndex > metadata?.totalRecords ? metadata?.totalRecords : endIndex}
    </p>
  )
}

export default ShowEntries
