import {
  BIMA_BHAI_VIDEOS_URL,
  FILTER_PATH_URL,
  FIND_ASSOCIATIONS,
  PARENT_PATH_URL,
} from "helpers/api-end-points.constant.helper"
import { del, get, patch, post } from "helpers/api.helper"

export async function callGetAllBimabhaiApi(params) {
  try {
    const res = await get(BIMA_BHAI_VIDEOS_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function callGetBimabhaiApi(params) {
  try {
    const res = await get(`${BIMA_BHAI_VIDEOS_URL}/${params}`)
    return res
  } catch (err) {
    return err
  }
}

export async function callAddBimabhaiVideo(data) {
  try {
    const res = await post(BIMA_BHAI_VIDEOS_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function callUpdateBimabhaiVideo(data) {
  try {
    const { changes, id } = data
    const res = await patch(`${BIMA_BHAI_VIDEOS_URL}/${id}`, { changes })
    return res
  } catch (err) {
    return err
  }
}

export async function callDeleteBimabhaiVideo(bimaBhaiId, isChild) {
  try {
    const URL = isChild ? `${BIMA_BHAI_VIDEOS_URL}/child` : BIMA_BHAI_VIDEOS_URL
    const res = await del(URL, {
      data: { ids: bimaBhaiId },
    })
    return res
  } catch (err) {
    return err
  }
}

export async function callFilterBimaBhaiVideoApi(params) {
  try {
    const res = await get(`${BIMA_BHAI_VIDEOS_URL}${FILTER_PATH_URL}`, {
      params,
    })
    return res
  } catch (err) {
    return err
  }
}

export async function getParentBimaBhaiVideoDataApi(parentBVId) {
  try {
    const res = await get(
      `${BIMA_BHAI_VIDEOS_URL}${PARENT_PATH_URL}/${parentBVId}`
    )
    return res
  } catch (err) {
    return err
  }
}

export async function checkBimabhaiVideoAssociations(childVideoId) {
  try {
    const res = await get(
      `${BIMA_BHAI_VIDEOS_URL}${FIND_ASSOCIATIONS}/${childVideoId}`
    )
    return res
  } catch (err) {
    return err
  }
}
