import {
  ADD_CHILD_FAQ,
  UPDATE_CHILD_FAQ,
  UPDATE_CHILD_FAQ_LOADER,
  GET_ALL_PARENT_FAQS,
  GET_ALL_PARENT_FAQS_SUCCESS,
  GET_CHILD_FAQ,
  SET_CHILD_FAQ_LOADER,
  SET_GET_CHILD_FAQ_LOADER,
  SET_PARENT_FAQ_LOADING,
  SET_UPDATE_PARENT_FAQ_LOADER,
  UPDATE_PARENT_FAQ,
  DELETE_FAQS,
  SET_DELETE_FAQS_LOADER,
  GET_PARENT_FAQ,
  GET_PARENT_FAQ_SUCCESS,
} from "./actionTypes"

export const getAllParentFaqs = params => {
  return {
    type: GET_ALL_PARENT_FAQS,
    payload: params,
  }
}
export const getParentFaq = (parentFaqId, cb) => {
  return {
    type: GET_PARENT_FAQ,
    payload: { parentFaqId, cb },
  }
}
export const getParentFaqSuccess = parentFAQ => ({
  type: GET_PARENT_FAQ_SUCCESS,
  payload: { parentFAQ },
})
export const getAllParentFaqsSuccess = (parentFAQList, parentFAQMetadata) => ({
  type: GET_ALL_PARENT_FAQS_SUCCESS,
  payload: { parentFAQList, parentFAQMetadata },
})

export const setParentFaqLoader = loading => ({
  type: SET_PARENT_FAQ_LOADING,
  payload: loading,
})

export const updateParentFAQ = (data, cb) => ({
  type: UPDATE_PARENT_FAQ,
  payload: { data, cb },
})

export const setUpdateParentFAQLoader = loading => ({
  type: SET_UPDATE_PARENT_FAQ_LOADER,
  payload: loading,
})

export const addFAQ = (data, cb) => ({
  type: ADD_CHILD_FAQ,
  payload: { data, cb },
})

export const setChildFAQLoader = loading => ({
  type: SET_CHILD_FAQ_LOADER,
  payload: loading,
})

export const deleteFaq = (faqId, cb) => ({
  type: DELETE_FAQS,
  payload: { faqId, cb },
})

export const setDeleteFaqLoader = loading => ({
  type: SET_DELETE_FAQS_LOADER,
  payload: loading,
})

export const getChildFaq = (childFaqId, cb) => ({
  type: GET_CHILD_FAQ,
  payload: { childFaqId, cb },
})

export const setChildFaqLoader = loading => ({
  type: SET_GET_CHILD_FAQ_LOADER,
  payload: loading,
})

export const updateChildFaq = (data, cb, params) => ({
  type: UPDATE_CHILD_FAQ,
  payload: { data, cb, params },
})

export const setUpdateChildFaqLoader = loading => ({
  type: UPDATE_CHILD_FAQ_LOADER,
  payload: loading,
})
