import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import { DOWNLOAD_LEADS, GET_ALL_LEADS } from "./actionTypes"
import { getAllLeadsError, getAllLeadsSuccess } from "./actions"
import { downloadleads, getleads } from "./httpCalls.leads"

function* getAllLeads(action) {
  try {
    const { payload } = action
    const response = yield call(getleads, payload)
    if (response?.success) {
      yield put(getAllLeadsSuccess(response))
    } else {
      yield put(getAllLeadsError(response))
    }
  } catch (error) {
    yield put(getAllLeadsError(error))
  }
}

function* downloadLeads(action) {
  try {
    const response = yield call(downloadleads, action.payload)
    if (response.res?.success) {
      var mediaType =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
      const link = document.createElement("a")
      link.href = mediaType + response.res.data[0]
      link.download = `${action.payload?.activeTab || "KIBPL"}_leads.xlsx`
      document.body.appendChild(link)
      link.click()
    } else {
      toast.error(response?.res?.message || "Not found")
    }
  } catch (error) {
    console.log(error)
  }
  action.payload.cb()
}

function* leadSaga() {
  yield takeEvery(GET_ALL_LEADS, getAllLeads)
  yield takeEvery(DOWNLOAD_LEADS, downloadLeads)
}

export default leadSaga
