import React, { useEffect, useState } from "react"
import Select from "react-select"

const CategoryDropDown = props => {
  const { options, setFieldValue, name, value, disabled } = props

  const [currentCategory, setCurrentCategory] = useState([])

  useEffect(() => {
    if (value) setFieldValue(name, value)
  }, [])
  useEffect(() => {
    if (value) {
      setCurrentCategory(
        options.find(category => category.value === value) || options[0]
      )
    }
  }, [value])

  useEffect(() => {
    setFieldValue(name, currentCategory?.value)
  }, [currentCategory])

  return (
    <>
      <Select
        value={currentCategory}
        name={name}
        onChange={e => setCurrentCategory(e)}
        options={options}
        classNamePrefix="select2-selection"
        defaultSelectValue={options[0]}
        isDisabled={disabled}
      />
    </>
  )
}

export default CategoryDropDown
