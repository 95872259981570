import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  GET_ALL_PRIVACY,
  GET_PRIVACY,
  ADD_PRIVACY,
  EDIT_PRIVACY,
  DELETE_PRIVACY,
} from "./actionTypes"
import {
  addPrivacyError,
  addPrivacySuccess,
  deletePrivacyError,
  deletePrivacySuccess,
  editPrivacyError,
  editPrivacySuccess,
  getAllPrivacyError,
  getAllPrivacySuccess,
  getPrivacyError,
  getPrivacySuccess,
} from "./actions"
import {
  addPrivacy,
  deletePrivacy,
  editPrivacy,
  getPrivacy,
  singlePrivacy,
} from "./httpCalls.privacy"
import { SUCCESS_TOAST } from "helpers/constants/constants.helper"

function* getAllPrivacy(action) {
  try {
    const { payload } = action
    const response = yield call(getPrivacy, payload)
    if (response?.success) {
      yield put(getAllPrivacySuccess(response))
    } else {
      yield put(getAllPrivacyError(response))
    }
  } catch (error) {
    yield put(getAllPrivacyError(error))
  }
}

function* getSinglePrivacy(action) {
  try {
    const { payload } = action
    const response = yield call(singlePrivacy, payload)
    if (response?.success) {
      yield put(getPrivacySuccess(response))
    } else {
      yield put(getPrivacyError(response))
    }
  } catch (error) {
    yield put(getPrivacyError(error))
  }
}

function* AddPrivacy(action) {
  try {
    const { payload, cb } = action
    const response = yield call(addPrivacy, payload)
    if (response?.success) {
      toast.success(response?.message, { toastId: SUCCESS_TOAST.ADD_PRIVACY })
      cb()
      yield put(addPrivacySuccess(response))
    } else {
      yield put(addPrivacyError(response))
    }
  } catch (error) {
    yield put(addPrivacyError(error))
  }
}

function* EditPrivacy(action) {
  try {
    const { payload } = action
    const response = yield call(editPrivacy, payload)
    if (response?.success) {
      toast.success(response?.message, { toastId: SUCCESS_TOAST.EDIT_PRIVACY })
      yield put(editPrivacySuccess(response))
    } else {
      yield put(editPrivacyError(response))
    }
  } catch (error) {
    yield put(editPrivacyError(error))
  }
}

function* DeletePrivacy(action) {
  try {
    const { payload, cb } = action
    const response = yield call(deletePrivacy, payload)
    if (response?.success) {
      toast.success(response?.message, {
        toastId: SUCCESS_TOAST.DELETE_PRIVACY,
      })
      cb()
      yield put(deletePrivacySuccess(response))
    } else {
      yield put(deletePrivacyError(response))
    }
  } catch (error) {
    yield put(deletePrivacyError(error))
  }
}

function* PrivacySaga() {
  yield takeEvery(GET_ALL_PRIVACY, getAllPrivacy)
  yield takeEvery(GET_PRIVACY, getSinglePrivacy)
  yield takeEvery(ADD_PRIVACY, AddPrivacy)
  yield takeEvery(EDIT_PRIVACY, EditPrivacy)
  yield takeEvery(DELETE_PRIVACY, DeletePrivacy)
}

export default PrivacySaga
