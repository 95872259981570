import React, { useCallback, useEffect, useState } from "react"
import { UncontrolledTooltip } from "reactstrap"
import SelectTestimonialModal from "./SelectTestimonialModal"
import styles from "./Testimonial.module.css"
import waveImage from "assets/svg/wave.svg"
import SkeletonLoader from "components/common/SkeletonLoader"
import { TESTIMONIALS_MESSAGES } from "helpers/constants/messages.constants"
import FormButton from "components/form-components/FormButton"
import { MdSettings, MdClose } from "react-icons/md"

const TestimonialFeaturedList = props => {
  const {
    parentTestimonialList,
    getAllTestimonial,
    filteredTestimonialList,
    getStars,
    addToFeatureAction,
    activeTab,
    setShowSettingModal,
    setcurrentSelectedParentTestimonial,
    FeaturedLoader,
    SelectModalLoader,
    TestmonialMetadata,
  } = props
  const [isNewSlideActive, setIsNewSlideActive] = useState(false)
  const [selectTestimonialModal, setSelectTestimonialModal] = useState(false)
  const [selectedTestimonials, setSelectedTestimonials] = useState([])
  const [modalCurrentPage, setModalCurrentPage] = useState(0)
  const [featuredTestimonialListing, setFeaturedTestimonialListing] = useState(
    []
  )

  const FilterNewArray = () => {
    let newList = []
    if (filteredTestimonialList?.length) {
      filteredTestimonialList.forEach(() =>
        newList.push(filteredTestimonialList.splice(0, 3))
      )
      setFeaturedTestimonialListing(newList)
    }
  }

  const addRemovedFeature = (
    testimonialArray = [],
    featuredStatus,
    cb = () => { }
  ) => {
    if (testimonialArray?.length) {
      let ids = testimonialArray.map(testimonial => {
        return testimonial?.id || ""
      })
      let requestObj = {
        parent_id: ids,
        isfeatured: featuredStatus,
      }
      addToFeatureAction(requestObj, () => {
        setSelectedTestimonials([])
        setSelectTestimonialModal(false)
        getAllTestimonial(
          {
            pageNum: 0,
            recordLimit: 10,
            isfeatured: true,
          },
          true
        )
        cb()
        isNewSlideActive && setIsNewSlideActive(false)
      })
    }
  }

  const getAllTestimonialParams = () => ({
    pageNum: modalCurrentPage,
    recordLimit: 10,
    isfeatured: false,
    isAllLanguage: true,
  })

  const getAllTestimonialAPI = useCallback(() => {
    getAllTestimonial(
      {
        pageNum: modalCurrentPage,
        recordLimit: 10,
        isfeatured: false,
        isAllLanguage: true,
      },
      false
    )
  }, [])

  useEffect(() => {
    if (selectTestimonialModal) {
      getAllTestimonial(getAllTestimonialParams(), false)
    }
  }, [selectTestimonialModal, modalCurrentPage])

  useEffect(() => {
    if (filteredTestimonialList?.length && activeTab === 1) {
      FilterNewArray()
    } else {
      setFeaturedTestimonialListing([])
    }
  }, [filteredTestimonialList])

  return (
    <>
      <div>
        <div className="row mb-2">
          <div className="col-6" />
          <div className="col-3" />
          <div className="col-3">
            <FormButton
              customClass="btn-dark"
              btnText="Add new Slide"
              type="button"
              clickEvent={() => setSelectTestimonialModal(true)}
              disabled={isNewSlideActive}
            />
          </div>
        </div>
      </div>
      <div>
        {FeaturedLoader ? (
          <div className={`${styles.slide_container} ${styles.slidewrapper}`}>
            <div
              className={`d-flex justify-content-between py-3 mb-3 gap-3  ${styles.featured_list}`}
            >
              {new Array(3).fill(0).map((value, idx) => {
                return (
                  <div key={idx} className={styles.loading_card}>
                    <SkeletonLoader customClass="h-100" />
                  </div>
                )
              })}
            </div>
          </div>
        ) : featuredTestimonialListing?.length ? (
          featuredTestimonialListing.map((slide, idx) => {
            return (
              <FeaturedTestimonialView
                featuredTestimonialListing={featuredTestimonialListing}
                key={idx}
                getStars={getStars}
                waveImage={waveImage}
                idx={idx}
                slide={slide}
                loader={SelectModalLoader}
                addRemovedFeature={addRemovedFeature}
                setShowSettingModal={setShowSettingModal}
                setcurrentSelectedParentTestimonial={
                  setcurrentSelectedParentTestimonial
                }
              />
            )
          })
        ) : (
          <div className={`${styles.slide_container} p-3 text-dark`}>
            <h3>Added Testimonial in feature</h3>
          </div>
        )}
      </div>
      <SelectTestimonialModal
        setModalShow={setSelectTestimonialModal}
        modalShow={selectTestimonialModal}
        selectFeatureList={parentTestimonialList}
        getStars={getStars}
        setSelectedTestimonials={setSelectedTestimonials}
        selectedTestimonials={selectedTestimonials}
        addToFeature={addRemovedFeature}
        loader={SelectModalLoader}
        setCurrentPage={setModalCurrentPage}
        TestmonialMetadata={TestmonialMetadata}
        modalHeaderText="Select Testimonials"
        getAllTestimonialAPI={getAllTestimonialAPI}
        isfeatured={activeTab === 1 ? true : false}
      />
    </>
  )
}

const FeaturedTestimonialView = ({
  addRemovedFeature,
  getStars,
  slide,
  idx,
  setShowSettingModal,
  setcurrentSelectedParentTestimonial,
}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  return (
    <>
      <div className={styles.slide_container}>
        <div className="d-flex align-items-center gap-3 mb-3">
          <p className={`${styles.slide_name} text-dark`}>Slide {idx + 1}</p>
          {isDeleteModal ? (
            <div className="d-flex gap-2">
              <button
                className="btn btn-danger"
                id={`deletebtn${idx}`}
                onClick={() => {
                  addRemovedFeature(slide, false, setIsDeleteModal(false))
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-dark"
                onClick={() => {
                  setIsDeleteModal(false)
                }}
              >
                Cancel
              </button>

              <UncontrolledTooltip placement="right" target={`deletebtn${idx}`}>
                {TESTIMONIALS_MESSAGES.REMOVE_FROM_FEATURED}
              </UncontrolledTooltip>
            </div>
          ) : (
            <button
              className={`btn btn-danger text-white align-items-center ${styles.remove_btn}`}
              style={{ lineHeight: 0.7 }}
              onClick={() => setIsDeleteModal(true)}
            >
              <MdClose
                className="text-white"
                style={{ fontSize: "18px" }}
              />{" "}
              Remove
            </button>
          )}
        </div>
        <div
          className={`d-flex flex-wrap gap-3 justify-content-around ${styles.testimonial_container}`}
        >
          {slide?.length
            ? slide.map((testimonial, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.card} position-relative`}
                >
                  <div
                    className={`position-absolute ${styles.card_settingIcon}`}
                  >
                    <MdSettings
                      className="text-success icons"
                      id={`settingsTooltip${index}`}
                      onClick={() => {
                        setcurrentSelectedParentTestimonial(testimonial)
                        setShowSettingModal(true)
                      }}
                    />
                    <UncontrolledTooltip target={`settingsTooltip${index}`}>
                      Settings
                    </UncontrolledTooltip>
                  </div>
                  <div className={`${styles.data} position-relative`}>
                    <p className={`${styles.copy}`}>
                      {testimonial?.testimonials?.length
                        ? testimonial?.testimonials[0].feedback
                        : null}
                    </p>
                    <div className={`${styles.star_container}`}>
                      {getStars(testimonial?.ratings)}
                    </div>
                    <h3 className={`${styles.customer}`}>
                      {testimonial?.label}
                    </h3>
                  </div>
                </div>
              )
            })
            : null}
        </div>
      </div>
    </>
  )
}

export default TestimonialFeaturedList
