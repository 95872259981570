import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { ToastContainer } from "react-toastify"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/vertical-layout"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
// Import toastify css
import "react-toastify/dist/ReactToastify.min.css"
// Custom scss
import "assets/scss/custom.scss"
import InitialApiLoader from "components/vertical-layout/InitialApiLoader"
import { useSelector } from "react-redux"

const App = () => {
  const Layout = VerticalLayout
  const token = useSelector(({ Login }) => Login?.userInfo?.access_token)

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact={route.exact}
              enum_string={route.enum}
            />
          ))}

          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {/* TODO: Add 400 Page Later */}
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      {token && <InitialApiLoader />}
    </React.Fragment>
  )
}

export default App
