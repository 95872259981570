import {
  BANNER_PARENT_URL,
  BANNER_URL,
} from "helpers/api-end-points.constant.helper"
import { del, get, post, put } from "helpers/api.helper"

export async function getBannerList(params) {
  try {
    const res = await get(BANNER_URL, { params })
    return res
  } catch (err) {
    return err
  }
}

export async function addBanner(data) {
  try {
    const res = await post(BANNER_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function updateBanner(data) {
  try {
    const res = await put(BANNER_URL, data)
    return res
  } catch (err) {
    return err
  }
}

export async function getParentBanner(id) {
  try {
    const res = await get(`${BANNER_PARENT_URL}/${id}`)
    return res
  } catch (err) {
    return err
  }
}

export async function getBanner(id) {
  try {
    const res = await get(`${BANNER_URL}/${id}`)
    return res
  } catch (err) {
    return err
  }
}

export async function deleteBanner(id, apiEndpoint = BANNER_URL) {
  try {
    const res = await del(`${apiEndpoint}/${id}`)
    return res
  } catch (err) {
    return err
  }
}
