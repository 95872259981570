export const GET_ALL_SOCIAL_MEDIAS = "GET_ALL_SOCIAL_MEDIAS"
export const GET_ALL_SOCIAL_MEDIAS_SUCCESS = "GET_ALL_SOCIAL_MEDIAS_SUCCESS"
export const GET_ALL_SOCIAL_MEDIAS_ERROR = "GET_ALL_SOCIAL_MEDIAS_ERROR"

export const ADD_SOCIAL_MEDIA = "ADD_SOCIAL_MEDIA"

export const UPDATE_SOCIAL_MEDIA = "UPDATE_SOCIAL_MEDIA"

export const DELETE_SOCIAL_MEDIA = "DELETE_SOCIAL_MEDIA"

export const SET_LOADING_SOCIAL_MEDIAS = "SET_LOADING/SOCIAL_MEDIAS"

export const GET_SOCIAL_MEDIA = "GET_SOCIAL_MEDIA"
