import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getAllLanguages } from "store/actions"

const InitialApiLoader = props => {
  const { getAllLanguages } = props

  useEffect(() => {
    getAllLanguages({})
  }, [])

  return <></>
}

const mapStatetoProps = state => {
  const { languages } = state.Language
  // console.log(state, state.Language)
  return { languages }
}

const mapDispatchToProps = { getAllLanguages }

export default connect(mapStatetoProps, mapDispatchToProps)(InitialApiLoader)
