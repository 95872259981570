import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import {
  ADD_BANNER,
  DELETE_BANNER,
  GET_BANNER,
  GET_BANNER_LIST,
  GET_PARENT_BANNER,
  UPDATE_BANNER,
} from "./actionTypes"
import {
  addBanner,
  deleteBanner,
  getBanner,
  getBannerList,
  getParentBanner,
  updateBanner,
} from "./httpCalls.banner"
import { setLoading, getBannerListSuccess } from "./actions"

function* GetBannerList(action) {
  yield put(setLoading(true))
  try {
    const { payload } = action
    const response = yield call(getBannerList, payload)
    if (response?.success) {
      yield put(getBannerListSuccess(response.data, response.metadata))
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* GetParentBanner(action) {
  yield put(setLoading(true))
  const { id, cb } = action.payload
  try {
    const response = yield call(getParentBanner, id)
    cb && cb(response)
  } catch (error) {
    cb && cb(error)
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* AddBanner(action) {
  yield put(setLoading(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(addBanner, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "banner-added" })
      cb && cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* GetBanner(action) {
  yield put(setLoading(true))
  try {
    const { id, cb } = action.payload
    const response = yield call(getBanner, id)
    if (response?.success) {
      cb && cb(response.data[0])
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* UpdateBanner(action) {
  yield put(setLoading(true))
  try {
    const { data, cb } = action.payload
    const response = yield call(updateBanner, data)
    if (response?.success) {
      toast.success(response?.message, { toastId: "banner-update" })
      cb && cb()
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* DeleteBanner(action) {
  yield put(setLoading(true))
  try {
    const { id, apiEndpoint, cb } = action.payload
    const response = yield call(deleteBanner, id, apiEndpoint)
    if (response?.success) {
      toast.success(response?.message, { toastId: "banner-delete" })
      cb && cb(id)
    }
  } catch (error) {
    console.log(error, "error")
  }
  yield put(setLoading(false))
}

function* bannerSaga() {
  yield takeEvery(GET_BANNER_LIST, GetBannerList)
  yield takeEvery(ADD_BANNER, AddBanner)
  yield takeEvery(GET_PARENT_BANNER, GetParentBanner)
  yield takeEvery(GET_BANNER, GetBanner)
  yield takeEvery(UPDATE_BANNER, UpdateBanner)
  yield takeEvery(DELETE_BANNER, DeleteBanner)
}

export default bannerSaga
