import { call, put, takeEvery } from "redux-saga/effects"
// Login Redux States
import { GET_ALL_PRODUCT } from "./actionTypes"
import { getAllProductsError, getAllProductsSuccess } from "./actions"

import { getallproducts } from "./httpCalls.product"

function* getAllProducts() {
  try {
    const response = yield call(getallproducts)
    if (response?.success) {
      yield put(getAllProductsSuccess(response))
    } else {
      yield put(getAllProductsError())
    }
  } catch (error) {
    yield put(getAllProductsError())
  }
}

function* productSaga() {
  yield takeEvery(GET_ALL_PRODUCT, getAllProducts)
}

export default productSaga
