import {
  ADD_PARTNER,
  DELETE_PARTNER,
  GET_ALL_PARTNERS,
  GET_ALL_PARTNERS_SUCCESS,
  GET_PARTNER,
  SET_LOADING_PARTNERS,
  UPDATE_PARTNER,
} from "./actionTypes"

export const getAllPartners = params => ({
  type: GET_ALL_PARTNERS,
  payload: params,
})

export const getAllPartnersSuccess = data => ({
  type: GET_ALL_PARTNERS_SUCCESS,
  payload: data,
})

export const getPartner = (partnerId, cb) => ({
  type: GET_PARTNER,
  payload: { partnerId, cb },
})

export const addPartner = (data, cb) => ({
  type: ADD_PARTNER,
  payload: { data, cb },
})

export const updatePartner = (partnerId, data, cb) => ({
  type: UPDATE_PARTNER,
  payload: { partnerId, data, cb },
})

export const deletePartner = (partnerIdList, cb) => ({
  type: DELETE_PARTNER,
  payload: { partnerIdList, cb },
})

export const setLoading = bool => ({
  type: SET_LOADING_PARTNERS,
  payload: bool,
})
