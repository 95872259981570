import { del, get, post, put } from "helpers/api.helper"

export async function callAddTextSectionApi(textSectionData) {
  try {
    const res = await post("/leadsection/text", textSectionData)
    return res
  } catch (err) {
    return err
  }
}

export async function callGetAllLeadSectionApi(params) {
  try {
    const res = await get("/leadsection", { params })
    return res
  } catch (err) {
    return err
  }
}

export async function callDeleteLeadSectionApi(sectionId) {
  try {
    const res = await del(`/leadsection/${sectionId}`)
    return res
  } catch (err) {
    return err
  }
}

export async function callAddVideoLeadSectionApi(videoSectionData) {
  try {
    const res = await post("/leadsection/video", videoSectionData)
    return res
  } catch (err) {
    return err
  }
}

export async function callGetSingleLeadSectionApi(sectionId) {
  try {
    const res = await get(`/leadsection/${sectionId}`)
    return res
  } catch (err) {
    return err
  }
}

export async function callUpdateTextSectionApi(textSectionData) {
  try {
    const res = await put("/leadsection/text", textSectionData)
    return res
  } catch (err) {
    return err
  }
}

export async function callUpdateVideoSectionApi(videoSectionData) {
  try {
    const res = await put("/leadsection/video", videoSectionData)
    return res
  } catch (err) {
    return err
  }
}
