import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_TEXT_SECTION,
  ADD_VIDEO_SECTION,
  DELETE_LEAD_SECTION,
  GET_ALL_LEAD_SECTION,
  SINGLE_SECTION,
  UPDATE_TEXT_SECTION,
  UPDATE_VIDEO_SECTION,
} from "./actionTypes"
import {
  addTextSectionError,
  addTextSectionSuccess,
  addVideoSectionError,
  addVideoSectionSuccess,
  deleteLeadPageSectionError,
  deleteLeadPageSectionSuccess,
  getAllLeadPageError,
  getAllLeadPageSuccess,
  getSingleSectionError,
  getSingleSectionSuccess,
  updateTextSectionError,
  updateTextSectionSuccess,
  updateVideoSectionError,
  updateVideoSectionSuccess,
} from "./actions"
import {
  callAddTextSectionApi,
  callAddVideoLeadSectionApi,
  callDeleteLeadSectionApi,
  callGetAllLeadSectionApi,
  callGetSingleLeadSectionApi,
  callUpdateTextSectionApi,
  callUpdateVideoSectionApi,
} from "./httpCalls.leadpages"
import { toast } from "react-toastify"
import { SUCCESS_TOAST } from "helpers/constants/constants.helper"

function* addTextSectionSaga(action) {
  try {
    const response = yield call(callAddTextSectionApi, action?.payload?.data)
    if (response?.success) {
      action.payload.cb()
      toast.success(response?.message || "Successfully Added !!!", {
        toastId: SUCCESS_TOAST.ADD_LEAD_PAGES_TEXT_SECTION,
      })
      yield put(addTextSectionSuccess())
    } else {
      yield put(addTextSectionError())
    }
  } catch (error) {
    yield put(addTextSectionError())
  }
}

function* addVideoSectionSaga(action) {
  try {
    const response = yield call(
      callAddVideoLeadSectionApi,
      action?.payload?.data
    )
    if (response?.success) {
      action.payload.cb()
      toast.success(response?.message || "Successfully Added !!!", {
        toastId: "successAddedVideoLeadSection",
      })
      yield put(addVideoSectionSuccess())
    } else {
      yield put(addVideoSectionError())
    }
  } catch (error) {
    yield put(addVideoSectionError())
  }
}

function* getAllLeadPageSectionSaga(action) {
  try {
    const response = yield call(callGetAllLeadSectionApi, action?.payload)
    if (response?.success) {
      yield put(getAllLeadPageSuccess(response))
    } else {
      yield put(getAllLeadPageError())
    }
  } catch (error) {
    yield put(getAllLeadPageError())
  }
}

function* GetSingleLeadPageSectionSaga(action) {
  try {
    const response = yield call(
      callGetSingleLeadSectionApi,
      action.payload?.sectionId
    )
    if (response?.success) {
      action.payload.cb(response?.data)
      yield put(getSingleSectionSuccess(response))
    } else {
      yield put(getSingleSectionError())
    }
  } catch (error) {
    yield put(getSingleSectionError())
  }
}

function* deleteLeadPageSectionSaga(action) {
  try {
    const response = yield call(
      callDeleteLeadSectionApi,
      action?.payload.sectionId
    )
    if (response?.success) {
      toast.success(response?.message || "Successfully deleted!!!", {
        toastId: "successDeletedLeadSection",
      })
      action.payload.cb()
      yield put(deleteLeadPageSectionSuccess())
    } else {
      yield put(deleteLeadPageSectionError())
    }
  } catch (error) {
    yield put(deleteLeadPageSectionError())
  }
}

function* updateTextSectionSaga(action) {
  try {
    const response = yield call(callUpdateTextSectionApi, action?.payload?.data)
    if (response?.success) {
      action.payload.cb()
      toast.success(response?.message || "Successfully Added !!!", {
        toastId: "successUpdatedTextSection",
      })
      yield put(updateTextSectionSuccess())
    } else {
      yield put(updateTextSectionError())
    }
  } catch (error) {
    yield put(updateTextSectionError())
  }
}

function* updateVideoSectionSaga(action) {
  try {
    const response = yield call(
      callUpdateVideoSectionApi,
      action?.payload?.data
    )
    if (response?.success) {
      action.payload.cb()
      toast.success(response?.message || "Successfully Added !!!", {
        toastId: "successUpdatedVideoSection",
      })
      yield put(updateVideoSectionSuccess())
    } else {
      yield put(updateVideoSectionError())
    }
  } catch (error) {
    yield put(updateVideoSectionError())
  }
}

function* leadPagesSaga() {
  yield takeEvery(ADD_TEXT_SECTION, addTextSectionSaga)
  yield takeEvery(GET_ALL_LEAD_SECTION, getAllLeadPageSectionSaga)
  yield takeEvery(DELETE_LEAD_SECTION, deleteLeadPageSectionSaga)
  yield takeEvery(ADD_VIDEO_SECTION, addVideoSectionSaga)
  yield takeEvery(SINGLE_SECTION, GetSingleLeadPageSectionSaga)
  yield takeEvery(UPDATE_TEXT_SECTION, updateTextSectionSaga)
  yield takeEvery(UPDATE_VIDEO_SECTION, updateVideoSectionSaga)
}

export default leadPagesSaga
