import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { useSelector } from "react-redux"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  enum_string,
  ...rest
}) => {
  const userStatePageList = useSelector(({ Login }) => Login?.userData?.pages)
  const token = useSelector(({ Login }) => Login?.userInfo?.access_token)

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected) {
          if (!token) {
            return (
              <Redirect
                to={{
                  pathname: `/${ROUTES_PATH.LOGIN}`,
                  state: { from: props.location },
                }}
              />
            )
          }
          if (userStatePageList?.includes(enum_string))
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          else
            return (
              <Redirect
                to={{
                  pathname: `/${ROUTES_PATH.UNAUTHORIZED}`,
                  state: { from: props.location },
                }}
              />
            )
        } else
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )

        // return (
        //   <Redirect
        //     to={{
        //       pathname: `/${ROUTES_PATH.UNAUTHORIZED}`,
        //       state: { from: props.location },
        //     }}
        //   />
        // )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  enum_string: PropTypes.any,
}

export default Authmiddleware
