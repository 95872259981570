import FileDropBox from "components/form-components/FileDropBox"
import FormButton from "components/form-components/FormButton"
import LanguageDropDown from "components/form-components/LanguageDropDown"
import { Field, Form, Formik } from "formik"
import { FILE_TYPE } from "helpers/constants/constants.helper"
import { BANNER } from "helpers/constants/labels.constants"
import React from "react"
import { MdInfoOutline } from "react-icons/md"
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

const BannerForm = ({
  formData,
  BannerValidationSchema,
  onSubmit,
  childBannerId,
  parentBannerId,
  submitLoader,
  submitbtn,
  uploadFile,
  mediaLoader,
}) => {
  const { TITLE, LINK, BANNER_IMAGE, BANNER_IMAGE_SIZE, LANGUAGE_CODE } =
    BANNER.BANNER_FORM
  return (
    <div>
      <Formik
        initialValues={formData}
        validationSchema={BannerValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, errors, values, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="8" sm="12">
                <Card className="p-3">
                  <Row>
                    <div className="mb-4">
                      <Label>{TITLE} </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="title"
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <div className="position-absolute text-danger">
                          {errors.title}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label>{LINK} </Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="link"
                        value={values.link}
                      />
                      {errors.link && touched.link ? (
                        <div className="position-absolute text-danger">
                          {errors.link}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Row>
                        <Col>
                          <Label>
                            <p className="m-0 p-0 d-flex align-items-center">
                              <span className="me-1">{BANNER_IMAGE}</span>
                              <MdInfoOutline
                                id="info-icon"
                                className="cursor-pointer"
                              />
                              <UncontrolledTooltip target="info-icon">
                                {BANNER_IMAGE_SIZE}
                              </UncontrolledTooltip>
                            </p>
                          </Label>
                          <FileDropBox
                            value={values.image}
                            handleChange={file => {
                              uploadFile(file, "image", setFieldValue)
                            }}
                            fileType={FILE_TYPE.IMAGE}
                          />
                          {errors.image && touched.image ? (
                            <div className="position-absolute text-danger">
                              {errors.image}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-4">
                        <Label>{LANGUAGE_CODE}</Label>
                        <LanguageDropDown
                          disabled={
                            childBannerId || parentBannerId ? true : false
                          }
                        />
                        {errors.languageCode && touched.languageCode ? (
                          <div className="position-absolute text-danger">
                            {errors.languageCode}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <FormButton
                  customClass="btn-dark"
                  loading={submitLoader || mediaLoader}
                  type="submit"
                  btnText={submitbtn}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BannerForm
