import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { ROUTES_PATH } from "helpers/constants/constants.helper"
import { MdHome, MdLogin } from "react-icons/md"
import { IoHeart } from "react-icons/io5"

const Unauthorized = () => {
  const history = useHistory()
  const isNotFound = history.location.pathname === `/${ROUTES_PATH.NOT_FOUND}`
  console.log(isNotFound)
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="ex-page-content text-center">
                    <h1 className="text-dark">
                      {isNotFound ? "404!" : "401!"}
                    </h1>
                    <h3 className="">
                      {isNotFound
                        ? "Opps! Page not found"
                        : "Sorry, you are unauthorized to view this page"}
                    </h3>
                    <br />

                    <div className="d-flex justify-content-around">
                      <Link
                        className="btn btn-info mb-4 waves-effect waves-light"
                        to={`${ROUTES_PATH.BASE}`}
                      >
                        <MdHome /> Back to Dashboard
                      </Link>
                      <Link
                        className="btn btn-info mb-4 waves-effect waves-light"
                        to={`/${ROUTES_PATH.LOGIN}`}
                      >
                        <MdLogin /> Back to Log-in
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Policy leader
                  <span className="d-none d-sm-inline-block">
                    {" "}
                    - Crafted with <IoHeart className="text-danger" /> {" "}
                    by Policy leader.
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Unauthorized
