import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  LOGIN_ERROR,
  SET_USER_DATA,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginError = error => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const logoutUserError = error => {
  return {
    type: LOGOUT_USER_ERROR,
    payload: error,
  }
}

export const setUserData = userData => {
  return {
    type: SET_USER_DATA,
    payload: userData,
  }
}
