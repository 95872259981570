import {
  ADD_LANGUAGE,
  GET_ALL_LANGUAGES,
  GET_ALL_LANGUAGES_ERROR,
  GET_ALL_LANGUAGES_SUCCESS,
  SET_CURRENT_LANGUAGE_CODE,
} from "./actionTypes"

export const getAllLanguages = params => {
  return {
    type: GET_ALL_LANGUAGES,
    payload: params,
  }
}

export const getAllLanguagesSuccess = params => {
  return {
    type: GET_ALL_LANGUAGES_SUCCESS,
    payload: params,
  }
}

export const getAllLanguagesError = error => {
  return {
    type: GET_ALL_LANGUAGES_ERROR,
    payload: error,
  }
}

export const addLanguage = (data, cb) => {
  return {
    type: ADD_LANGUAGE,
    payload: { data, cb },
  }
}

export const setCurrentLanguageCode = languageCode => {
  return {
    type: SET_CURRENT_LANGUAGE_CODE,
    payload: languageCode,
  }
}
