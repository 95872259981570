import { ROUTES_PATH } from "helpers/constants/constants.helper"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
} from "reactstrap"
import styles from "./banner.module.css"
import BannerCard from "./BannerCard"
import { IoChevronDown } from 'react-icons/io5'

const BannerSettingModal = ({
  showSettingModal,
  setShowSettingModal,
  currentSelectedParentBanner,
  languages,
  filterBanner,
  deleteBanner,
  isFeaturedTab,
}) => {
  const [singlebtn, setSinglebtn] = useState(false)
  const history = useHistory()
  const remainingLanguages = languages.filter(language => {
    return !currentSelectedParentBanner?.BannerByLanguages.some(
      banner => banner.language_code === language.language_code
    )
  })
  return (
    <>
      <Modal isOpen={showSettingModal} centered={true} size="xl">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Banner Settings</h5>
          <button
            type="button"
            onClick={() => {
              setShowSettingModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className={`modal-body ${styles.setting_modal_body}`}>
          <div className="info-container">
            <div className="row">
              <div className="col-6">
                <div className="row mb-1">
                  <div className="col-3 mb-3" /> <div className="col-3" />
                </div>
              </div>
              <div className="col-2" />
              <div className="col-4 ">
                {remainingLanguages.length ? (
                  <Dropdown
                    isOpen={singlebtn}
                    toggle={() => setSinglebtn(!singlebtn)}
                    className=""
                  >
                    <DropdownToggle className="btn btn-dark w-100" caret>
                      Add Banner <IoChevronDown />
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      {remainingLanguages.map((language, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            history.push(
                              `/${ROUTES_PATH.BANNER}/${ROUTES_PATH.ADD_BANNER}/${language.language_code}/${currentSelectedParentBanner?.id}`
                            )
                          }}
                        >
                          {language.name}
                          {` (${language.language_code})`}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                ) : null}
              </div>
            </div>
          </div>
          <div className={`${styles.container}`}>
            {currentSelectedParentBanner?.BannerByLanguages.length
              ? currentSelectedParentBanner?.BannerByLanguages.map(
                (banner, index) => (
                  <BannerCard
                    banner={banner}
                    key={index}
                    filterBanner={filterBanner}
                    deleteBanner={deleteBanner}
                    isFeaturedTab={isFeaturedTab}
                  />
                )
              )
              : null}
          </div>
        </div>
      </Modal>
    </>
  )
}
const mapStatetoProps = state => {
  const { Language } = state
  const { languages } = Language

  return { languages }
}
export default connect(mapStatetoProps, {})(BannerSettingModal)
